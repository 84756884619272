import { TextField } from '@mui/material';
import React, { useState } from 'react';
import InputMask from '@mona-health/react-input-mask'

const MakedInput = React.forwardRef(({ className, mask, ...props }, ref) => {
  return (
    
    <InputMask
        {...props}
        ref={ref}
        mask={mask}
    >
        <TextField
         />
    </InputMask>
  );
});

export default MakedInput;