import { useSelector } from "react-redux";
import { ReturnsTable } from "../../../../components/StoreEvent/Returns/ReturnsTable";
import { useEffect, useState } from "react";
import storeSalesReturnService from "../../../../services/store-sales-return.service";
import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const ReturnsPage = () => {
    const storeEvent = useSelector((state) => state.storeEvent);
	const [salesReturns, setSalesReturns] = useState([]);
    const { event } = storeEvent;
	const navigate = useNavigate();



	useEffect(() => {
		storeSalesReturnService
			.getByStoreEvent(event.id)
			.then((data) => {
				setSalesReturns(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [event.id]);


    const openHandler = (saleReturn) => {
		navigate(`/store-event/${event.id}/return/${saleReturn.id}`);
    }

	const createHandler = () => {
		navigate(`/store-event/${event.id}/return`);
	};

    if(!event) return null;
    return (
    <div>
        <ReturnsTable  salesReturns={salesReturns} onOpen={openHandler}/>

		<Button variant="outlined" sx={{mt: 3}} startIcon={<AddIcon />} onClick={createHandler} >
		Registrar devolução
		</Button>

			{/* <Fab color="primary" onClick={handleCreate}>
				<AddIcon />
			</Fab>

			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				maxWidth="90vw"
				maxHeight="90vh"
			>
				<DialogContent>{dialogContent}</DialogContent>
			</Dialog> */}
    </div>
    );
};

export default ReturnsPage;