import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setStores } from '../../store/slice/store.slice'
import storeService from '../../services/store.service'

const StoreSelector = ({ selectedStore, onSelectStore }) => {
  const stores = useSelector((state) => state.stores.list);
  const [store, setStore] = React.useState(selectedStore || '');
  const dispatch = useDispatch();

  useEffect(() => {
    //load stores if not loaded
    if (stores.length === 0) {
      storeService.availables().then((stores) => {
        dispatch(setStores(stores));

        if(stores.length === 1) {
          //onSelectStore(stores[0].id);
          handleChange({target: {value: stores[0].id}});
        }

      })
    }
  }, []);

  useEffect(() => {
    if(selectedStore) {
      const s = stores.find(store => store.id.toLowerCase() === selectedStore.toLowerCase());
      
      if(s)
        setStore(s.id);
    }
  }, [selectedStore]);

  const handleChange = (event) => {
    setStore(event.target.value);
    onSelectStore(event.target.value);
  };

  if(stores.length === 0) {
    return (<div>Loading...</div>)
  }


  return (
    <FormControl fullWidth>

      <InputLabel id="store-select-label">Loja</InputLabel>
      <Select labelId="store-select-label" label='Loja' value={store} onChange={handleChange}>
        {stores.map((store) => (
          <MenuItem key={store.id} value={store.id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StoreSelector
