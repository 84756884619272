import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false,
    token: null,
    expiration: null,
    user: {
        nameid: null,
        email: null,
        EmployeeIdentifier: null,
        EmployeeStores: null,
        role: null,
        given_name: null
      }
};

//initialize state based on local storage
if(localStorage.getItem('token')) {
    initialState.token = localStorage.getItem('token');
    initialState.expiration = localStorage.getItem('expiration');
    initialState.user = JSON.parse(localStorage.getItem('payload'));
    initialState.isLoggedIn = initialState.token && initialState.expiration > new Date().getTime();
}


const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout(state) {


            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.expiration = null;

            //remove the token from local storage
            localStorage.removeItem('token');
            localStorage.removeItem('payload');
            localStorage.removeItem('expiration');

            //clear the expiration timer
            if(state.expirationTimeout)
                clearTimeout(state.expirationTimeout);
        },
        login(state, action) {
            
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.expiration = action.payload.expiration;
            
            console.log('login', state);
            
            //store the token in local storage
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('payload', JSON.stringify(action.payload.user));
            localStorage.setItem('expiration', action.payload.expiration);

        },
    },
});

export const authActions = authSlice.actions;

export default authSlice;