import { Button, Checkbox, Dialog, DialogActions, Drawer, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CouponDetails from "components/Coupon/CouponDetails";
import DropdownButton from "components/Layout/DropdownButton";
import ReturnDetails from "components/Return/ReturnDetails";
import axios from "axios";
import printDocument from "services/print";
import productService from "services/product.service";
import storeEventService from "services/store-event.service";
import storeSalesReturnService from "services/store-sales-return.service";
import { useAppHooks } from "hooks/useAppHooks";
import { useHasRole } from "hooks/useHasRole";
import { v4 as uuidv4 } from 'uuid';

function ReturnDetailsPage(props) {
	const [returns, setReturns] = useState(null);
	const [storeEvent, setStoreEvent] = useState(null);
	const [openCoupon, setOpenCoupon] = useState(false);
    const [openLabels, setOpenLabels] = useState(false);
    const { returnId } = useParams();
    const { salesReturn } = returns || {};
    const { message, withLoading } = useAppHooks();
    const navigate = useNavigate();
	const isManager = useHasRole(['Administrator', 'Manager']) // Initialize your RoleService

    const confirmed = salesReturn && salesReturn.confirmationAt ? true : false;

	const printPage = () => {
        printDocument(30);
	};

	const printCoupon = () => {
		setOpenCoupon(true);
	};

	const printLabels = () => {

		setOpenLabels(true);
	};

	const printHandler = (e) => {

        switch(e){
            case 'cupom':
                printCoupon();
                break;
            case 'etiqueta':
                printLabels();
                break;
            default:
                printPage();
        }
	};

    const confirmHandler = () => {

        withLoading(async() =>{

            const data = await storeSalesReturnService.confirm(returnId);

            setReturns((s) => ({ ...s, salesReturn: data }));
            message.success('Devolução confirmada com sucesso');
        }).catch((error) => {
            message.error('Não foi possível confirmar a devolução', error);
        });

        
    }

	const handleClose = () => {
		setOpenCoupon(false);
	};

    const onSaleClick =() => {

        const url = returns.salesReturn.storeEventId ?
            `/store-event/${returns.salesReturn.storeEventId}/sales/create` :
            `/store/${returns.salesReturn.storeId}/sales/create`;
            
        console.log('navigate to', url);
        navigate(url, { state: { coupon: returns.coupon, customer: returns.salesReturn.customer }});
    };

	useEffect(() => {

        withLoading(async() =>{
            const data = await storeSalesReturnService.get(returnId);
            setReturns(data);
        }).catch((error) => {
            message.error('Não foi possível carregar a devolução', error);
        });

	}, [returnId]);

    useEffect(()=>{

        if(!returns) return;
        if(!returns.salesReturn || !returns.salesReturn.storeEventId) return;

        withLoading(async() =>{
            const data = await storeEventService.get(returns.salesReturn.storeEventId);
            setStoreEvent(data);
        }).catch((error) => {
            message.error('Não foi possível carregar a devolução', error);
        });

    }, [returns])
    
    const options = [
        { value: 'A4', label: 'Imprimir A4' },
        { value: 'cupom', label: 'Imprimir cupom' },
      ];

    if(isManager)
        options.push({ value: 'etiqueta', label: 'Imprimir etiqueta' });



	return returns ? (
        <div className="print" id='component-to-print'>
        
			<Stack direction="row" gap={2} position="absolute" right={30} zIndex={2} className="no-print">
                {isManager && !confirmed && (
                    <Button variant="contained" color="secondary" onClick={confirmHandler}>
                        Confirmar devolução
                    </Button>
                )}
                
                <DropdownButton
                    buttonText="Impressão"
                    options={options}
                    onSelect={printHandler}
                />
            </Stack>
            <ReturnDetails storeEvent={storeEvent} salesReturn={returns.salesReturn}></ReturnDetails>
            <br />
            <br />

            {returns.coupon && (
            <Button variant="contained" color="primary" onClick={onSaleClick}>
                Registrar venda
            </Button>
            )}

            <Drawer anchor="right" open={openLabels} onClose={() => setOpenLabels(false)}>
                <TagPrint storeId={returns.salesReturn.storeId} items={returns.salesReturn.items.map((item) => ({ ...item, id: uuidv4() }))} onClose={() => setOpenLabels(false)}></TagPrint>
            </Drawer>

            <Dialog 
                fullWidth={true}
                maxWidth='md'
                onClose={handleClose} 
                open={openCoupon}>				

                {openCoupon && <CouponDetails storeEvent={storeEvent} coupon={returns.coupon} salesReturn={returns.salesReturn} storeId={returns.salesReturn.storeId}></CouponDetails>}
                
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                            Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
	) : returnId ? (
		<p>Carregando devolução...</p>
	) : (
		<p>Não foi possível localizar a devolução.</p>
	);
}

export default ReturnDetailsPage;


const TagPrint = ({items, storeId, onClose}) => {
    
    //display table with products and checkboxes to select which products to print
    //print button
    //print selected products

    const { message, withLoading } = useAppHooks();
    const [selected, setSelected] = useState(items.map((item) => item.id));
    const modelRef = useRef(null);

    const handleSelect = (e) => {
        console.log(e);

        if(e.target.checked)
            setSelected([...selected, e.target.value]);
        else
            setSelected(selected.filter(x => x != e.target.value));
    }

    const printHandler = () => {
            
        //get selected products
        //call api to print
        //print labels
        //close dialog

        const model = modelRef.current;
        const ids = items.filter((item) => selected.indexOf(item.id) !== -1).map(item => item.product.id);

        if(ids.length === 0){
            message.error('Selecione pelo menos um produto para imprimir');
            return;
        }
        
        withLoading(async() =>{
            const products = await productService.list(storeId, ids);

            const printData = {
                Modelo: model,
                Produtos: products.map((product) => ({
                    id: product.storeProduct.productId,
                    Codigo: product.storeProduct.code,
                    CodigoFilhoGrade: product.sku,
                    Descricao: product.name,
                    Quantidade: 1,
                    PrecoPromocao: product.price,
                    Preco: product.price
                }))
            };

            axios.post('http://localhost:9090/api/print', printData).then((response) => {
                message.success('Etiquetas impressas com sucesso');
                onClose();
            }).catch((error) => {
                console.log(error);
                message.error('Não foi possível imprimir as etiquetas', error);
            });

        }).catch((error) => {
            message.error('Não foi possível carregar os produtos', error);
        });

    
            //http://localhost:9090/api/print
    
            // {
            //     "Modelo": 2,
            //     "Produtos": [{
            //             "Id": "7212",
            //             "Codigo": "2085634385004",
            //             "CodigoFilhoGrade": "202018DP0002",
            //             "Descricao": "CONJUNTO CROPPED COM TULE ARRASTÃO E DETALHES EM RENDA - Preto GG",
            //             "Quantidade": 1,
            //             "PrecoPromocao": 0.01,
            //             "Preco": 189.9
            //         }
            //     ]
            // }
            
            //"{"Modelo":2,"Produtos":[{"Id":"7212","Codigo":"2085634385004","CodigoFilhoGrade":"202018DP0002","Descricao":"CONJUNTO CROPPED COM TULE ARRASTÃO E DETALHES EM RENDA - Preto GG","Quantidade":1,"PrecoPromocao":0.01,"Preco":189.9}]}
            // Logic to print the labels
    
            // Logic to print the labels
    }

    return (
        <Stack gap={3} sx={{p: 2}}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell align="right">SKU</TableCell>
                        <TableCell align="right">Imprimir</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {items.map((item, i) => (
                        <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {item.product.name}
                        </TableCell>
                        <TableCell align="right">{item.product.sku}</TableCell>
                        <TableCell align="right">
                            <Checkbox
                                checked={selected.indexOf(item.id) !== -1}
                                value={item.id}
                                onChange={handleSelect}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <FormControl fullWidth>
                <InputLabel>Modelo impressão</InputLabel>
                
                <Select
                    onChange={(event) => modelRef.current = event.target.value}
                    label="Modelo impressão"
                >
                    <MenuItem value={2}>Sex Shop</MenuItem>
                    <MenuItem value={3}>Lingerie</MenuItem>
                </Select>

            </FormControl>

            <Stack direction="row" gap={2} right={30} zIndex={2} className="no-print">
                <Button variant="contained" color="primary" onClick={printHandler}>
                    Imprimir etiquetas
                </Button>
            </Stack>

        </Stack>
    );
};
