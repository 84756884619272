import { ObjectCleaner } from 'ObjectCleaner';
import axios from 'axios';
import store from '../store/store';
import { v4 as uuidv4 } from 'uuid';

let authorizationInterceptorLoaded = false;

export function authorizationInterceptor() {

    if(authorizationInterceptorLoaded)
        return;

    axios.interceptors.request.use(request => {

        
        const currentSession = store.getState().auth;

        if(currentSession && currentSession.isLoggedIn){

            const token = currentSession.token;
            const user = currentSession.user;

            request.headers.setAuthorization(`Bearer ${token}`);
        }

        // if(request.data){
        //     request.data = ObjectCleaner.clean(request.data);
        // }

        return request;
    });

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if(error.response.status === 401){
            window.location.href = "/logout?returnUrl=" + encodeURIComponent(window.location.pathname);
        }

        return Promise.reject(error);
    });

    authorizationInterceptorLoaded = true;
}