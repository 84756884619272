import { Box, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Switch, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2/Grid2'
import TitleBox from 'components/Layout/TitleBox'
import storeEventService from '../../../services/store-event.service'
import { useDebounce } from '../../../hooks/useDebounce'
import { useLoading } from '../../../hooks/useLoading'
import { useMessage } from '../../../hooks/useMessage'

const Activities = ({ storeEvent }) => {
	const [formValues, setFormValues] = useState({})
	const debounce = useDebounce()
	const message = useMessage()
	const loading = useLoading()

	const attributeKeys = ['description', 'invite', 'welcome', 'orientations', 'presence_list', 'link_sent', 'pranks', 'location', 'return_orientations', 'bride_research', 'contractor_research']

	useEffect(() => {
		if (storeEvent) {
			loadActivities()
		}
	}, [storeEvent])

	const loadActivities = () => {
		const updatedFormValues = { ...formValues }

		attributeKeys.forEach((key) => {
			if (storeEvent.activities && storeEvent.activities[key]) {
				updatedFormValues[key] = storeEvent.activities[key]
			} else {
				updatedFormValues[key] = key === 'description' ? '' : false
			}
		})

		setFormValues(updatedFormValues)
	}

	const handleInputChange = (event) => {
		const { value } = event.target
		debounce(() => updateKey('description', value), 1000)
	}

	const handleFormChange = (event) => {
		const { name, checked } = event.target
		const value = checked.toString()
		setFormValues((prevValues) => ({ ...prevValues, [name]: value }))

		updateKey(name, value)
	}

	const updateKey = (activity, value) => {
		if (!storeEvent) return

		loading.show()

		storeEventService
			.updateActivity(storeEvent.id, activity, value)
			.then((response) => {
				message.success('Registro atualizado com sucesso.')
			})
			.catch((error) => {
				message.error('Não foi possível atualizar o registro.', error)
			})
			.finally(() => {
				loading.hide()
			})

		setFormValues((prevValues) => ({ ...prevValues, [activity]: value }))
	}

	//get the current date without time
	const now = new Date()
	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
	const eventDate = new Date(storeEvent.eventDate)

	const isPreEventDay = !!storeEvent // && eventDate > today;
	const isEventDay = !!storeEvent // && eventDate === today;
	const isPostEventDay = !!storeEvent // && eventDate < today;

	const items = [
		{
			label: 'Convite enviado',
			name: 'invite',
			enabled: isPreEventDay,
			group: 'Antes do evento',
		},
		{
			label: 'Boas vindas',
			name: 'welcome',
			enabled: isPreEventDay,
			group: 'Antes do evento',
		},
		{
			label: 'Orientações',
			name: 'orientations',
			enabled: isPreEventDay,
			group: 'Antes do evento',
		},
		{
			label: 'Lista de presença',
			name: 'presence_list',
			enabled: isPreEventDay,
			group: 'Antes do evento',
		},
		{
			label: 'Link enviado',
			name: 'link_sent',
			enabled: isPreEventDay,
			group: 'Antes do evento',
		},
		{
			label: 'Brincadeiras',
			name: 'pranks',
			enabled: isPreEventDay,
			group: 'Antes do evento',
		},
		{
			label: 'Localização',
			name: 'location',
			enabled: isEventDay,
			group: 'Dia do evento',
		},
		{
			label: 'Orientações de retorno',
			name: 'return_orientations',
			enabled: isPostEventDay,
			group: 'Depois do evento',
		},
		{
			label: 'Pesquisa noiva',
			name: 'bride_research',
			enabled: isPostEventDay,
			group: 'Depois do evento',
		},
		{
			label: 'Pesquisa contratante',
			name: 'contractor_research',
			enabled: isPostEventDay,
			group: 'Depois do evento',
		},
	]

	const itemsToRender = items.reduce((acc, obj) => {
		const key = obj.group
		if (!acc[key]) {
			acc[key] = []
		}
		acc[key].push(obj)
		return acc
	}, {})

	//const itemsToRender = items.filter((item) => item.enabled === true);

	//console.log("itemsToRender", itemsToRender);

	return (
		<>
			<TitleBox title="Atividades" sx={{ mt: 0, mb: 2 }} />

			<FormControl fullWidth={true}>
				<TextField id="description" rows={6} multiline defaultValue={formValues.description || ''} placeholder="Observações especiais..." onKeyUp={handleInputChange} />
			</FormControl>

			{Object.keys(itemsToRender).map((key) => (
				<Box key={key} mt={3}>
					<Divider textAlign="center">
						<Chip label={key} color="secondary" />
					</Divider>
					<Grid container spacing={2} justifyContent="space-between">
						{itemsToRender[key].map((item) => (
							<Box key={item.name}>
								<FormGroup sx={{ m: 2 }}>
									<FormLabel component="legend">{item.label}</FormLabel>
									<FormControlLabel control={<Switch checked={formValues[item.name] === 'true'} onChange={handleFormChange} inputProps={{ 'aria-label': 'controlled' }} name={item.name} color="success" />} label="Enviado" />
								</FormGroup>
							</Box>
						))}
					</Grid>
				</Box>
			))}
			{/* 
      {itemsToRender.map((item) => (
        <Box key={item.group}>
          <FormGroup sx={{m: 2}}>
            <FormLabel component="legend">{item.label}</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formValues[item.name] === "true"}
                  onChange={handleFormChange}
                  inputProps={{ "aria-label": "controlled" }}
                  name={item.name}
                  color="success"
                />
              }
              label="Enviado"
            />
          </FormGroup>
        </Box>
      ))} */}
		</>
	)
}

export default Activities
