import { Box, Stack } from "@mui/material";
import { NavLink, Outlet, useParams } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import StoreSummary from "components/Store/StoreSummary";
import SubMenu from "components/Layout/SubMenu";
import classes from "./IndexPage.module.scss";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const IndexPage = (props) => {
  const { storeId } = useParams();
  const store  = useSelector((state) => state.stores.list.find(x=> x.id == storeId.toLowerCase()));

  "".toLowerCase()

  if (!store) {
    return <p>Loadding</p>;
  }


  const LeftPanel = styled(Grid)({
    position: "relative",

  });
  const RightPanel = styled(Grid)({
    boxShadow: "0 0 6px #00000059",
    webkitBoxShadow: "0px 0px 6px 0px rgba(0,0,0,.35)",
    mozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,.35)",
    position: "relative",

  });

  return (
    <Grid container spacing={0} minHeight='100%'>
      <LeftPanel xs={6} md={2} direction='column' className="no-print" sx={{display: 'flex', height: 'auto', justifyContent:'flex-start', alignItems:'stretch' }} >
        <StoreSummary store={store} /> 
      </LeftPanel>

      <RightPanel xs={6} md={10} padding={3}>
        <SubMenu>
          <NavLink end to={`/store/${storeId}`}>Geral</NavLink>
          <NavLink end to={`/store/${storeId}/sales`} >Vendas</NavLink>
          <NavLink end to={`/store/${storeId}/checkout-counter/search`}>Caixas</NavLink>
          {/* <Link end to={`/store/${storeId}/returns`}>Histórico de Devoluções</Link> */}
        </SubMenu>
        <Box>
          <Outlet />
        </Box>
      </RightPanel>
    </Grid>
  );
};

export default IndexPage;
