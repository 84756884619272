//this component will get the list of store events and display them in a table, each row should have a column that the render will be a prop render method

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { EventStatusText } from "models/EventStatus";
import useFormatter from "hooks/useFormatter";

const StoreEventList = (props) => {
    const { storeEvents, renderAction } = props;
    const formatter = useFormatter();
        
    const columns = [
        {
            title: "Data",
            dataIndex: "eventDate",
            key: "eventDate",
            render: (text, record) => (
                <Typography variant="body1">
                {`${formatter(record.eventDate, "date")}`}
                </Typography>
            ),
        },
        {
            title: "Noiva",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (                
                <Typography variant="body1">
                    {record?.bridge?.name?.split(" ")[0] ?? record?.contractorName?.split(" ")[0]}
                </Typography>
            ),
        },
        {
            title: "Horário",
            dataIndex: "eventDate",
            key: "eventDate",
            render: (text, record) => (
                <Typography variant="body1">
                {`${formatter(record.eventStartAt, "time")} às ${formatter(record.eventEndAt, "time")}`}
                </Typography>
            ),
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (text, record) => renderAction(record)
        },
    ];

    if(!storeEvents || storeEvents.length === 0) return (<div>Nenhum evento encontrado</div>);
    
    return (
        <Table>
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell key={column.key}>{column.title}</TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {storeEvents.map((row) => (
                <TableRow key={row.id}>
                    {columns.map((column) => (
                    <TableCell key={column.key}>
                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                    </TableCell>
                    ))}
                </TableRow>
                ))}
            </TableBody>
            </Table>
    );
};

export default StoreEventList;