import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function DropdownButton({ buttonText, options, onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => () => {
    setAnchorEl(null);
    if (value !== undefined && onSelect) {
      onSelect(value);
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose()}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={handleClose(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default DropdownButton;
