import { Stack, Typography, styled } from "@mui/material";

import logo from "../../assets/img/logo.svg";
import { useSelector } from "react-redux";

const PrintHeader = ({storeId}) => {

	const store  = useSelector((state) => state.stores.list.find(x=> x.id == storeId.toLowerCase()));


    const TypographyCustom = styled(Typography)(({ theme }) => ({
        color: theme.palette.text.primary,
        fontSize:  '8px !important',
        lineHeight: '8px !important',
        padding: theme.spacing(0),
    }));


    return (

        <Stack direction='row'  justifyContent='center' alignItems='center' gap={2}>
            <img src={logo} alt="logo" width='90' className="print-only" />
            
            <Stack direction='column' className="print-only" sx={{textAlign: `left`}}>
                    <TypographyCustom variant="caption" className="print-only">
                        {store.cnpj}
                    </TypographyCustom>

                    <TypographyCustom variant="caption" className="print-only">
                        {store.address}
                    </TypographyCustom>
                    <TypographyCustom variant="caption" className="print-only">
                        {store.city.name} / {store.city.stateId}
                    </TypographyCustom>
                    <TypographyCustom variant="caption" className="print-only">
                        {store.zipCode}
                    </TypographyCustom>
                </Stack>
        </Stack>


    );
}

export default PrintHeader;