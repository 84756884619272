import { Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';

import React from 'react';
import styled from '@emotion/styled';
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter';
import useDateFormatter from '../../../hooks/useDateFormatter';

const ProductTable = ({ items, discount, total, payment }) => {
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();

  const TableHeadCell = styled(TableCell)(({ theme }) => ({
		"&": {
			padding: `${theme.spacing(0.2)} ${theme.spacing(2)}`,
		},
	}));

  const FooterRow = styled(TableRow)(({ theme }) => ({
    "&": {
      background: theme.palette.grey[300],
    },
  }));

  if(!items) return null;

  return (
    <TableContainer sx={{border: '1px solid #cbd0dd'}}>
      <Table>
        <TableHead>
          <TableRow sx={{background: '#d6e1ff'}}>
            <TableHeadCell>Código</TableHeadCell>
            <TableHeadCell>Produto</TableHeadCell>
            <TableHeadCell>Quantidade</TableHeadCell>
            <TableHeadCell>Subtotal</TableHeadCell>
            <TableHeadCell>Desconto</TableHeadCell>
            <TableHeadCell>Acréscimo</TableHeadCell>
            <TableHeadCell>Total</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell>{item.product.sku}</TableCell>
              <TableCell>{item.product.name}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{formatCurrency(item.subtotal)}</TableCell>
              <TableCell>{formatCurrency(item.discount)}</TableCell>
              <TableCell>{formatCurrency(item.added)}</TableCell>
              <TableCell>{formatCurrency(item.total)}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <FooterRow>
            <TableCell colSpan={7} sx={{textAlign: 'right'}}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent='end'>
                
              <Typography variant="caption" component="span" sx={{color: 'text.secondary'}}>
                  Forma de pagamento:
                </Typography>
                <Typography variant="subtitle2" component="span" sx={{fontWeight: 'bold'}} flexGrow={1} textAlign='left'>
                  {payment}
                </Typography>
                <Typography variant="caption" component="span" sx={{color: 'text.secondary'}}>
                Desconto
                </Typography>
                <Typography variant="subtitle2" component="span" sx={{fontWeight: 'bold'}}>
                  {formatCurrency(discount)}
                </Typography>
                <Typography variant="caption" component="span" sx={{color: 'text.secondary'}}>
                Total
                </Typography>
                <Typography variant="subtitle2" component="span" sx={{fontWeight: 'bold'}}>
                  {formatCurrency(total)}
                </Typography>
              </Stack>

              </TableCell>
          </FooterRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
