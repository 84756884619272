import { Button, Chip, Divider } from '@mui/material'
import { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import SalesFilter from 'components/StoreEvent/Sales/SalesFilter'
import SalesList from 'components/StoreEvent/Sales/SalesList'
import storeSalesService from 'services/store-sales.service'
import { useAppHooks } from 'hooks/useAppHooks'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SalesSearchPage = () => {
  const storeEvent = useSelector((state) => state.storeEvent)
  const { event } = storeEvent
  const [sales, setSales] = useState([])
  const [salesFromReturn, setSalesFromReturn] = useState([])
  const navigate = useNavigate()
  const { withLoading, message } = useAppHooks()

  const setSalesList = (list) => {
    setSales(list.filter((sale) => !sale.isReturnSale))
    setSalesFromReturn(list.filter((sale) => sale.isReturnSale))
  }

  useEffect(() => {
    withLoading(async () => {
      try {
        var sales = await storeSalesService.all(event.store.id, event.id);

        setSalesList(sales);

      } catch (error) {
        message.error('Erro ao carregar vendas', error);

      }

    });
  }, [event])

  const handleSearch = (form) => {
    withLoading(async () => {
      try {
        var source = await storeSalesService.all(event.store.id, event.id);
        if (form.status) {
          source = source.filter((sale) => {
            return sale.status === form.status
          })
        }

        if (form.origin) {
          if (form.origin === 'Coupon') {
            source = source.filter((sale) => {
              return sale.isReturnSale
            })
          } else {
            source = source.filter((sale) => {
              return sale.origin === form.origin
            })
          }
        }

        if(form.seller) {
          source = source.filter((sale) => {
            return sale.seller.id === form.seller
          })
        }

        if (form.startDate && form.endDate) {
          source = source.filter((sale) => {
            return sale.createdAt >= new Date(form.startDate) && sale.createdAt <= new Date(form.endDate)
          })
        } else if (form.startDate) {
          source = source.filter((sale) => {
            return sale.createdAt >= new Date(form.startDate)
          })
        } else if (form.endDate) {
          source = source.filter((sale) => {
            return sale.createdAt <= new Date(form.endDate)
          })
        }

        setSalesList(source)

      } catch (error) {
        message.error('Erro ao tentar carregar as vendas.', error);
      }
    });
  }

  const saleClickHandler = (sale) => {
    navigate(`/store-event/${event.id}/sales/${sale.id}`)
  }

  if (!event) return null

  return (
    <div>
      <SalesFilter onSearch={handleSearch} />
      <br />
      <Divider>
        <Chip label="Vendas" color="primary" />
      </Divider>
      <br />
      <SalesList sales={sales} onClick={saleClickHandler} />
      <br />
      <br />

      <Divider>
        <Chip label="Trocas" color="primary" />
      </Divider>
      <br />
      <SalesList sales={salesFromReturn} onClick={saleClickHandler} />
    </div>
  )
}

export default SalesSearchPage
