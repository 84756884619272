import { useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import LoadingModal from './LoadingModal';
import classes from './LoadingModalWrapper.module.css';

const LoadingModalWrapper = (props) => {
    const loading = useSelector((state) => state.global.loading);
  
    return (
      <Modal {...props} open={loading} className={classes.modal}>
          <>
            <LoadingModal />
          </>
      </Modal>
    );
  };

  export default LoadingModalWrapper;