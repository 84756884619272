import { Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import InputMask from 'react-input-mask'
import MaskedInput from 'components/MaskedInput'
import { ObjectCleaner } from 'ObjectCleaner'
import PhoneInput from 'components/PhoneInput'
import customerService from 'services/customer.service'
import { useAppHooks } from 'hooks/useAppHooks'
import { useDebounce } from 'hooks/useDebounce'

//const CustomerInput = ({ onUpdated, customer: inputCustomer,  }) => {
const CustomerInput = ({ onUpdated, customer  }) => {
  const [matchingField, setMatchingField] = useState('cpf')
  const { handleSubmit,  trigger, control, watch, getValues, setValue, formState } = useForm()
  const debounce = useDebounce();
  const debounceValidate = useDebounce();
  const { withLoading, message } = useAppHooks();

  const { errors } = formState


  const handleFieldChange = (field, value) => {
    const values = getValues()
    values[field] = value

    const query = {
      cpf: values.cpf?.replace(/\_/g, ''),
      phone: values.phone?.replace(/\_/g, ''),
      //email: values.email
    }

    if (query.cpf && query.cpf.length < 14) {
      query.cpf = null
    }

    if (query.phone && query.phone.length < 14) {
      query.phone = null
    }

    if(field === 'cpf' && !query.cpf ||
      field === 'phone' && !query.phone) {
        return;
    }

    if (!query.cpf && !query.phone) {

      if(customer) {
        setValue('email', '');
        setValue('name', '');
        setValue('birthday', '');
      }

      return;
    }

    console.log('query', query)

    if (matchingField && customer) {
      if(matchingField.cpf === query.cpf &&
        matchingField.phone === query.phone &&
        matchingField.email === query.email) {
          return;
        }
    }

    search(query);
  }
  
  const search = (query) => {
    console.log('searching client', query);
    withLoading(async () => {
      var data = await customerService.find(query.cpf, query.email, query.phone);
      
      //update form values
      setMatchingField({ cpf: query.cpf, phone: query.phone, email: query.email});

      if (data) {

        const { cpf, phone, email, name } = data
        const birthday = data.birthday ? new Date(data.birthday).toISOString().split('T')[0] : null

        if (!query.cpf) {
          setValue('cpf', cpf || '')
        }
        if (!query.phone) {
          setValue('phone', phone || '')
        }
        if (!query.email) {
          setValue('email', email || '')
        }

        setValue('name', name || '')
        setValue('birthday', birthday || '')
        
        onSubmit();
      }
    })
    .catch((error) => {
        message.error('Ocorreu um erro ao buscar os dados do cliente.', error)
    });
  };

  const onSubmit = () => {
    trigger().then((isValid) => {
      onUpdated(isValid, ObjectCleaner.clean(getValues()));
    });
  }


  useEffect(() => {

    if(!customer) {
      return;
    }

    search({ cpf: customer.cpf, phone: customer.phone, email: customer.email });


  }, []);

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={4}>
        <Controller
          name="cpf"
          control={control}
          defaultValue={customer.cpf || ''}
          rules={{ required: 'Campo obrigatório', pattern: { value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/, message: 'O CPF informado é inválido' } }}
          render={({ field }) => (
            <MaskedInput
              {...field}
              variant="outlined"
              error={!!errors.cpf}
              helperText={errors.cpf?.message}
              fullWidth
              label="CPF"
              onChange={(e) => {
                field.onChange(e);
                debounceValidate(onSubmit, 500);
              }}
              onBlur={(e) => {
                field.onBlur(e);
                handleFieldChange('cpf', e.target.value);
              }}
              mask={'999.999.999-99'}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={4}>
        <Controller
          name="phone"
          control={control}
          defaultValue={customer.phone || ''}
          rules={{ pattern: { value: /^\(?\d{2}\)?\s?(?:\d{4}|\d{5})-\d{4}$/, message: 'Formato inválido' } }}
          render={({ field }) => (
            <PhoneInput
              {...field}
              variant="outlined"
              error={!!errors.phone}
              helperText={errors.phone?.message}
              fullWidth
              onChange={(e) => {
                field.onChange(e);
                debounceValidate(onSubmit, 500);
              }}
              onBlur={(e) => {
                field.onBlur(e);
                handleFieldChange('phone', e.target.value);
              }}
              label="Telefone"
            ></PhoneInput>
          )}
        />
      </Grid2>
      <Grid2 xs={4}>
        <Controller name="birthday" 
          control={control} 
          defaultValue={customer.birthday || ''}
          render={({ field }) => 
            <TextField {...field} 
              fullWidth type="date" 
              label="Data de Nascimento" 
              variant="outlined" 
              onChange={(e) => {
                field.onChange(e)
                debounceValidate(onSubmit, 500);
              }}
              InputLabelProps={{ shrink: true }} />} />
      </Grid2>
      <Grid2 xs={12}>
        <Controller
          name="name"
          control={control}
          defaultValue={customer.name || ''}
          rules={{ required: 'Campo obrigatório', maxLength: 80 }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Nome
              "
              variant="outlined"
              maxLength={80}
              error={!!errors.name}
              helperText={errors.name?.message}
              onChange={(e) => {
                field.onChange(e)
                debounceValidate(onSubmit, 500);
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Controller name="email" 
          control={control} 
          defaultValue={customer.email || ''}
          rules={{ maxLength: 80 }} 
          render={({ field }) => 
          <TextField {...field} 
            fullWidth 
            label="Email" 
            variant="outlined" 
            
            onChange={(e) => {
              field.onChange(e)
              debounceValidate(onSubmit, 500);
            }}/>} 
          />
      </Grid2>
    </Grid2>
  )
}

export default CustomerInput
