import Activities from "../../../components/StoreEvent/General/Activities";
import Attachments from "components/StoreEvent/Attachments/Attachments";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ServicesOrders from "../../../components/StoreEvent/General/ServicesOrders";
import ServicesSummary from "../../../components/StoreEvent/General/ServicesSummary";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const GeneralPage = () => {
	const storeEvent = useSelector((state) => state.storeEvent);

	const { event, orders } = storeEvent;

	if (!event) return null;

	return (
		<Grid container spacing={4}>
			<Grid xs={12} md={7}>
				<ServicesSummary storeEvent={event} orders={orders} />
				<ServicesOrders storeEvent={event} />
				<Attachments storeEventId={event.id} />
			</Grid>

			<Grid xs={12} md={5}>
				<Activities storeEvent={event} />

			</Grid>
		</Grid>
	);
};

export default GeneralPage;
