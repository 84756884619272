import { Button, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { ButtonLink } from "components/Layout/ButtonLink";
import LinkIcon from '@mui/icons-material/Link';
import storeSalesService from "services/store-sales.service";
import { useAppHooks } from "hooks/useAppHooks";
import useFormatter from "hooks/useFormatter";

function ReturnDetails(props) {
    const { salesReturn, storeEvent } = props;
	const [sale, setSale] = useState(null);
    const { message, withLoading } = useAppHooks();

	const formatter = useFormatter();
	const navigate = useNavigate();
	
    const confirmed = salesReturn && salesReturn.confirmationAt ? true : false;

    useEffect(()=>{

        if(!salesReturn) return;
        if(salesReturn.storeEventId) return;

        withLoading(async() =>{
            const data = await storeSalesService.get(salesReturn.saleId);
            setSale(data);
        }).catch((error) => {
            message.error('Não foi possível carregar a devolução', error);
        });

    }, [salesReturn]);

	const basePath = salesReturn.storeEventId ? `/store-event/${salesReturn.storeEventId}` : `/store/${salesReturn.storeId}`;
	const saleUrl = `${basePath}/sales/${salesReturn.saleId}`;


	const onSaleClick = (e) => {
		navigate(saleUrl);
	};

	// Inside your SalesReturnDetailsComponent
	return salesReturn ? (
		<>
			<Stack className="print">
				<Typography variant="h5" >
					Devolução de venda
				</Typography>

				<br />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Stack direction="column" spacing={0}>
							<Typography variant="body1">
								<strong>Registrado em: </strong>{" "}
								{formatter(salesReturn.createdAt, "dateTime")}
							</Typography>
							<Typography variant="body1">
								<strong>Registrado por: </strong>{" "}
								{salesReturn.createdBy?.name}
							</Typography>

<br />

							

							{confirmed && (
								<Typography variant="body1">
									<strong>Confirmado em:</strong>  {formatter(salesReturn.confirmationAt, "dateTime")} - {salesReturn.confirmedBy?.name}
								</Typography>
							)}
						</Stack>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'left' }}>
						<Stack direction="column" spacing={0}>
							<Typography variant="body1">
								<strong>CPF: </strong>{" "}
								{salesReturn.customer?.cpf || '--'}
							</Typography>
							<Typography variant="body1">
								<strong>Nome: </strong>{" "}
								{salesReturn.customer?.name || '--'}
							</Typography>
							
						</Stack>
					</Grid>
				</Grid>
				

							
				{storeEvent?.bridge?.name?.split(" ") && (
					<Typography variant="body1">
						<strong>Evento: </strong>
						<NavLink to={`/store-event/${storeEvent.id}`}>
							<Button variant="text" size="small">
								{ `Chá da ${storeEvent.bridge.name.split(" ")[0]} - ${formatter(storeEvent.eventDate, 'date')} ` }
								{ `${formatter(storeEvent.eventStartAt, 'time')} às ${formatter(storeEvent.eventEndAt, 'time')}` }
							</Button>						
						</NavLink>


					</Typography>
				)}

				<br />
				{sale && (
					<>
						<Typography variant="subtitle1">
							Dados da venda devolvida:
						</Typography>
						<Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
							<Typography variant="body1">
								<strong>Venda: </strong>{" "}
								<ButtonLink to={saleUrl}>
									{sale.number}
								</ButtonLink>
							</Typography>
							<Typography variant="body1">
								<strong>Série: </strong>{" "}
								{sale.series?.toString()?.padLeft(3, '0')}
							</Typography>
							<Typography variant="body1">
								<strong>Data: </strong>{" "}
								{formatter(sale.createdAt, "dateTime")}
							</Typography>
						</Stack>
					</>
				)}
				<br />

				<TableContainer component={Paper}>
					<Table
						className="table table-striped table-bordered"
						aria-label="simple table"
					>
						<TableHead className="table-dark">
							<TableRow>
								<TableCell>Produto</TableCell>
								<TableCell>Preço</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{salesReturn.items && salesReturn.items.length > 0 && salesReturn.items.map((item, index) => (
								<TableRow key={`sales_return_item_${index}`}>
									<TableCell>{item.product.name}</TableCell>
									<TableCell>
										{formatter(item.unitaryValue, "currency")}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell align="right">Total devolução</TableCell>
								<TableCell>
									{formatter(salesReturn.total, "currency")}
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</Stack>
		
		</>
	) : (
		<p>Não foi possível localizar a devolução.</p>
	);
}

export default ReturnDetails;