import moment from 'moment';
import { useMemo } from 'react';

function useFormatter() {
  const format = useMemo(() => {
    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const numberFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    return (value, formatType) => {

        if(value === undefined || value === null)
            return '';

        if (formatType === 'number') {
            return numberFormatter.format(value)
        }

        if(formatType === 'currency') {
            return currencyFormatter.format(value)
        }

        if(formatType === 'date'){

            let date = value;
            
            if(value instanceof Date)
                date = value.toISOString();

            return date.endsWith('Z') ? moment.utc(date).format('DD/MM/YYYY') : moment(date).format('DD/MM/YYYY');
        }

        if(formatType === 'dateTime'){
            let date = value;
            
            if(value instanceof Date)
                date = value.toISOString();

            return date.endsWith('Z') ? moment.utc(date).format('DD/MM/YYYY HH:mm') : moment(date).format('DD/MM/YYYY HH:mm');
        }

        if(formatType === 'time'){
            let date = value;
            
            if(value instanceof Date)
                date = value.toISOString();

            return date.endsWith('Z') ? moment.utc(date).format('HH:mm') : moment(date).format('HH:mm');
        }
    };
  }, []);

  return format;
}

export default useFormatter;
