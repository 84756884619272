import { Alert, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Barcode from "react-barcode";
import PrintHeader from "components/Layout/PrintHeader";
import customerService from "services/customer.service";
import printDocument from "services/print";
import { useAppHooks } from "hooks/useAppHooks";
import useFormatter from "hooks/useFormatter";

function CouponDetails(props) {
	const { coupon, salesReturn, storeEvent, storeId } = props;
	//get width from query string
	const params = new URLSearchParams(document.location.search);
	const width = params.get('width' ) || 2;
	const format = params.get('format' ) || `CODE128B`;
	//const { storeId } = coupon;
    const stackRef = React.useRef(null);
	const formatter = useFormatter();
	const [ customer, setCustomer ] = useState(salesReturn?.customer);
	const showProducts = !storeEvent && salesReturn;
	const { withLoading } = useAppHooks();

	useEffect(() => {

		withLoading(async () => {

			console.log('loading customer', coupon);
			if(coupon.customerId){
				const data = await customerService.get(coupon.customerId);

				setCustomer(data);
			}

			setTimeout(() => {
				printDocument(10, stackRef.current);
			}, 2000);
			
		});
	}, [coupon]);


	console.log(salesReturn);

	const FieldAndValue = ({ field, value }) => (
		<>
			<Grid item xs={5} style={{ textAlign: 'right' }}>
				<Typography variant="body1"><strong>{field}:</strong></Typography>
			</Grid>
			<Grid item xs={7} style={{ textAlign: 'left' }}>
				<Typography variant="body1">{value}</Typography>
			</Grid>
		</>
	);

	return (
		<Box
			ref={stackRef}
			sx={{ p: 3, textAlign: "center" }}
			className="print"
		>
			<PrintHeader storeId={storeId} />
			
			{coupon.expired && (
			<Alert severity="error" sx={{textAlign: 'center'}} className="no-print">
				O cupom está EXPIRADO. Não é possível utilizá-lo.
			</Alert>
			)}
			<br />

			<Grid container justifyContent="center" spacing={0.5}>				
				
				<Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 20, textTransform: 'uppercase' }}>
					<Typography variant="h2">Cupom de devolução</Typography>
				</Grid>

				{customer && (
					<>
						<FieldAndValue field="CPF" value={customer.cpf || '--'} />
						<FieldAndValue field="Nome" value={customer.name || '--'} />
					</>
				)}

				<FieldAndValue field="Criado em" value={formatter(coupon.createdAt, "dateTime")} />
				<FieldAndValue field="Criado por" value={coupon.createdBy?.name} />
				<br /><br />

				{storeEvent && (
					<FieldAndValue field="Evento" value={`${storeEvent.bridge.name.split(" ")[0]} - ${formatter(storeEvent.eventDate, 'date')} `} />
				)}

				<FieldAndValue field="Expira em" value={formatter(coupon.expiresAt, "dateTime")} />
				<FieldAndValue field="Valor" value={formatter(coupon.value, 'currency')} />

				{coupon.alreadyUsed &&  (
					<>
					<br /><br />
					<FieldAndValue field="Valor utilizado" value={formatter(coupon.used, "currency")} />
					<FieldAndValue field="Usado em" value={formatter(coupon.usedAt, "dateTime")} />
					</>
				)}
			
			</Grid>

			{showProducts && (
			<TableContainer>
			<br />
			<br />
				<Table
					className="table table-striped table-bordered"
					aria-label="simple table"
				>
					<TableHead className="table-dark">
						<TableRow>
							<TableCell colSpan={2} sx={{textAlign: 'center'}}>PRODUTO(S) DEVOLVIDO(S)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{salesReturn.items && salesReturn.items.length > 0 && salesReturn.items.map((item, index) => (
							<TableRow key={`sales_return_item_${index}`}>
								<TableCell>{item.product.name}</TableCell>
								<TableCell>
									{formatter(item.unitaryValue, "currency")}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			)}
			<br />

			<Barcode value={coupon.id} format={format} width={width} height={150}  />
            <div className="break"></div>
			<br />
			<br />
			<br />
			<br />
			<br />

		</Box>
	);
}

export default CouponDetails;
