import './index.css';

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'; // Ajustado para MUI v5

import AlertSnackbarWrapper from './components/Layout/AlertSnackbarWrapper';
import LoadingModalWrapper from './components/Layout/LoadingModalWrapper';
import { Provider } from "react-redux";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { authorizationInterceptor } from "./interceptor/axios.interceptor";
import { createPortal } from 'react-dom';
import moment from "moment";
import reportWebVitals from './reportWebVitals';
import routes from "./routes/Routes";
import store from "./store/store";

let theme = createTheme({
  palette: {
    primary: {
      main: '#e2368c',
    },
    secondary: {
      main: '#00274b',
    },
    tertiary: {
      main: '#fdd8df',
    }
  },
  typography: {
    fontFamily: [
      'Inter', 'sans-serif', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    ].join(','),
    fontSize: 14,
    
    h1:{
      fontSize: '1.25rem',
      '@media print': {
        fontSize: '70%'
      }
    },
    h2:{
      fontSize: '1.20rem',
      '@media print': {
        fontSize: '70%'
      }
    },
    h3:{
      fontSize: '1.15rem',
      '@media print': {
        fontSize: '70%'
      }
    },
    h4:{
      fontSize: '1.10rem',
      '@media print': {
        fontSize: '70%'
      }
    },
    h5:{
      fontSize: '1.05rem',
      '@media print': {
        fontSize: '70%'
      }
    },
    h6:{
      fontSize: '1rem',
      '@media print': {
        fontSize: '70%'
      }
    },
    body1: {
      fontSize: '0.9rem',
      '@media print': {
        fontSize: '10px'
      }
    },
    body2: {
      fontSize: '0.8rem',
      '@media print': {
        fontSize: '10px'
      }
    },
    caption: {
      fontSize: '0.75rem',
      '@media print': {
        fontSize: '8px'
      }
    },
  },
});



theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 2),
        },
      },
    },
    
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

console.log(theme);

moment.locale(`pt-BR`);
authorizationInterceptor();

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(process.env, 'process.env');

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
    <Provider store={store}>
      <RouterProvider router={routes}></RouterProvider>     
      <AlertSnackbarWrapper />
      {/* {createPortal(<AlertSnackbarWrapper />, document.getElementById('snackbar-root'))} */}
      {createPortal(<LoadingModalWrapper />, document.getElementById('loading-root'))}
    </Provider>
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


String.prototype.padLeft = function(length, padString) {
  var str = this;
  while (str.length < length)
      str = padString + str;
  return str;
}