import { BaseService } from './base.service';
import axios from 'axios';
import settings from '../settings';

const apiPath = `${settings.api.url}/product`;


const saleParser = (sale) => {
    return {
        ...sale,
        createdAt: new Date(sale.createdAt),
        associatedAt: new Date(sale.associatedAt),
        confirmationAt: new Date(sale.confirmationAt),
        issueDate: new Date(sale.issueDate)
    }
}

class productService extends BaseService {
  constructor() {
    super();
  }

  search(query, storeId) {
    return axios
      .get(`${apiPath}`, { params: {query, storeId}})
      .then((response) => response.data)
      .then((data) => data.map((item) => saleParser(item)))
      .catch((error) => this.catchCustomError(error));
  }

  
  list(storeId, ids) {
    return axios
      .post(`${apiPath}/${storeId}/list`, { ids: ids})
      .then((response) => response.data)
      .catch((error) => this.catchCustomError(error));
  }
}

export default new productService();