import { Table, TableBody, TableCell, TableContainer, TableHead,TableRow } from "@mui/material";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import useDateFormatter from "../../../hooks/useDateFormatter";

export const ReturnsTable = ({ salesReturns, onOpen }) => {

	const currencyFormatter = useCurrencyFormatter();
	const dateFormatter = useDateFormatter();

	return (
		<>
			{salesReturns.length > 0 ? (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Criado em</TableCell>
								<TableCell>Registrado por</TableCell>
								<TableCell>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{salesReturns.map((saleReturn) => (
								<TableRow
									key={saleReturn.id}
									onClick={() => onOpen(saleReturn)}
								>
									<TableCell>{dateFormatter(saleReturn.createdAt)}</TableCell>
									<TableCell>{saleReturn.createdBy.name}</TableCell>
									<TableCell>{currencyFormatter(saleReturn.total)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<p>Não foram encontradas devoluções.</p>
			)}
		</>
	);
};
