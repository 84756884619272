import { useMemo } from 'react';

function useCurrencyFormatter() {
  const formatCurrency = useMemo(() => {
    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return (value) => currencyFormatter.format(value);
  }, []);

  return formatCurrency;
}

export default useCurrencyFormatter;
