import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { Origin, OriginText } from '../../../models/Origin'
import React, { useState } from 'react'
import { SaleStatus, SaleStatusText } from '../../../models/SaleStatus'

import EmployeesAutoComplete from 'components/Employee/EmployeesAutoComplete'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SearchIcon from '@mui/icons-material/Search'

const SalesFilter = ({ onSearch }) => {

  const [form, setForm] = useState({
    status: "",
    origin: "",
    startDate: "",
    endDate: "",
    seller: ""
  });

  const handleFormChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const handleSellerchange = (s) => {
    setForm({
      ...form,
      seller: s?.id
    })
  }


  const handleSearch = (event) => {
    event.preventDefault()
    onSearch(form)
  }
  
  const statusOptions = Object.values(SaleStatus).map((option, index) => ({
    title: SaleStatusText[option],
    value: option,
  }))

  //add Todos option to statusOptions on the beginning of the array
  statusOptions.unshift({ title: 'Todos', value: '' })

  const originOptions = Object.values(Origin).map((option, index) => ({
    title: OriginText[option],
    value: option,
  }))

  originOptions.unshift({ title: 'Todos', value: '' })
  originOptions.push({ title: 'Com cupom', value: 'Coupon' })

  return (
    <form onSubmit={handleSearch}>
    <Grid2 container spacing={1}>
        <Grid2 xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Origem</InputLabel>
            <Select name="origin" label="Origem" value={form.origin} onChange={handleFormChange}>
              {originOptions.map((option, index) => (
                <MenuItem key={`sale-origin-${index}`} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 xs={12} md={3}>
        <FormControl fullWidth>
          <TextField
            label="Data Inicial"
            type="datetime-local"
            name="startDate"
            value={form.startDate}
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        </Grid2>
        <Grid2 xs={12} md={3}>
        <FormControl fullWidth>
          <TextField
            label="Data Final"
            type="datetime-local"
            name="endDate"
            value={form.endDate}
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        </Grid2>
        
        <Grid2 xs={12} md={2}>
          <EmployeesAutoComplete
                        name="seller"
                        title="Vendedor"
                        css="w-100 col-md-3"
                        onChange={handleSellerchange}
                        selected={form.seller}    />
        </Grid2>
        <Grid2 xs={12} md={1}>
        <IconButton type="submit">
          <SearchIcon />
        </IconButton>
        
        </Grid2>
      </Grid2>
    </form>
  )
}

export default SalesFilter
