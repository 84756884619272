import axios from "axios";
import settings from "../settings";

const apiPath = `${settings.api.url}/coupon`;

const couponParser = (coupon) => {
  return {
    ...coupon,
    createdAt: new Date(coupon.createdAt),
    expiresAt: new Date(coupon.expiresAt),
  };
};

class couponService {
  constructor() {}

  get(id) {
    return axios
      .get(`${apiPath}/${id}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => response.data.data)
      .then((data) => couponParser(data));
  }

  getBySale(saleId) {
    return axios.get(`${apiPath}/by-sale/${saleId}`)
                .then((response) => response.data.data)
                .then((data) => couponParser(data));
  }
}

export default new couponService();
