import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import { EventStatus } from 'models/EventStatus'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import storeEventService from 'services/store-event.service'
import { useAppHooks } from 'hooks/useAppHooks'
import { useHasRole } from '../../hooks/useHasRole'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'

function StoreEventEditionOptions({ storeEvent, sx }) {
	const hasRole = useHasRole(['Administrator', 'Manager']) // Initialize your RoleService	
	const currentUser = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null)
	const [dialogOpen, setDialogOpen] = useState(false)
	const [dialogTitle, setDialogTitle] = useState('')
	const [dialogContent, setDialogContent] = useState('')
	const [onConfirm, setOnConfirm] = useState(null)
	const { withLoading, message } = useAppHooks();

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const cancelHandler = () => {
		handleClose();
		setDialogTitle('Cancelar evento')
		setDialogContent('Você tem certeza que deseja cancelar o evento?')
		setOnConfirm((e) => () => {

			withLoading(async () => {
				await storeEventService.cancel(storeEvent.id);
				message.success('Evento cancelado com sucesso.');
			}).catch(error => {
				message.error('Não foi possível cancelar o evento.', error);
			});
		});
		setDialogOpen(true)
	}

	const postponeHandler = () => {
		handleClose();
		setDialogTitle('Adiar evento')
		setDialogContent('Você tem certeza que deseja adiar o evento?')
		setOnConfirm((e) => () => {
			withLoading(async () => {
				await storeEventService.postpone(storeEvent.id);
				message.success('Evento adiado com sucesso.');
			}).catch(error => {
				message.error('Não foi possível cancelar o evento.', error);
			});
		})
		setDialogOpen(true)
	}

	const editHandler = () => {
		
		navigate(`/store-event/${storeEvent.id}/edit`)
	}

	let menuOptions = <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
			<MenuItem onClick={editHandler}>
				<EditIcon />
				<span>Editar</span>
			</MenuItem>
			<MenuItem onClick={cancelHandler}>
				<CancelIcon />
				<span>Cancelar evento</span>
			</MenuItem>
			
			<MenuItem onClick={postponeHandler}>
				<EventBusyIcon />
				<span>Adiar evento</span>
			</MenuItem>
	</Menu>;

	if (!hasRole && (storeEvent.status !== EventStatus.Reserved || storeEvent.assignee.id !== currentUser.EmployeeIdentifier)) {
		return null
	}

	return (
		<>
      <Box sx={{
            position: 'absolute',
            right: '0'
        }}>
        <IconButton
          onClick={handleOpen}
        >
          <MoreVertIcon></MoreVertIcon>
        </IconButton>
      </Box>
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
					<MenuItem onClick={editHandler}>
						<EditIcon />
						<span>Editar</span>
					</MenuItem>
					<MenuItem onClick={cancelHandler}>
						<CancelIcon />
						<span>Cancelar evento</span>
					</MenuItem>
					{hasRole && (						
						<MenuItem onClick={postponeHandler}>
							<EventBusyIcon />
							<span>Adiar evento</span>
						</MenuItem>
					)}
			</Menu>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>{dialogTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>{dialogContent}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)} variant="contained" color="primary">
						Não
					</Button>
					<Button
						onClick={() => {
							onConfirm();
							setDialogOpen(false)
						}}
						color="primary"
						autoFocus
					>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default StoreEventEditionOptions
