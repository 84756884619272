import { Box, Button, Container, Drawer, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import BasicInfo from 'components/CheckoutCounter/BasicInfo'
import CurrencyInput from 'components/Layout/CurrencyInput'
import PaymentsTable from 'components/CheckoutCounter/PaymentsTable'
import PrintHeader from 'components/Layout/PrintHeader'
import { SaleStatus } from 'models/SaleStatus'
import TillHistory from 'components/CheckoutCounter/TillHistory'
import checkoutCounterService from 'services/checkout-counter.service'
import printDocument from 'services/print'
import storeEventService from 'services/store-event.service'
import storeSalesService from 'services/store-sales.service'
import { useAppHooks } from "hooks/useAppHooks";
import useFormatter from 'hooks/useFormatter'
import { useHasRole } from 'hooks/useHasRole'
import { useParams } from 'react-router-dom'

const CheckoutCounterDetails = ({checkoutCounter, onUpdate}) => {
  const { storeId, id } = useParams()
  const [operationType, setOperationType] = useState(null)
  const [ sales, setSales ] = useState([]);
  const [ storeEvents, setStoreEvents ] = useState([]);
  const [operationAmount, setOperationAmount] = useState('')
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
	const { withLoading, message } = useAppHooks();
	const formatter = useFormatter();
	const isManager = useHasRole(['Administrator', 'Manager']) // Initialize your RoleService

  const handleTillOperation = () => {
    let data;

    withLoading(async () => {

      if (operationType === 'float') {
        data = await checkoutCounterService.tillFloat(checkoutCounter.storeId, checkoutCounter.id, operationAmount)
      } else if (operationType === 'drop') {
        data = await checkoutCounterService.tillDrop(checkoutCounter.storeId, checkoutCounter.id, operationAmount)
      }

      console.info('checkout counter till operation completed', data)
      onUpdate(data)
      setOperationType(null)
      setOperationAmount('')
    }).catch((err) => {
      message.error('Não foi possível realizar a operação', err);
    });
  }

  const handleCloseCheckoutCounter = () => {

    withLoading(async () => {

      const data = await checkoutCounterService.close(checkoutCounter.storeId, checkoutCounter.id);
      onUpdate(data);
      setShowCloseConfirmation(false);

    }).catch((err) => {
      message.error('Não foi possível realizar a operação', err);
    });

  }

  const printCheckoutCounter = () => {

    printDocument(15);

  }

  const closeHandler = () => {
    setShowCloseConfirmation(false);
    printCheckoutCounter();
  }

  useEffect(() => {

    if(!checkoutCounter || !storeId) return;

    withLoading(async () => {

      const saleIds = checkoutCounter.sales.map((sale) => sale.id);
      const sales = await storeSalesService.list(storeId, saleIds);

      setSales(sales.filter((sale) => sale.status === SaleStatus.Approved));

    }).catch((err) => {
      message.error('Não foi possível carregar os totalizadores', err);
    });

  }, [checkoutCounter, storeId]);

  useEffect(() => {
      
    const storeEventIds = sales.map((sale) => sale.storeEventId).filter((value) => !!value).filter((value, index, array) => array.indexOf(value) === index);
    if(!storeEventIds || storeEventIds.length === 0) return setStoreEvents([]);

    withLoading(async () => {
  
        //load store events using storeEventService.get and push to events array
        var storeEvents = await Promise.all(storeEventIds.map((id) => storeEventService.get(id)));
        
        setStoreEvents(storeEvents);
  
      }).catch((err) => {
        message.error('Não foi possível carregar os totalizadores dos eventos', err);
      });

  }, [sales]);


  const getPaymentMethods = (sales) => {
    return sales.reduce((acc, sale) => {
        sale.invoices.forEach(invoice => {
                          const paymentMethod = invoice.paymentMethod.description;
                          if (!acc[paymentMethod]) {
                            acc[paymentMethod] = invoice.total;
                          } else {
                            acc[paymentMethod] += invoice.total;
                          }
                      });
                      
      return acc;
    }, {});

  };


  const storeSales = sales.filter((sale) => !sale.storeEventId);
  const storeSalesTotal = storeSales.reduce((total, sale) => total + sale.total, 0);
  //get distinct storeEventIds

  const storeSalesDetails = ()=>{

      const groupedInvoices = getPaymentMethods(storeSales);

      return (
        <>
        <TableRow>
          <TableCell>Vendas de loja</TableCell>
          <TableCell>{formatter(storeSalesTotal, 'currency')}</TableCell>
        </TableRow>
        
        <TableRow>
          <TableCell colSpan={2}>
            <Table sx={{ width: '90%', marginRight: '0px', marginLeft: 'auto'}}>
              <TableHead>
                <TableRow sx={{backgroundColor: '#f3f3f3!important;'}}>
                  <TableCell>Forma de pagamento</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(groupedInvoices).map((payment, index) => {
                  return (            
                    <TableRow key={index}>
                      <TableCell>{payment}</TableCell>
                      <TableCell>{formatter(groupedInvoices[payment], 'currency')}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
        </>
      );
  }

  return (
    <Container maxWidth={false} className="print" id='component-to-print'>

      {checkoutCounter ? (
        <div>
          {!checkoutCounter.closedAt && (
            <Stack direction="row" justifyContent="right" gap={1} className='no-print'>
              {isManager && (
                <>
                  <Button variant="outlined" onClick={() => setOperationType('float')}>
                    Realizar suprimento
                  </Button>
                  <Button variant="outlined" onClick={() => setOperationType('drop')}>
                    Realizar sangria
                  </Button>
                </>
              )}
              <Button variant="outlined" color="error" onClick={() => setShowCloseConfirmation(true)}>
                Fechar caixa
              </Button>
            </Stack>
          )}

          {/* Till Float/Drop Right Side Panel */}
          <Drawer anchor="right" open={operationType !== null} onClose={() => setOperationType(null)}>
            <Paper style={{ width: 300, padding: 20 }}>
              <Typography variant="h6">{operationType === 'float' ? 'Informe abaixo o valor de suprimento para o caixa:' : 'Informe abaixo o valor de sangria do caixa:'}</Typography>
              <br />
              <CurrencyInput label="Valor" value={operationAmount} onChange={(e) => setOperationAmount(e)} />
              <br />
              <br />
              <Stack direction="row" spacing={2} style={{ marginTop: 20 }} justifyContent="space-between">
                <Button variant="outlined" onClick={() => setOperationType(null)}>
                  Cancelar
                </Button>
                <Button variant="contained" color="primary" onClick={handleTillOperation}>
                  Confirmar
                </Button>
              </Stack>
            </Paper>
          </Drawer>
          {/* Close Confirmation Right Side Panel */}
          <Drawer anchor="right" open={showCloseConfirmation} onClose={() => setShowCloseConfirmation(false)}>
            <Paper style={{ width: 300, padding: 20 }}>
              <Typography variant="h5">Confirmar fechamento</Typography>
              <Typography variant="body1">Tem certeza que deseja fechar o caixa?</Typography>

              <Stack direction="row" spacing={2} style={{ marginTop: 20 }} justifyContent="space-between">
                <Button variant="outlined" onClick={closeHandler}>
                  Não
                </Button>
                <Button variant="contained" color="primary" onClick={handleCloseCheckoutCounter}>
                  Sim
                </Button>
              </Stack>
            </Paper>
          </Drawer>


          <PrintHeader storeId={storeId} />
          
          <BasicInfo checkoutCounter={checkoutCounter} />
          <PaymentsTable checkoutCounter={checkoutCounter} />

          <TillHistory title="Histórico de suprimento" history={checkoutCounter.tillFloatHistory} />
          <TillHistory title="Histórico de sangria" history={checkoutCounter.tillDropHistory} />


          {(storeSalesTotal > 0 || (storeEvents && storeEvents.length > 0)) && (
          <Box sx={{mt: 3}}>
            <Typography variant="body1">Detalhamento de venda por origem:</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Origem</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeSalesTotal > 0 && storeSalesDetails()}

                {storeEvents && storeEvents.length > 0 && storeEvents.map((item, index) => {

                  const eventSales = sales.filter((sale) => sale.storeEventId === item.id);
                  //console.table(eventSales, ['storeEventId', 'status', 'giftCardTotal', 'couponTotal', 'total']);
                  const couponTotal = eventSales.reduce((total, sale) => total + sale.couponTotal, 0);
                  const giftCardTotal = eventSales.reduce((total, sale) => total + sale.giftCardTotal, 0);
                  const total = eventSales.reduce((total, sale) => total + sale.total, 0);

                  //group sales.invoice by description and sum total
                  const groupedInvoices = getPaymentMethods(eventSales);

                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell>{formatter(item.eventDate, 'date')} {formatter(item.eventStartAt, 'time')} as {formatter(item.eventEndAt, 'time')}</TableCell>
                        <TableCell>{formatter(total - couponTotal - giftCardTotal, 'currency')}</TableCell>
                      </TableRow>
                      <TableRow key={item.id + "_details"}>
                        <TableCell colSpan={2}>
                          <Table sx={{ width: '90%', marginRight: '0px', marginLeft: 'auto'}}>
                            <TableHead>
                              <TableRow sx={{backgroundColor: '#f3f3f3!important;'}}>
                                <TableCell>Forma de pagamento</TableCell>
                                <TableCell>Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(groupedInvoices).map((payment, index) => {
                                return (            
                                  <TableRow key={index}>
                                    <TableCell>{payment}</TableCell>
                                    <TableCell>{formatter(groupedInvoices[payment], 'currency')}</TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          )}

          <br />
          <br />
          <Button variant="outlined" color="error" onClick={printCheckoutCounter} className='no-print'>
            Imprimir caixa
          </Button>
        </div>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Container>
  )
}

export default CheckoutCounterDetails;