const printDocument = (margin, content) => {

    if(!margin){
        margin = 0;
    }

    if(!content){
        content = document.getElementById('component-to-print');
    }
    
    const printContents = content.innerHTML;
	const iframe = document.createElement('iframe');

    window.printDone = (mql) => {        
        if (!mql.matches) {
            iframe.remove();
        }
    };
    
	iframe.style.display = 'none'
	document.body.appendChild(iframe)
	const iframeDoc = iframe.contentDocument || iframe.contentWindow.document


    const dataEmotionInner = [...document.querySelectorAll('[data-emotion]')].flatMap(({ sheet }) => [...sheet.cssRules].map(rules => rules.cssText)).join('\n')
    const dataEmotionStyle = document.createElement('style');
    dataEmotionStyle.innerHTML = dataEmotionInner;
    iframeDoc.head.appendChild(dataEmotionStyle);

	// Copy all styles to the new iframe
	Array.from(document.head.querySelectorAll('link[rel=stylesheet], style')).forEach((linkOrStyle) => {
		iframeDoc.head.appendChild(linkOrStyle.cloneNode(true))
	})

	const style = document.createElement('style');
    /**
     * html{
                width: 5.75cm;height: 100%;min-width: 5.75cm !important;margin-bottom:0;
            }
     */
	style.innerHTML = `
        @media print {
            .no-print {display: none !important;}
            @page{
                size:auto;margin:${margin};padding:0;
                @top-center{margin:0}
                @top-left{margin:0}
                @top-right{margin:0}
                @top-left-corner{margin:0}
                @top-right-corner{margin:0}
                @bottom-center{margin:0}
                @bottom-left{margin:0}
                @bottom-right{margin:0}
                @bottom-left-corner{margin:0}
                @bottom-right-corner{margin:0}
            }
            body{
                width:100%;height:100%;min-width:auto !important;margin-bottom:0;text-align:center;
            }
            table{
                width: 100%;
            }
            .break{page-break-after:always}
            .logo{
                display: block;
                text-align: center;
                margin: 0 auto;
            }
        }
    
        svg{
            max-width: 100% !important;
        }
    `;

	iframeDoc.head.appendChild(style);

    const script = document.createElement('script');
    script.innerHTML =`
    if (window.matchMedia) {
		var mediaQueryList = window.matchMedia('print')
		mediaQueryList.addEventListener('change', (mql)=>{
            window.parent.printDone(mql);
        });
	}    
    setTimeout(() => {
        window.print();
    }, 1000);
    `;
	iframeDoc.head.appendChild(script);

	iframeDoc.body.innerHTML = printContents;

	//iframe.contentWindow.print();
}

export default printDocument
