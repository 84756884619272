import { Box, Button, Divider, Stack } from "@mui/material";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DropdownButton from "components/Layout/DropdownButton";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import StoreEventEditionOptions from "../../../components/StoreEvent/StoreEventEditionOptions";
import StoreEventSummary from "../../../components/StoreEvent/StoreEventSummary";
import SubMenu from "components/Layout/SubMenu";
import { setEvent } from "../../../store/slice/store-event.slice";
import storeEventService from "../../../services/store-event.service";
import styled from "@emotion/styled";
import { useEffect } from "react";

const IndexPage = (props) => {
  const { storeEventId } = useParams();
  const storeEvent = useSelector((state) => state.storeEvent);
  const  {event } = storeEvent;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    storeEventService.get(storeEventId).then((data) => {
      dispatch(setEvent(data));
    });
  }, [storeEventId]);

  if (!event) {
    return <p>Loadding</p>;
  }


  const LeftPanel = styled(Grid)({
    position: "relative",
    paddingBottom: '20px'

  });
  const RightPanel = styled(Grid)({
    boxShadow: "0 0 6px #00000059",
    webkitBoxShadow: "0px 0px 6px 0px rgba(0,0,0,.35)",
    mozBoxShadow: "0px 0px 6px 0px rgba(0,0,0,.35)",
    position: "relative",

  });

  return (
    <Grid container spacing={0} minHeight='100%'>
      <LeftPanel xs={6} md={2.5} direction='column' className="no-print" sx={{display: 'flex', height: 'auto', justifyContent:'flex-start', alignItems:'stretch' }} >
        <StoreEventEditionOptions storeEvent={event} />
        <StoreEventSummary storeEvent={event} /> 
      </LeftPanel>

      <RightPanel xs={6} md={9.5} padding={3}>
        <SubMenu>
          <NavLink end to={`/store-event/${storeEventId}`}>Geral</NavLink>
          <NavLink end to={`/store-event/${storeEventId}/sales`} >Vendas</NavLink>
          <NavLink end to={`/store-event/${storeEventId}/returns`}>Devoluções</NavLink>

          <Divider orientation="vertical" flexItem />

          <NavLink end to={`/store-event/${storeEventId}/sales/create`} >Registrar Venda</NavLink>
          
        </SubMenu>
        <Box>
          <Outlet />
        </Box>
      </RightPanel>
    </Grid>
  );
};

export default IndexPage;
