import { Alert, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { NavLink } from 'react-router-dom'
import { OriginText } from '../../models/Origin'
import { SaleStatusText } from '../../models/SaleStatus'
import customerService from 'services/customer.service'
import giftCardService from 'services/gift-card.service'
import storeEventService from 'services/store-event.service'
import styled from '@emotion/styled'
import { useAppHooks } from 'hooks/useAppHooks'
import useCurrencyFormatter from '../../hooks/useCurrencyFormatter'
import useDateFormatter from '../../hooks/useDateFormatter'
import useFormatter from 'hooks/useFormatter'

function SalesDetails(props) {
	const { sale, openCouponHandler } = props
	const [ storeEvent, setStoreEvent ] = useState(null);
	const [ customer, setCustomer ] = useState(sale.customer || null);

	const { withLoading, message } = useAppHooks();
	const formatter = useFormatter();
	const currencyFormatter = useCurrencyFormatter()
	const dateFormatter = useDateFormatter()

	const bridgeName = storeEvent?.bridge?.name?.split(' ')[0] || storeEvent?.contractorName?.split(' ')[0];
	const origin = storeEvent ? `Chá da ${bridgeName}` : 'Venda de loja';

	const CustomAlert = styled(Alert)(({ theme }) => ({
		'& .MuiAlert-message': {
		  flexGrow: 1,
		},
	  }));

	useEffect(() => {
		
		if(!sale) return;
		if(!sale.storeEventId) return;

		// Get the store event
		withLoading(async () => {
			try {
				const storeEvent = await storeEventService.get(sale.storeEventId);
				setStoreEvent(storeEvent);
			} catch (error) {
				message.error('Não foi possível carregar os dados do evento.', error);
			}
		});

		//load user data from api and set to state
		if(!sale.customer) return;
		withLoading(async () => {
			try {
				const data = await customerService.get(sale.customer.id);

				setCustomer(data);
			} catch (error) {
				if(error.response.status === 404) return;
				message.error('Não foi possível carregar os dados do cliente.', error)
			}
		});

		

	}, [sale])



	return (
		<Stack className="print" position="relative">
			<Typography variant="h5">Detalhes da venda</Typography>
			<br />

			<Grid2 container spacing={2}>
				<Grid2 xs={12} sm={6}>
					<Stack>
						<Stack direction="row" gap={3}>
							<Typography variant="body1">
								<strong>Série:</strong> {sale.series}
							</Typography>
							<Typography variant="body1">
								<strong>Número:</strong> {sale.number}
							</Typography>
						</Stack>

						<Typography variant="body1">
							<strong>Status:</strong> {SaleStatusText[sale.status]}
						</Typography>
						<Typography variant="body1">
							<strong>Data de criação:</strong> {formatter(sale.createdAt, 'dateTime')}
						</Typography>
						
						<Typography variant="body1">
							<strong>Vendedor:</strong> {sale.seller?.name || '--'}
						</Typography>
						<Typography variant="body1">
							<strong>Origem:</strong> {OriginText[sale.origin]} 
							{storeEvent && (
								<>
								{' |'}
								<NavLink to={`/store-event/${storeEvent.id}`} style={{marginLeft: '10px'}}>
									Chá da {bridgeName}
								</NavLink>
								</>
							)}
						</Typography>
						<Typography variant="body1">
							<strong>Devolução:</strong> {sale.isReturnSale ? 'Sim' : 'Não'}
						</Typography>
						
						<Typography variant="body1">
							<strong>Cupom(s):</strong>{' '}
							{sale.returnCouponIds?.map((c, i) => (
								<Button variant="outlined" size="small" onClick={openCouponHandler.bind(null, c)}>
									{c}
								</Button>
							)) || 'Nenhum'}
						</Typography>
					</Stack>

				</Grid2>
				<Grid2 item xs={12} sm={6}>
					<Stack>
						<Typography variant="body1">
							<strong>Cliente:</strong> {sale.customer?.name || '--'}
						</Typography>

						<Typography variant="body1">
							<strong>CPF:</strong> {sale.customer?.cpf || '--'}
						</Typography>

						<Typography variant="body1">
							<strong>Telefone:</strong> {sale.customer?.phone || '--'}
						</Typography>

						<Typography variant="body1">
							<strong>Data de nascimento:</strong> {sale.customer?.birthDate || '--'}
						</Typography>

					</Stack>
				</Grid2>

			</Grid2>
			
			<br />
			
			<br />
			<br />
			<Typography variant="h6">Itens:</Typography>
			<TableContainer component={Paper}>
				<Table className="table table-striped table-bordered" aria-label="simple table">
					<TableHead className="table-dark">
						<TableRow>
							<TableCell>Produto</TableCell>
							<TableCell>Quantidade</TableCell>
							<TableCell>Preço</TableCell>
							<TableCell>Descontos</TableCell>
							<TableCell>Acréscimo</TableCell>
							<TableCell>Total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sale.items.map((item, index) => (
							<TableRow key={`sales_item_${index}`}>
								<TableCell>{item.product.name}</TableCell>
								<TableCell>{item.quantity}</TableCell>
								<TableCell>{formatter(item.unitaryValue, 'currency')}</TableCell>
								<TableCell>{formatter(item.discount, 'currency')}</TableCell>
								<TableCell>{formatter(item.added, 'currency')}</TableCell>
								<TableCell>{formatter(item.total, 'currency')}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<br />
			<br />
			<Typography variant="h6">Faturas:</Typography>

			<TableContainer component={Paper}>
				<Table className="table table-striped table-bordered" aria-label="simple table">
					<TableHead className="table-dark">
						<TableRow>
							<TableCell>Forma Pagamento</TableCell>
							<TableCell>Parcelas</TableCell>
							<TableCell>Total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sale.invoices.map((item, index) => (
							<TableRow key={`sales_invoice_item_${index}`}>
								<TableCell>{item.paymentMethod.description}</TableCell>
								<TableCell>{item.installments}</TableCell>
								<TableCell>{formatter(item.total, 'currency')}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<br />
			<br />

			<CustomAlert severity="info" sx={{mb: 2}}>
				<Stack direction="row" gap={2} justifyContent='space-between'>
					<Typography variant="caption">
					<strong>Subtotal:</strong> {formatter(sale.subtotal, 'currency')}
					</Typography>
					<Typography variant="caption">
					<strong>Desconto:</strong> {formatter(sale.discount, 'currency')}
					</Typography>
					<Typography variant="caption">
						<strong>Adicional:</strong>
						{formatter(sale.addition, 'currency')}
					</Typography>
					<Typography variant="caption">
						<strong>Frete:</strong>
						{formatter(sale.shipping, 'currency')}
					</Typography>
					<Typography variant="caption">
					<strong>Aprovado:</strong> 
					{formatter(sale.total, 'currency')}
					</Typography>

				</Stack>
			</CustomAlert>
		</Stack>
	)
}

export default SalesDetails
