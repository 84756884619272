export class ObjectCleaner {
  static clean(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => ObjectCleaner.clean(item));
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = ObjectCleaner.clean(value);
      if (cleanedValue !== null && cleanedValue !== undefined && cleanedValue !== "") {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
  }
}