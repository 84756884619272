export const SaleStatus = {
    Indifferent:10,
    Typing:20,
    Approved:30,
    Canceled:40,
    Denied:50,
    Disenable:60
};

export const SaleStatusText = {
    [SaleStatus.Indifferent]: "Indiferente",
    [SaleStatus.Typing]: "Em digitação",
    [SaleStatus.Approved]: "Aprovado",
    [SaleStatus.Canceled]: "Cancelado",
    [SaleStatus.Denied]: "Negado",
    [SaleStatus.Disenable]: "Desabilitado"
};

export const SaleStatusColor = {
    [SaleStatus.Indifferent]: "#9e9e9e",
    [SaleStatus.Typing]: "#00bcd4",
    [SaleStatus.Approved]: "#8bc34a",
    [SaleStatus.Canceled]: "#f44336",
    [SaleStatus.Denied]: "#d20e00",
    [SaleStatus.Disenable]: "#b67c00"
};

