export const ProductType = {
    Simple: 1,
    Variation: 2,
    Grouped: 3,

    GiftCard: 99,

    Downloadable: 11,
    Course: 12,


    Service: 13,
    EventService: 14
}

export const ProductTypeText = {
    [ProductType.Simple]: "Simples",
    [ProductType.Variation]: "Variação",
    [ProductType.Grouped]: "Agrupado",

    [ProductType.GiftCard]: "Vale Presente",

    [ProductType.Downloadable]: "Download",
    [ProductType.Course]: "Curso",

    [ProductType.Service]: "Serviço",
    [ProductType.EventService]: "Serviço de Evento"
}