import { useRouteError } from "react-router-dom";
import classes from "./ErrorPage.module.css";

const ErrorPage = (props) => {
    const error = useRouteError();
    console.error(error);

    return (
        <div className={classes.error}>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p className={classes.message}>
            {error.statusText || error.message}
          </p>
        </div>
    );
};

export default ErrorPage;
