import { Box, Typography } from "@mui/material";

import useFormatter from "hooks/useFormatter";

export const PendingItem = ({ item, onClick }) => {

    const formatter = useFormatter();

    return (

        <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, cursor: 'pointer',
              borderBottom: 1, borderColor: 'divider', '&:hover': { backgroundColor: 'grey.50' } }}
            onClick={() => onClick(item)}
        >
            <Box>
                <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                    {formatter(item.createdAt, 'dateTime')}
                </Typography>
                <Typography variant="body2" component="div" sx={{ color: 'text.secondary' }}>
                    Registrado por: {item.createdBy.name}
                </Typography>
            </Box>
            <Box>
                <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                    {formatter(item.total, 'currency')}
                </Typography>
                <Typography variant="body2" component="div" sx={{ color: 'text.secondary', textAlign: 'right' }}>
                    {item.items.length} itens
                </Typography>
            </Box>
        </Box>
    );


};
