import axios from 'axios';
import settings from '../settings';

const apiPath = `${settings.api.url}/store-event`;

class storeEventService {
  get(storeEventId) {
    return axios
      .get(`${apiPath}/${storeEventId}`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  getOrder(storeEventId) {
    return axios
      .get(`${apiPath}/${storeEventId}/order`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  create(storeEvent) {
    return axios
      .post(`${apiPath}/create`, storeEvent)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  update(storeEvent) {
    return axios
      .post(`${apiPath}/${storeEvent.id}`, storeEvent)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  cancel(id) {
    return axios
      .delete(`${apiPath}/${id}`, { data: {} })
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  setReturnLimit(id) {

    //create a new date with the current date adding 7 days and setting the time to 23:59:59:999
    const date = new Date(new Date().setDate(new Date().getDate() + 7));
    date.setHours(23,59,59,999);

    return axios
      .patch(`${apiPath}/${id}/set-return-limit?days=7`, { data: {} })
      .then((response) => date.toLocaleString());
  }

  postpone(id) {
    return axios
      .put(`${apiPath}/${id}/postpone`, { data: {} })
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  updateActivity(storeEventId, activity, value) {
    return axios
      .put(`${apiPath}/${storeEventId}/activities`, { activity, value })
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  updateData(storeEventId, key, data) {
    return axios
      .post(`${apiPath}/${storeEventId}/set-data`, { id: storeEventId, key, data })
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));

  }

  search(storeEventRequest) {

    return axios
      .post(`${apiPath}/search`, storeEventRequest)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  signedRecently(page, size) {
    return axios
      .get(`${apiPath}/signed-recently/${size}/${page}`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  registerTransaction(storeEventId, transaction, file) {
    let formParams = new FormData();
    formParams.append('model', JSON.stringify(transaction));
    formParams.append('file', file);

    return axios
      .post(`${apiPath}/${storeEventId}/register-transaction`, formParams)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  getReceiptFile(storeEventId, transactionId) {
    const headers = { 'Content-Type': 'text/plain; charset=utf-8' };
    const options = { responseType: 'blob' };

    return axios
      .get(`${apiPath}/${storeEventId}/receipt/${transactionId}`, {
        headers,
        ...options,
      })
      .then((response) => response.data.data);
  }

  attach(storeEventId, file) {
    let formParams = new FormData();
    formParams.append('file', file);

    return axios
      .post(`${apiPath}/${storeEventId}/attach`, formParams)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  attachments(storeEventId) {
    return axios
      .get(`${apiPath}/${storeEventId}/attachments`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  downloadAttachment(storeEventId, file) {
    const headers = { 'Content-Type': 'text/plain; charset=utf-8' };
    const options = { responseType: 'blob' };

    return axios
      .get(`${apiPath}/${storeEventId}/attachment/${file}`, {
        headers,
        ...options,
      })
      .then((response) => response.data);
  }
  downloadAttachmentUrl(storeEventId, file) {
    return `${apiPath}/${storeEventId}/attachment/${file}`;
  }


  catchCustomError(response) {
    let result = response.data;

    if (result) {
      if (result.errors) {
        if (Object.keys(result.errors).length > 0) {
          throw Object.values(result.errors)[0];
        }
      }

      if (result.success === false) {
        throw result.errors[0];
      }
    }

    if (response.ok === false) {
      if (response.status === 403) {
        throw "Você não possui acesso para realizar essa operação, tente novamente ou faça logoff e entre novamente.";
      }
      if (response.message) {
        throw response.message;
      }
    }

    throw response;
  }
}

export default new storeEventService();
