import { Stack, styled } from "@mui/material";

const SubMenu = styled(Stack)(({ theme }) => ({
    spacing: 2,
    flexDirection: "row",
    marginBottom: theme.spacing(3.5),
    borderRadius: '5px',
    background: theme.palette.primary.main,
    boxShadow: "0px 3px 7px 0px #260c0c85",
    justifyContent: 'flex-start',

    '& a': {
      color: theme.palette.primary.contrastText,
      padding: '6px 30px',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },

    '& a.active': {
      borderRadius: '5px',
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.dark,
    },
  }));

  export default SubMenu;