import axios from "axios";
import settings from "../../settings";
import { authActions } from "../slice/auth-slice";
import jwt_decode from "jwt-decode";

export const authenticate = (action) => {
    return async (dispatch) => {
        try {
            
            const response = await axios.post(`${settings.api.url}/auth/login`, { Username: action.username, password: action.password });
            const token = response.data.data;

            console.log('reponse', token);

            token.expiration = new Date(token.expiration);
            token.payload = jwt_decode(token.token);

            await dispatch(authActions.login({
                token: token.token,
                user: token.payload,
                expiration: token.expiration.getTime()
            }));


        } catch (error) {
            console.log(error);
            throw error;
        }
    };
};
