import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import checkoutCounterService from '../../../services/checkout-counter.service'
import CheckoutCounterDetails from 'components/CheckoutCounter/CheckoutCounterDetails'
import { Typography } from '@mui/material'

export default function DetailsPage() {
  const [checkoutCounter, setCheckoutCounter] = useState(null)
  const { storeId, id } = useParams()

  const onUpdateHandler = (checkoutCounter) => {
    setCheckoutCounter(checkoutCounter);
  };

  useEffect(() => {
    if (!storeId || !id) return

    // Fetch checkout counter details from API
    checkoutCounterService.get(storeId, id).then((response) => {
      console.log('checkoutCounter', response)
      setCheckoutCounter(response)
    })
  }, [storeId, id])

  return (
    <>    
      <Typography variant="h4">Informações do caixa</Typography>
      <CheckoutCounterDetails checkoutCounter={checkoutCounter} onUpdate={onUpdateHandler} />
    </>
  )
}
