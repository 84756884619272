import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
    name: 'stores',
    initialState: {
        list: [],
        dafault: null
    },
    reducers: {
        setStores: (state, action) => {
            state.list = [...action.payload].map((store) => ({...store, id: store.id.toLowerCase() }));

            if (state.list.length === 1) {
                state.default = state.list[0];
            }

        },

        setDefault: (state, action) => {
            state.default = action.payload;
        }

    },
});

export const {
    setStores,
    setDefault
} = storeSlice.actions;

export default storeSlice;







        