import { useRef } from "react";

export const useDebounce = () => {

    const debounceRef = useRef(null);

    return (func, wait) => {
        if(debounceRef.current){
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(func, wait);
    };
}

