import { useSelector } from 'react-redux';

export const useHasRole = (requiredRoles) => {
  const auth = useSelector((state) => state.auth);
  const userRoles = auth.user.role;
  
  if (!Array.isArray(requiredRoles)) {
    requiredRoles = [requiredRoles];
  }

  return requiredRoles.some((role) => userRoles.includes(role));
};
