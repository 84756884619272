const printFile = (margin, blob) => {

    if(!margin){
        margin = 0;
    }

    if(!blob){
        return;
    }
    
    const objectURL = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objectURL;
    iframe.width = '100%';
    iframe.height = '800';
    document.body.appendChild(iframe);

    window.printDone = (mql) => {        
        if (!mql.matches) {
            iframe.remove();
        }
    };
    
	iframe.style.display = 'none'
	document.body.appendChild(iframe)
	const iframeDoc = iframe.contentDocument || iframe.contentWindow.document



    const script = document.createElement('script');
    script.innerHTML =`
    if (window.matchMedia) {
		var mediaQueryList = window.matchMedia('print')
		mediaQueryList.addEventListener('change', (mql)=>{
            window.parent.printDone(mql);
        });
	}    
    setTimeout(() => {
        window.print();
    }, 1000);
    `;
    
	iframeDoc.head.appendChild(script);

}

export default printFile
