import { BaseService } from "./base.service";
import axios from "axios";
import settings from "../settings";

const apiPath = `${settings.api.url}/sales-return`;

class storeSalesReturnService extends BaseService {
	constructor() {
		super();
		this.salesReturn = null;
	}

	getByStoreEvent(storeEventId) {
		return axios
			.get(`${apiPath}/by-store-event/${storeEventId}`)
			.then((response) => response.data.data)
			.catch((error) => this.catchCustomError(error));
	}

	getBySale(saleId) {
		return axios
			.get(`${apiPath}/by-sale/${saleId}`)
			.then((response) => response.data.data)
			.catch((error) => this.catchCustomError(error));
	}

	getPending(stores) {
		return axios
			.post(`${apiPath}/pending`, { stores })
			.then((response) => response.data.data)
			.catch((error) => this.catchCustomError(error));
	}

	get(id) {
		return axios
			.get(`${apiPath}/${id}`)
			.then((response) => response.data.data)
			.catch((error) => this.catchCustomError(error));
	}

	create(storeId, storeEventId, saleId, items, customer) {
		const payload = {
			storeId,
			items,
			customer
		};

		if(saleId)
			payload.saleId = saleId;

		if(storeEventId)
			payload.storeEventId = storeEventId;

		return axios
			.post(`${apiPath}`, payload)
			.then((response) => {
				if (!this.salesReturn) {
					this.salesReturn = [];
				}
				this.salesReturn.push(response.data.salesReturn);
				return response.data.data;
			})
			.catch((error) => this.catchCustomError(error));
	}

	confirm(id) {
		return axios
			.put(`${apiPath}/${id}/confirm`)
			.then((response) => response.data.data);
	}
}

export default new storeSalesReturnService();
