import axios from 'axios';
import settings from '../settings';

const apiPath = `${settings.api.url}/signature`;

class signatureService {
  
  getByStoreEvent(storeEventId) {
    return axios.get(`${apiPath}/store-event/${storeEventId}`)
                .then((response) => response.data.data)
                .catch((error) => this.catchCustomError(error));
  }

  updateBridgeAndContractor(request) {
    return axios.post(`${apiPath}/store-event/${request.storeEventId}/update-bridge-and-contractor`, request)
                .then((response) => response.data.data)
                .catch((error) => this.catchCustomError(error));
  }

  createToken(storeEventId, signingRequestId) {
    return axios.get(`${apiPath}/store-event/${storeEventId}/${signingRequestId}/create-token`)
                .then((response) => response.data.data)
                .catch((error) => this.catchCustomError(error));
  }

  sign(storeEventId, signingRequestId, token) {
    return axios.post(`${apiPath}/store-event/${storeEventId}/${signingRequestId}/sign`, token)
                .then((response) => response.data.data)
                .catch((error) => this.catchCustomError(error));
  }

  signedDocumentUrl(storeEventId, signatureId) {
    return `${apiPath}/store-event/${storeEventId}/signed-document/${signatureId}`;
  }
}

export default new signatureService();