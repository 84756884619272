import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../store/slice/global-slice'

export const useLoading = () => {
  //create hook to display and hide loading indicator based on global state
  const isLoading = useSelector((state) => state.global.isLoading)
  const dispatch = useDispatch()

  //return two functions, one to show loading indicator and other to hide loading indicator
  return {
    show: () => {
      dispatch(setLoading(true))
    },
    hide: () => {
      dispatch(setLoading(false))
    },
  }
}

export const useLoadingWith = () => {
  const loading = useLoading();

  const withLoading = async (asyncFunction) => {
    loading.show();

    try {
      const result = await asyncFunction()
      return result
    } catch (error) {
      throw error
    } finally {
        loading.hide();
    }
  }

  return withLoading;
}
