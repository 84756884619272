import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Box } from '@mui/material'
import useFormatter from '../../hooks/useFormatter';

export default function TillHistory({ title, history }) {
  const formatter = useFormatter();

  if (!history || history.length < 1) return <div></div>

  return (
    <Box sx={{mt: 3}}>
      <Typography variant="body1">{title}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Responsável</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{formatter(item.date, 'dateTime')}</TableCell>
              <TableCell>{formatter(item.amount, 'currency')}</TableCell>
              <TableCell>{item.operator.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
