import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/slice/auth-slice";

const ProtectedRoute = ({ children }) => {
    const auth = useSelector((state) => state.auth);
    const location = useLocation();
    const dispatch = useDispatch();


    if (!auth.isLoggedIn) {
        dispatch(authActions.logout());
        //window.location.href = "/auth";
        //return null;
         return <Navigate to={{
                            pathname: "/login" ,
                            search: `?returnUrl=${encodeURIComponent(location.pathname)}`
                        }}
                        replace 
                />;
    }

    return children;
};

export default ProtectedRoute;
