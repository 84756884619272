import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import { useAppHooks } from 'hooks/useAppHooks';

const SalesTable = ({ sales, onClick }) => {

    const { formatter } = useAppHooks();


    if(!sales.length) return (
        <Typography variant="body1">
            Nenhuma venda encontrada
        </Typography>
    );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data da venda</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Forma de pagamento</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.map((sale) => (
            <TableRow key={sale.id} onClick={onClick.bind(null, sale)} sx={{cursor: 'pointer'}}>
              <TableCell>{formatter(sale.createdAt, 'dateTime')}</TableCell>
              <TableCell>{sale.customer?.name || '--'}</TableCell>
              <TableCell>{sale.invoices.map(i=> `${i.paymentMethod.description} (${i.installments}x${formatter(i.total/i.installments, 'currency')})`).join(', ')}</TableCell>
              <TableCell>{formatter(sale.total, 'currency')}</TableCell>
              {/* Outras células conforme necessário */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SalesTable;
