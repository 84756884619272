import { Box, Button, Container, Drawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CheckoutCounterDetails from "components/CheckoutCounter/CheckoutCounterDetails";
import CreateCheckoutCounter from "components/CheckoutCounter/Create";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import checkoutCounterService from "services/checkout-counter.service";

const GeneralPage = () => {
	const { storeId } = useParams();
	const [checkoutCounter, setCheckoutCounter] = useState(null);
	const [openCheckoutCounter, setOpenCheckoutCounter] = useState(false);
	const navigate = useNavigate();

	const onUpdateHandler = (checkoutCounter) => {
		setCheckoutCounter(checkoutCounter);
	  };

	const checkoutClickHandler = (checkoutCounter) => {
		// redirect to checkout counter page
		navigate(`/store/${storeId}/checkout-counter/${checkoutCounter.id}`);
	}
	

	useEffect(() => {
		
		checkoutCounterService.getCurrent(storeId).then((response) => {
			setCheckoutCounter(response)
		  }).catch((error) => {
			console.log(error);
		  });

	}, [storeId]);	

	return (
		<Container maxWidth={false}>
			<Grid container spacing={4}>
				{checkoutCounter && (
					<CheckoutCounterDetails checkoutCounter={checkoutCounter} onUpdate={onUpdateHandler} />
				)}
				{!checkoutCounter && (
					<Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
						<Typography variant="body1" component="div" sx={{ color: 'text.secondary' }}>
						Não foi encontrado nenhum caixa aberto
						</Typography>
						<Button variant="outlined" onClick={() => setOpenCheckoutCounter(true)}>clique aqui para abrir o caixa</Button>
					</Box>
				)}

				{/* Till Float/Drop Right Side Panel */}
				<Drawer anchor="right" open={openCheckoutCounter} onClose={() => setOpenCheckoutCounter(false)}>
					<CreateCheckoutCounter store={storeId}  onCancel={() => setOpenCheckoutCounter(false)} onSuccess={checkoutClickHandler} />
				</Drawer>
			</Grid>
		</Container>
	);
};

export default GeneralPage;
