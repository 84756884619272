import axios from "axios";
import settings from "../settings";

const apiPath = `${settings.api.url}/gift-card`;

const giftCardParser = (giftCard) => {
  return {
    ...giftCard,
    createdAt: new Date(giftCard.createdAt),
  };
};

class giftCardService {
  constructor() {}

  get(id) {
    return axios
      .get(`${apiPath}/${id}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => response.data)
      .then((data) => giftCardParser(data));
  }

  getBySale(saleId) {
    return axios
      .get(`${apiPath}/by-sale/${saleId}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => response.data)
      .then((data) => data.map((d) => giftCardParser(d)));
  }

  getByCustomer(customerId) {
    return axios.get(`${apiPath}/by-customer/${customerId}`)
                .then((response) => response.data)
                .then((data) => data.map(d=> giftCardParser(d)));
  }
}

export default new giftCardService();
