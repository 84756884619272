import CreatePage from 'pages/Administration/Sales/SalesCreatePage';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SalesCreatePage = () => {

    const location = useLocation();
    const coupon = location?.state?.coupon;
    const customer = location?.state?.customer;
    const { event } = useSelector((state) => state.storeEvent);

    return (
        <CreatePage storeId={event.store.id} storeEventId={event.id} customer={customer} coupon={coupon} />
    );


};

export default SalesCreatePage;