import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { EventStatus } from 'models/EventStatus'
import { ProductType } from 'models/ProductType'
import { SaleStatus } from 'models/SaleStatus'
import TitleBox from 'components/Layout/TitleBox'
import storeEventService from '../../../services/store-event.service'
import storeSalesService from 'services/store-sales.service'
import { useAppHooks } from 'hooks/useAppHooks'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'

const ServicesOrders = (props) => {
	const { storeEvent } = props
	const [order, setOrder] = useState(null)
	const [transactions, setTransactions] = useState([])
	const { withLoading, message, formatter } = useAppHooks()
	const currencyFormatter = useCurrencyFormatter()

	useEffect(() => {
		if (!storeEvent) {
			return
		}

		if (storeEvent.status === EventStatus.Reserved || storeEvent.status === EventStatus.Cancelled) {
			return
		}

		if (storeEvent) {
			withLoading(async () => {
				const order = await storeEventService.getOrder(storeEvent.id);
				setOrder(order);

				const sales = await storeSalesService.all(storeEvent.store.id, storeEvent.id);
				const list = sales.filter((s) => s.status === SaleStatus.Approved && s.items.filter((p) => p.product.productType === ProductType.EventService || p.product.productType === ProductType.Service).length > 0)
									.map((sale) =>
										sale.items.flatMap((i) => ({
											product: i.product,
											value: i.unitaryValue + i.added - i.discount,
											qtd: i.quantity,
											seller: sale.seller,
											status: sale.status,
											createdAt: sale.createdAt,
											invoices: sale.invoices,
										})),
									)
									.flat();

				setTransactions(list)

			}).catch((e) => {
				if(e.response.status !== 404) {
					console.error(e);
					message.error('Erro ao carregar o pedido', e);
				}
			});
		}
	}, [storeEvent])

	const getReceiptFile = (orderTransaction) => {
		// Make your API call here to get the receipt file
		// Example of how to open the receipt file
		window.open(`URL_OF_RECEIPT_FILE`)
	}

	if (!order) {
		return
	}

	// Add your order and transaction state or props here.
	//const transactions = []; // This should be your state or props for transactions.

	return (
		<>
			<TitleBox title="Pagamento do contrato:" sx={{ mt: 5, mb: 2 }} />
			<div>
				<TableContainer component={Paper}>
					<Table size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Serviços</TableCell>
								<TableCell width={100}>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{order.items.map((item) => (
								<TableRow key={item.id}>
									<TableCell>{item.observation}</TableCell>
									<TableCell>
										{item.total.toLocaleString('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										})}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow sx={{ background: '#e3e3e3' }}>
								<TableCell>Total:</TableCell>
								<TableCell>{currencyFormatter(order.total)}</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>

				<br />
				<TableContainer component={Paper}>
					<Table size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Data</TableCell>
								<TableCell>Pagamentos</TableCell>
								<TableCell width={100}>Valor</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{transactions.length > 0 &&
								transactions.map((transaction, index) => (
									<TableRow key={`transaction-${index}`}>
										<TableCell>{formatter(transaction.createdAt, 'date')}</TableCell>
										<TableCell>{transaction.product.name}</TableCell>
										<TableCell>{formatter(transaction.value, 'currency')}</TableCell>
									</TableRow>
								))}
							{transactions.length === 0 && (
								<TableRow>
									<TableCell colSpan={4}>Nenhum pagamento realizado</TableCell>
								</TableRow>
							)}

							{/* {order.transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{transaction.paymentMethod.name}</TableCell>
                  <TableCell>{`${transaction.value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })} (${transaction.installments}x)`}</TableCell>
                  <TableCell>
                    {new Date(transaction.authorizedAt).toLocaleDateString(
                      "pt-BR"
                    )}
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => getReceiptFile(transaction)}
                      target="_blank"
                      className="receipt"
                    >
                      <AttachFileIcon />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
              {order.transactions.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>Nenhum pagamento realizado</TableCell>
                </TableRow>
              )} */}
						</TableBody>
						<TableFooter>
							<TableRow sx={{ background: '#d9ffd6' }}>
								<TableCell colSpan={2}>Pago:</TableCell>
								<TableCell>{currencyFormatter(order.paid)}</TableCell>
							</TableRow>
							<TableRow sx={{ background: '#e3e3e3' }}>
								<TableCell colSpan={3}>
									<Alert severity="warning">
										<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
											A receber {currencyFormatter(order.total - order.paid)}
										</Typography>
									</Alert>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</div>
		</>
	)
}

export default ServicesOrders
