import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material'

import React from 'react'
import settings from 'settings.js';
import useFormatter from '../../hooks/useFormatter'

export default function PaymentsTable({ checkoutCounter }) {
  const formatter = useFormatter();
  const payments = checkoutCounter.payments.filter(p => p.paymentMethod !== settings.returnInstallment && p.paymentMethod !== settings.giftCardInstallment);
  const totalFloat = checkoutCounter.tillFloat;
  const totalDrop = checkoutCounter.tillDrop;
  console.log('payments', checkoutCounter);
  
  const checkoutCounterTotal = payments.reduce((acc, payment) =>  acc + payment.total, 0) - checkoutCounter.initialCash - totalFloat + totalDrop;


  return (
    <Box sx={{mt: 3}}>
      <Typography variant="body1">Pagamentos:</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Forma de pagamento</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment, index) => {
            const total = payment.paymentMethod === settings.cashPaymentMethod ? payment.total - checkoutCounter.initialCash - totalFloat + totalDrop: payment.total;
            return (            
              <TableRow key={index}>
                <TableCell>{payment.paymentMethod}</TableCell>
                <TableCell>{formatter(total, 'currency')}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ backgroundColor:  '#e3e3e3' }}>
            <TableCell>Total</TableCell>
            <TableCell>{formatter(checkoutCounterTotal, 'currency')}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}
