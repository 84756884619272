import React, { useEffect, useState } from 'react'
import { useAppHooks } from 'hooks/useAppHooks' // Certifique-se de que os hooks estão exportados corretamente
import { Button, TextField, Container, Typography } from '@mui/material'
import StoreSelector from 'components/Store/StoreSelector' // Certifique-se de que o componente StoreSelector está exportado corretamente
import SalesTable from 'components/Sales/SalesTable '
import storeSalesService from 'services/store-sales.service'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router-dom'

const SaleSearchPage = () => {
  const { withLoading, message } = useAppHooks()
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    storeId: '',
    startDate: '',
    endDate: '',
    cpf: '',
    couponNumber: '',
  })
  const [sales, setSales] = useState([])

  const handleFilterChange = (event) => {
    const { name, value } = event.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const setSelectedStore = (storeId) => {
    setFilters({
      ...filters,
      storeId,
    })
  }

  const handleSearch = () => {
    if (!filters.storeId) {
      message.error('Selecione uma loja para realizar a busca.')
      return
    }

    withLoading(async () => {
      storeSalesService.search(filters.storeId, filters.startDate, filters.endDate, filters.cpf, filters.couponNumber).then((response) => {
        setSales(response)
      })
    }).catch((error) => {
      message.error('Ocorreu um erro ao buscar as vendas.')
    })
  }

  const saleClickHandler = (sale) => {
    console.log('opening sale details', sale)
    if (sale.storeEventId && sale.storeEventId.length > 0) {
      navigate(`/store-event/${sale.storeEventId}/sales/${sale.id}`)
    } else {
      navigate(`/sales/${sale.id}`)
    }
  }

  useEffect(() => {
    //initialize start and end date with latest 7 days
    const today = new Date();
    const endDate = today.toISOString().split('T')[0];
    today.setDate(today.getDate() - 7);
    const startDate = today.toISOString().split('T')[0];
    setFilters({
        ...filters,
        startDate,
        endDate,
    });
    }, []);

  return (
    <Container maxWidth="false">
      <Typography variant="h4">Busca de vendas</Typography>
      <br></br>
      <Grid2 container spacing={3}>
        <Grid2 xs={12} md={6}>
          <StoreSelector name="storeId" onSelectStore={setSelectedStore} />
        </Grid2>
        <Grid2 xs={12} md={3}>
          <TextField fullWidth label="Data inicial" name="startDate" type="date" InputLabelProps={{ shrink: true }} onChange={handleFilterChange} value={filters.startDate} />
        </Grid2>
        <Grid2 xs={12} md={3}>
          <TextField fullWidth label="Data final" name="endDate" type="date" InputLabelProps={{ shrink: true }} onChange={handleFilterChange} value={filters.endDate} />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <TextField fullWidth label="CPF do cliente" name="cpf" onChange={handleFilterChange} value={filters.cpf} />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <TextField fullWidth label="Número do cupom" name="couponNumber" onChange={handleFilterChange} value={filters.couponNumber} />
        </Grid2>
        <Grid2 xs={12}>
          <Button variant="contained" onClick={handleSearch}>
            Pesquisar
          </Button>
        </Grid2>
      </Grid2>
      <br />
      <SalesTable sales={sales} onClick={saleClickHandler} />
    </Container>
  )
}

export default SaleSearchPage
