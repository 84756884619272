import { Button, Container, Drawer, FormControl, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'

import CheckoutCounterTable from '../../../components/CheckoutCounter/CheckoutCounterTable'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import checkoutCounterService from '../../../services/checkout-counter.service'

const SearchPage = () => {
  const today = new Date();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const { storeId } = useParams();
  const [checkoutCounters, setCheckoutCounters] = useState([]);
  const startDate = urlParams.get('startDate') || lastWeek.toISOString().split('T')[0];
  const endDate = urlParams.get('endDate') || today.toISOString().split('T')[0];  
  const startDateRef = useRef(startDate);
  const endDateRef = useRef(endDate);
  const navigate = useNavigate();

  const search = (startDate, endDate) => {
    // API call to fetch checkout counters based on selected store and date range
    checkoutCounterService.search(storeId, startDate, endDate).then((response) => {
      setCheckoutCounters(response)
    })
  };

  const handleSearch = () => {
    const params = createSearchParams({
      startDate: startDateRef.current,
      endDate: endDateRef.current,
    });

    navigate(`/store/${storeId}/checkout-counter/search?${params.toString()}`);
  };

  const checkoutClickHandler = (checkoutCounter) => {
    // redirect to checkout counter page
    navigate(`/store/${storeId}/checkout-counter/${checkoutCounter.id}`);
  };

  useEffect(() => {    
    search(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={0}>
        <Grid xs>
          <Stack spacing={3} direction="row">
            <FormControl fullWidth>
              <TextField
                label="Data Inicial"
                type="date"
                name="startDate"
                defaultValue={startDate}
                onChange={(e) => startDateRef.current = e.target.value}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Data Final"
                type="date"
                name="endDate"
                defaultValue={endDate}
                onChange={(e) => endDateRef.current = e.target.value}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid xs="auto">
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </Grid>
      <br />
      <CheckoutCounterTable checkoutCounters={checkoutCounters} onClick={checkoutClickHandler} />
    </Container>
  )
}

export default SearchPage
