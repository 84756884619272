import { Checkbox, Chip, Divider, FormControlLabel, FormGroup, InputLabel, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from '@emotion/styled'

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import classes from "./ServiceSelection.module.css";
import eventServiceService from "services/event-service.service";
import { useAppHooks } from "hooks/useAppHooks";
import { useDebounce } from "hooks/useDebounce";
import { useHasRole } from "hooks/useHasRole";
import { useSelector } from "react-redux";

export const ServiceSelection = (props) => {

    const { storeId, required, services } = props;
    const store = useSelector((state) => state.stores.list.find((s) => s.id.toString().toLowerCase() === storeId?.toString()?.toLowerCase()));
    const [ requiredServices, setRequiredServices ] = useState([]);
    const [ optionalServices, setOptionalServices ] = useState([]);
    const [ selectedServices, setSelectedServices ] = useState([]);
    const [ holidays, setHolidays ] = useState({ years: [], holidays: []});
    const [ isHoliday, setIsHoliday ] = useState(false);
    const { withLoading, message } = useAppHooks();
    const date = props.date && new Date(props.date);
    //const isHoliday = props.date && holidays.find((h) => h.date === date.toISOString().substr(0, 10));

    const loadHolidays = async (year) => {
        const items = await eventServiceService.holidays(year);
        const clone = {...holidays};

        clone.years = [...clone.years, year];
        clone.holidays = [ ...clone.holidays, ...items];

        setHolidays(clone);
    }

    useEffect(() => {
        const year = new Date().getFullYear();
        loadHolidays(year).catch((error) => {
            message.error("Não foi possível carregar a lista de feriados", error);
        });
    }, []);

    useEffect(() => {
        if(!storeId) return;
        
        setSelectedServices([]);

        withLoading(async ()=> {
            try {
                const requireds = await eventServiceService.required(storeId);
                const optionals = await eventServiceService.list(storeId);

                if(services && services.length > 0) {
                    services.forEach((s) => {
                        const found = requireds.find((r) => r.priceRange.find(p=> p.id === s.service.id)) || optionals.find((o) => o.priceRange.find(p=> p.id === s.service.id));
                        
                        if(found) {
                            
                            
                            if(found.required){
                                found.quantity = isHoliday ? 7 : date.getDay() + 1;
                            }
                            else{
                                found.quantity = +s.quantity;
                            }
                            found.checked = s.checked;
                            found.value = +s.value;

                            const priceItem = found.priceRange.find((r) => r.min <= found.quantity && r.max >= found.quantity);

                            if(priceItem && +priceItem.price != found.value) {
                                priceItem.price = found.value;
                            }
                            
                        }
        
                    });
                }

                if(requireds && requireds.length > 0 && requireds.filter(x=> x.checked === true).length === 0) {
                    requireds[0].checked = true;
                }

    
                setRequiredServices(requireds);
                setOptionalServices(optionals);

                //const holidays = await eventServiceService.holidays(store.city.stateId);
            } catch (error) {
                message.error("Não foi possível carregar a lista de serviços", error);
            }
        });

    }, [storeId, services]);

    useEffect(() => {

        if(!props.date) return;

        if(!store) return;

        setSelectedServices([]);

        const checkHoliday = async () => {
            const date = new Date(props.date);

            if(holidays.years.indexOf(date.getFullYear()) < 0) {
                await loadHolidays(date.getFullYear());
            }

            if(holidays.years.indexOf(date.getFullYear()) >= 0) {
                const holiday = holidays.holidays.find((h) => h.date === date.toISOString().substr(0, 10));
                setIsHoliday(holiday);
            }
        };

        checkHoliday();

    }, [props.date]);


    const changeHandler = (service) => {
        setSelectedServices((s) => {
            const services = [...s];
            const index = services.findIndex((s) => s.service.name === service.service.name);
    
            if(index >= 0) {
                services[index] = service;
            }
            else{
                services.push(service);
            }
            
    
            props.onChange(services.filter((s) => s.checked));

            return services;
        });
    }

    if(requiredServices.length === 0 || optionalServices.length === 0) 
    {
        return;
    }


    const DividerCustom = styled(Divider)(({ theme }) => ({

        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    
      }));
    // console.table(requiredServices);    
    // console.table(optionalServices);    

    return (
        <div>
            {requiredServices.map((service) => (
                <Service key={service.id} service={service} date={date} required={true} isHoliday={isHoliday} onChange={changeHandler}></Service>
            ))}
            <br />
            <br />
            <DividerCustom textAlign='left'>
                <Chip label="Adicionais" color='secondary'  />
            </DividerCustom>
            {optionalServices.map((service) => (
                <Service key={service.id} service={service} required={false} onChange={changeHandler}></Service>
            ))}
        </div>
    );
};

const Service = (props) => {
    const { service, required, date, onChange, isHoliday } = props;
    const [quantity, setQuantity] = useState(service.multiply ? service.min :  1);
    const [price, setPrice] = useState(null);
    const [value, setValue] = useState(service.value || 0);
    const [checked, setChecked] = useState(service.checked || false);
    const { formatter } = useAppHooks();
    const debounce = useDebounce();
    const isManager = useHasRole(["Administrator", "Manager"]); // Initialize your RoleService


    useEffect(() => {
        if(!service) return;
        if(!service.quantity) return;
        if(!service.priceRange) return;
        if(!service.value) return;
        
        const q = +service.quantity;
        const priceItem = service.priceRange.find((r) => r.min <= q && r.max >= q);

        if(priceItem && +priceItem.price != +service.value) {
            priceItem.price = +service.value;
        }

    }, []);

    useEffect(() => {
        if(required && date) {

            if(isHoliday) {
                setQuantity(7);
            }
            else{
                setQuantity(date.getDay() + 1);
            }
        }
    }, [required, date]);

    useEffect(() => {
        if(service?.quantity){
            setQuantity(service.quantity);
        }
        if(service?.checked){
            setChecked(service.checked);
        }
    }, []);

    
    useEffect(() => {

        if(!service) return;
        if(!service.priceRange) return;

        const q = +quantity;
        const priceItem = service.priceRange.find((r) => r.min <= q && r.max >= q);

        if(priceItem != price)
        {
            setPrice(priceItem);
            setValue(priceItem?.price);
        }     

    }, [service, quantity]);

    useEffect(() => {
        if(!service || !price) return;
        
        debounce(() => {
            onChange({
                checked: checked,
                service: { ...service, id: price.id },
                quantity: +quantity,
                value: value,
                total: total
            });
        }, 300);
    }, [checked, quantity, price, value]);

    const quantityEnabled = service.priceRange.length > 1 && !required;
    //const value = price ? price.price : 0;
    const total = value * (service.multiply ? quantity : 1);

    //get min and max values from priceRange
    const max = Math.max.apply(Math, service.priceRange.map((r) => r.max));    
    const min = Math.min.apply(Math, service.priceRange.map((r) => r.min));

    const valueChangedHandler = (event) => {
        const temp = parseCurrency(event.target.value)

        setValue(temp);
    }
    
    const parseCurrency = (value) => {
        if (typeof value === 'number') {
          return +value.toFixed(2)
        }
    
        let temp = value.replace(/\D/g, '') // Remove non-digit characters
        temp = parseFloat(temp / 100).toFixed(2)
        return +temp
    }

    const checkedChangeHandler = (event) => {
        setChecked(event.target.checked);
    };

    const quantityChangeHandler = (event) => {        
        setQuantity(event.target.value);
    };

    return (
        <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={12}>
                <FormGroup>
                    <FormControlLabel 
                        checked={checked}
                        control={<Checkbox onChange={checkedChangeHandler} />} label={service.name} />
                </FormGroup>
            </Grid2>
            {checked && (
                <>
                <Grid2 xs={12} sm={6} sx={{alignItems: 'flex-start', display: 'flex'}}>
                    {quantityEnabled && (
                        <>
                        <InputLabel shrink className={classes.label}>Quantidade</InputLabel>
                        <input
                        // <TextField fullWidth 
                            className={classes.input}
                            id="outlined-number"
                            label="Quantidade"
                            type="number"
                            variant="outlined"
                            min={min}
                            max={max}
                            value={quantity}
                            onChange={quantityChangeHandler}
                        />
                        {(quantity > max || quantity < min) && (
                            <Typography variant="caption" color="error">
                                Quantidade inválida (min: {min} max: {max})
                            </Typography>
                        )}
                        </>
                    )}
                    {required && isHoliday && (
                        <Typography variant="caption" color="error">
                            Feriado
                        </Typography>
                    )}
                </Grid2>
                <Grid2 xs={12} sm={4} sx={{alignItems: 'flex-start', display: 'flex'}}>

                    <InputLabel shrink className={classes.label}>Valor unitário</InputLabel>
                    {isManager && (
                        <input
                        // <TextField fullWidth 
                            className={classes.input}
                            label="Valor" 
                            variant="outlined" 
                            value={value === '' ? '' : formatter(value, 'currency')} 
                            onChange={valueChangedHandler} />
                    )}

                    {!isManager && (
                        <span>{formatter(value, 'currency')}</span>
                    )}
                </Grid2>
                <Grid2 xs={12} sm={2} sx={{alignItems: 'center', display: 'flex', justifyContent: 'flex-end'}}>
                    <InputLabel shrink className={classes.label}>Total</InputLabel>
                    <Typography variant="h6" gutterBottom={false}>
                        {formatter(total, 'currency')}
                    </Typography>
                </Grid2>
                <Grid2 xs={12} sm={12}>
                    <Divider />
                </Grid2>
                </>
            )}
        </Grid2>
    );
};

export default ServiceSelection;