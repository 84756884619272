import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

import MaskedInput from 'components/MaskedInput';
import moment from 'moment';

const CustomTimeContainer = styled('div')({
  position: 'relative',
  marginTop: '1rem',
});

const CustomTime = styled('span')({
  position: 'absolute',
  top: '20%',
  left: '20%',
});


const regex = /^\d{2}:\d{2}$/;

const EventPeriods = (props) => {
  const { css, title, date, selected, onChange } = props;
  const [options, setOptions] = useState([{ title: 'Especificar horário' }]);
  const [customTime, setCustomTime] = useState('');
  const [customTimeFormatted, setCustomTimeFormatted] = useState('');
  const [formValue, setFormValue] = useState('');

  useEffect(() => {
    if (date) {

      const dayOfWeek = moment(date).toDate().getDay();

      console.log('selected - loading date', dayOfWeek, date);
      let newOptions = [];

      if (dayOfWeek === 6) {
        newOptions = [
          { title: '09:00 às 13:00', startAt: '09:00', endAt: '13:00' },
          { title: '14:30 às 18:30', startAt: '14:30', endAt: '18:30' },
          { title: '19:30 às 23:30', startAt: '19:30', endAt: '23:30' },
        ];
      } else if (dayOfWeek === 0) {
        newOptions = [
          { title: '16:00 às 20:00', startAt: '16:00', endAt: '20:00' },
        ];
      } else {
        newOptions = [
          { title: '19:00 às 23:00', startAt: '19:00', endAt: '23:00' },
        ];
      }

      newOptions.push({ title: 'Especificar horário' });

      console.log('setting selected');
      if (formValue) {

        const newOption = newOptions.find((option) => option.title === formValue.title);
        
        if (newOption) {
            setFormValue(newOption);
        }
      }

      setOptions(newOptions);
    }
  }, [date]);

  useEffect(() => {
    if (selected) {
      const option = options.find((option) => option.title === selected.title);
      
      if(option){
        setFormValue(option);
      }
      else{
        const option = options.find((option) => option.title === 'Especificar horário');

        setFormValue(option);
        setCustomTime(selected.startAt);
      }
    }
  }, [selected, options]);

  useEffect(() => {

    console.log('customTime effect', customTime);
    if(regex.test(customTime))
    {
      const [start, end] = customTime.split(':');
      console.log('customTime effect', ` às ${+start + 4}:${end}`);
      setCustomTimeFormatted(` às ${+start + 4}:${end}`);

    }
    else{
      setCustomTimeFormatted('');
    }

  }, [customTime]);


  const customTimeHandler = (e) => {
    console.log('customTimeHandler', e.target.value);
    setCustomTime(e.target.value);

    //match regular expression to e.target.value, must be dd:dd
    //if match, raise onChange with startAt and endAt
    const value = e.target.value;

    if (regex.test(value)) {
      const [start, end] = value.split(':');
      onChange({ startAt: value, endAt: `${+start + 4}:${end}` });
    }
    
  };

  const optionChangeHandler = (e) => {
    const option = options.find((option) => option.title === e.target.value.title);
    setFormValue(e.target.value);
    onChange(option);
  };

  return (
    <div className={css}>
      <FormControl fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
            {...props}
            label={title}
            value={formValue}
            onChange={optionChangeHandler}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {date && formValue?.title === 'Especificar horário' && (
        <CustomTimeContainer>
            <MaskedInput 
                fullWidth
                mask="99:99" 
                label="Informe o horário" 
                value={customTime} 
                onChange={customTimeHandler} />
          
          <CustomTime>{customTimeFormatted}</CustomTime>
        </CustomTimeContainer>
      )}
    </div>
  );
};

export default EventPeriods;
