import axios from "axios";
import settings from "../settings";

const apiPath = `${settings.api.url}/checkout-counter`;

class checkoutCounterService {
  constructor() {}


  search(storeId, startDate, endDate) {
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
  
    return axios
      .get(`${apiPath}/${storeId}/search`, {
        params: params,
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => response.data)
  }

  get(storeId, id) {
    return axios
      .get(`${apiPath}/${storeId}/${id}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => response.data)
  }

  getCurrent(storeId) {
    return axios
      .get(`${apiPath}/${storeId}/current`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => response.data)
  }

  open(storeId, id) {
    return axios
      .post(`${apiPath}/${storeId}/open`)
      .then((response) => response.data)
  }


  close(storeId, id) {
    return axios
      .post(`${apiPath}/${storeId}/${id}/close`)
      .then((response) => response.data)
  }

  tillFloat(storeId, id, amount) {
    return axios
      .put(`${apiPath}/${storeId}/${id}/till-float`, {amount: amount})
      .then((response) => response.data)
  }

  tillDrop(storeId, id, amount) {
    return axios
      .put(`${apiPath}/${storeId}/${id}/till-drop`, {amount: amount})
      .then((response) => response.data)
  }


}

export default new checkoutCounterService();
