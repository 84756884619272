import axios from "axios";
import settings from "../settings";
import { BaseService } from "./base.service";

const apiPath = `${settings.api.url}/customer`;


class customerService extends BaseService {
  constructor() {
    super();
  }

  get(id) {
    return axios
      .get(`${apiPath}/${id}`, { ...this.noCache })
      .then((response) => response.data.data);
  }

  find(cpf, email, phone) {

    const params = {
        cpf,
        email,
        phone
    };

    return axios.get(`${apiPath}/find`, { ...this.noCache, params })
                .then((response) => response.data.data);
  }
}

export default new customerService();
