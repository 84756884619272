export class BaseService {
  constructor() {

  }
  
  noCache = {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0"
    }
  }

  catchCustomError(response) {
    let result = response.data;

    if (result) {
      if (result.errors) {
        if (Object.keys(result.errors).length > 0) {
          throw Object.values(result.errors)[0];
        }
      }

      if (result.success === false) {
        throw result.errors[0];
      }
    }

    if (response.ok === false) {
      if (response.status === 403) {
        throw "Você não possui acesso para realizar essa operação, tente novamente ou faça logoff e entre novamente.";
      }
      if (response.message) {
        throw response.message;
      }
    }

    throw response;
  }
}
