import axios from "axios";
import settings from "../settings";
import { BaseService } from "./base.service";

const apiPath = `${settings.api.url}/store`;

class StoreService extends BaseService {
  constructor() {
    super();
  }

  availables() {
    return axios
      .get(`${apiPath}/availables`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }

  paymentMethods(storeId) {
    return axios
      .get(`${apiPath}/${storeId}/payment-methods`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }
}

export default new StoreService();
