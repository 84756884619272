import useFormatter from './useFormatter';
import { useLoading, useLoadingWith } from './useLoading';
import { useMessage } from './useMessage';

export const useAppHooks = () => {
  const withLoading = useLoadingWith();
  const message = useMessage();
  const formatter = useFormatter();

  return { withLoading, message, formatter };
};