import { Box, Link, Stack, Typography } from "@mui/material";

import React from "react";
import { styled } from "@mui/material/styles";

const Container = styled(Stack)({
  background: '#e6e6e6',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 1,
  display: 'flex',
});

const Title = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.tertiary.main,
}));

const StyledBox = styled(Box)({
  padding: 2,
});

function StoreSummary({ store }) {
  const cleanPhone = (phone) => {
    return phone
      ?.replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/ /g, "")
      .replace(/-/g, "");
  };

  return (
    <Container>
      <Title>
        <Typography variant="h6" gutterBottom>
          {store.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {store.cnpj}
        </Typography>
      </Title>

      <StyledBox>
        <Typography>
          <strong>Endereço:</strong>
          <br />
          <span className="store">{store.address}</span><br />
          <span className="store">{store.city.name} / {store.city.stateId}</span><br />
          <span className="store">{store.zipCode}</span>
        </Typography>
      </StyledBox>
    </Container>
  );
}

export default StoreSummary;
