// LoadingModal.js

import { Box, CircularProgress, Typography } from '@mui/material';

import React from 'react';
import { useSelector } from 'react-redux';

const LoadingModal = () => {
  const loadingMessage = useSelector((state) => state.global.loadingMessage);

  return (
    <Box sx={{margin: '0 auto', textAlign: 'center'}}>
      <CircularProgress />
      <Typography variant="body1" sx={{color: 'white'}}>{loadingMessage || 'Carregando'}</Typography>
    </Box>
  );
};

export default LoadingModal;
