export const EventStatus = {
    Reserved: 1,
    Signed: 2,
    //Pending: 4,
    //PartialPaid: 8,
    Confirmed: 16,
    Done: 32,
    Cancelled: 64,
    Expired: 128,
    Postponed: 256
};


export const EventStatusText = {
    [EventStatus.Reserved]: "Reservado",
    [EventStatus.Signed]: "Assinado",
    // [EventStatus.Pending]: "Pendente",
    // [EventStatus.PartialPaid]: "Parcialmente Pago",
    [EventStatus.Confirmed]: "Confirmado",
    [EventStatus.Done]: "Finalizado",
    [EventStatus.Cancelled]: "Cancelado",
    [EventStatus.Expired]: "Expirado",
    [EventStatus.Postponed]: "Adiado"
};

export const EventStatusColor = {
    [EventStatus.Reserved]: "#d50000",
    [EventStatus.Signed]: "#e2c31e",
    // [EventStatus.Pending]: "#e2c31e",
    // [EventStatus.PartialPaid]: "#d50000",
    [EventStatus.Confirmed]: "#7cb342",
    [EventStatus.Done]: "#01579B",
    [EventStatus.Cancelled]: "#616161",
    [EventStatus.Expired]: "#616161",
    [EventStatus.Postponed]: "#616161"
};

export const EventStatusColorText = {
    [EventStatus.Reserved]: "#fff",
    [EventStatus.Signed]: "#fff",
    // [EventStatus.Pending]: "#fff",
    // [EventStatus.PartialPaid]: "#fff",
    [EventStatus.Confirmed]: "#fff",
    [EventStatus.Done]: "#fff",
    [EventStatus.Cancelled]: "#fff",
    [EventStatus.Expired]: "#fff",
    [EventStatus.Postponed]: "#fff"
};




/*

Pre-reserva
Pre-Reserva Assinado |  Pre-Reserva Pago | Pre-Reserva ParcialmentePago

ParcialmentePago | Pago

AdicionalPendente
AdicionalAssinado |  AdicionalParcialmentePago | AdicionalPago

Realizado

[Pendente de troca estado virtual]

Finalizado

Cancelado
Extorno Pendente
Extornado

Expirado
Adiado
*/