export const Origin = {
    StoreEvent: 0,
    Store: 1,
    Online: 2
}

export const OriginText = {
    [Origin.StoreEvent]: "Evento",
    [Origin.Store]: "Loja",
    [Origin.Online]: "Online"
}
