import React from "react";
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	Alert,
	IconButton,
} from "@mui/material";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";
import useDateFormatter from "../../hooks/useDateFormatter";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";

export const ProductSelection = ({ products, onRemoveProduct }) => {
	const currencyFormatter = useCurrencyFormatter();
	const dateFormatter = useDateFormatter();

	const TableSubRow = styled(TableRow)(({ theme }) => ({
		"& > *": {
			borderBottom: "unset",
            backdropFilter: "blur(10px)",
            backgroundColor: theme.palette.grey['300'],
		},
		"& > *:first-of-type": {
			paddingLeft: theme.spacing(6),
		},
		"& > *:last-of-type": {
			paddingRight: theme.spacing(6),
		},
	}));

	return (
		<div>
			{products.length === 0 ? (
				<Alert severity="warning">
					<strong>Atenção!</strong> Nenhum produto selecionado.
				</Alert>
			) : (
				<div>
					<Alert severity="info" sx={{ mb: 1 }}>
						<strong>Atenção!</strong> {products.length} produto(s)
						selecionado(s), totalizando{" "}
						<strong>
							{currencyFormatter(
								products.reduce((total, product) => total + product.price, 0)
							)}
						</strong>
						.
					</Alert>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Produto</TableCell>
									<TableCell>Preço</TableCell>
									<TableCell width={5}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{products.map((item) => (
									<React.Fragment key={item.id}>
										<TableRow>
											<TableCell>{item.product.name}</TableCell>
											<TableCell>{currencyFormatter(item.price)}</TableCell>
											<TableCell>
												<IconButton
													aria-label="delete"
													onClick={() => onRemoveProduct(item)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableSubRow>
											<TableCell colSpan={3}>
												<small>
													Data compra: {dateFormatter(item.createdAt)}
												</small>{" "}
												- <small>Comprador: {item.customer?.name}</small>
											</TableCell>
										</TableSubRow>
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
		</div>
	);
};

export default ProductSelection;
