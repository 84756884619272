import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { EventListItem } from "./EventListItem";
import { EventStatus } from "models/EventStatus";
import TitleBox from "components/Layout/TitleBox";
import storeEventService from "services/store-event.service";

const PostponedEventList = ({ onClick }) => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        
        const now = new Date();
        now.setHours(0, 0, 0, 0);

		let filter = {
			startDate: now,
            status: EventStatus.Postponed,
			page: 1,
			pageSize: 20,
		};

		storeEventService.search(filter).then((x) => {

            const expired = new Date();
            expired.setDate(expired.getDate() - 2);

            console.log(expired, x.items);
            const events = x.items.filter(x => new Date(x.createdOn) < expired);
			setEvents(events);
		});
    }, []);

    return (
        <Box>
            <TitleBox title="Eventos adiados" />
        {events && events.map(event => (
            <EventListItem key={event.id} storeEvent={event} onClick={onClick} />
        ))}
        {(!events || events.length === 0) && (
            <Typography variant="body2" component="div" sx={{ color: 'text.secondary', p: 2 }}>
                Nenhum registro foi encontrado
            </Typography>
        )}
        </Box>
    );
};

export default PostponedEventList;

