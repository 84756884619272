import axios from 'axios';
import settings from '../settings';
import { BaseService } from './base.service';

const apiPath = `${settings.api.url}/store-sales`;

const noCache = {
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};

const saleParser = (sale) => {
    return {
        ...sale,
        createdAt: new Date(sale.createdAt),
        associatedAt: new Date(sale.associatedAt),
        confirmationAt: new Date(sale.confirmationAt),
        issueDate: new Date(sale.issueDate)
    }
}

class storeSalesService extends BaseService {
  constructor() {
    super();
  }

  all(storeId, storeEventId) {
    return axios
      .get(`${apiPath}/${storeId}/${storeEventId}`, noCache)
      .then((response) => response.data)
      //.then((data) => data.map((item) => saleParser(item)))
      .catch((error) => this.catchCustomError(error));
  }


  list(storeId, saleIds) {
    return axios
      .post(`${apiPath}/${storeId}/list`, saleIds, noCache)
      .then((response) => response.data)
      //.then((data) => data.map((item) => saleParser(item)))
      .catch((error) => this.catchCustomError(error));
  }


  getStoreEventsSales(storeId, storeEventIds) {
    return axios
      .post(`${apiPath}/${storeId}/store-events-sales`, storeEventIds, noCache)
      .then((response) => response.data)
      .then((data) => data.map((item) => saleParser(item)))
      .catch((error) => this.catchCustomError(error));
  }

  create(storeId, sale){
    return axios
      .post(`${apiPath}/${storeId}`, sale)
      .then((response) => response.data)
      .catch(error => Promise.reject(error));
  }

  get(saleId){    
    return axios
      .get(`${apiPath}/${saleId}`, noCache)
      .then((response) => response.data)
      .catch((error) => this.catchCustomError(error));
  }
  
  receipt(saleId){
    return axios
      .get(`${apiPath}/${saleId}/receipt`, {
        responseType: 'blob'
      });
  }

  associateStoreEvent(sale, storeEventId){
    return axios
      .put(`${apiPath}/${sale.storeId}/associate-store-event`, {
        id: sale.id, 
        storeEventId: storeEventId
      })
      .then((response) => response.data)
      .catch((error) => this.catchCustomError(error));
  }

  search(storeId, startDate, endDate, CPF, name, number, seller, status){    
    //send all those fields as a query string parameter
    const params = {
      startDate,
      endDate,
      CPF,
      name,
      number,
      seller,
      status
    };

    return axios
      .get(`${apiPath}/${storeId}/search`, {params}, noCache)
      .then((response) => response.data)
      .catch((error) => this.catchCustomError(error));
  }

}

export default new storeSalesService();