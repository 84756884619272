import axios from 'axios'
import settings from '../settings'

const apiPath = `${settings.api.url}/employee`

class employeeService {
  constructor() {}

  list() {
    return axios.get(`${apiPath}/list`).then((response) => response.data.data)
  }

  search(name, page, pageSize) {
    return axios.get(`${apiPath}/search/${pageSize}/${page}/${name}`).then((response) => response.data.data)
  }

  get(id) {
    return axios
      .get(`${apiPath}/${id}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      })
      .then((response) => response.data.data)
  }

  create(employee) {
    return axios.post(`${apiPath}/create`, employee).then((response) => response.data.data)
  }

  update(employee) {
    return axios.post(`${apiPath}/${employee.id}`, employee).then((response) => response.data.data)
  }

  disable(id){
    return axios.delete(`${apiPath}/${id}`).then((response) => response.data.data);
  }

  

}

export default new employeeService()
