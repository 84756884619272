import { AppBar, Box, Container, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Switch, Toolbar, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SellIcon from '@mui/icons-material/Sell';
import { useSelector } from 'react-redux';

const drawerMinWidth = 73;
const drawerWidth = 200;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1',
  //gap: '10px',
}));

const Logo = styled('img')({
  height: '50px',
  flexGrow: 1,
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: !open ? drawerMinWidth : drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : theme.spacing(9),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  [theme.breakpoints.up('sm')]: {
    width: !open ? drawerMinWidth : drawerWidth,
    flexShrink: 0,
  },
  bottom: 0,
}));

const ToolbarSpacing = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0) + ' !important',
}));

const CustomDivider = styled(Divider)({
  marginTop: '10px',
  marginBottom: '10px',
});

const NavLinkStyled = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '5px',
  '& svg': {
    minWidth: '46px',
  },
}));

const BoxStyled = styled(Box)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '5px',
  '& svg': {
    minWidth: '46px',
  },
}));

const WrappedText  = styled('span')(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden', // Geralmente, textOverflow de 'ellipsis' é usado com overflow 'hidden'
  whiteSpace: 'nowrap',
}));

const VerticalLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const auth = useSelector((state) => state.auth);
  const name = auth?.user?.given_name || '';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Root>
      <CustomDrawer variant="permanent" open={open}>
        <ToolbarSpacing>
          {!open && (
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
              <MenuIcon />
            </IconButton>
          )}
          {open && (
            <>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
              <Logo src="/assets/img/logo.svg" alt="Logo" />
            </>
          )}
        </ToolbarSpacing>
        <List sx={{flexGrow: 1}}>
          <NavLinkStyled to="/">
            <DashboardIcon />
            {open && <ListItemText primary="Dashboard" />}
          </NavLinkStyled>
          <NavLinkStyled to="/store-event/register">
            <EventIcon />
            {open && <ListItemText primary="Novo evento" />}
          </NavLinkStyled>
          {/* <NavLink>
            <EventAvailableIcon />
            {open && <ListItemText primary="Buscar evento" />}
          </NavLink> */}
          
          <CustomDivider />

          <NavLinkStyled to='/store/A0CB9157-82F0-41AE-82F2-1AC3DFFC87D7'>
            <ReceiptIcon />
            {open && <ListItemText primary="Loja" />}
          </NavLinkStyled>
          <NavLinkStyled to="/seller/sales-report">
            <AssessmentIcon />
            {open && <ListItemText primary="Relatório de vendas" />}
          </NavLinkStyled>
          <NavLinkStyled to="/store/A0CB9157-82F0-41AE-82F2-1AC3DFFC87D7/sales/create">
            <SellIcon />
            {open && <ListItemText primary="Registrar venda" />}
          </NavLinkStyled>

          
        </List>
        <CustomDivider />
        <List sx={{flexGrow: 0}}>
            {open && 
            <BoxStyled>
              <WrappedText>{name}</WrappedText>
            </BoxStyled>
              }
          <NavLinkStyled to='/logout'>
            <ExitToAppIcon />
            {open && <ListItemText primary="Sair" />}
          </NavLinkStyled>
        </List>
      </CustomDrawer>
      <ContentContainer maxWidth={false}>
        {children}
      </ContentContainer>
    </Root>
  );
};

export default VerticalLayout;
