import axios from 'axios'
import settings from '../settings'

const apiPath = `${settings.api.url}/event-service`

class eventServiceService {
  constructor() {}

  list(storeId) {
    return axios.get(`${apiPath}/${storeId}/list`).then((response) => response.data.data)
  }

  required(storeId) {
    return axios.get(`${apiPath}/${storeId}/required`).then((response) => response.data.data)
  }


  holidays(year) {
    //return axios.get(`${settings.api.url}/holidays/${uf}`).then((response) => response.data)
    return axios.get(`https://brasilapi.com.br/api/feriados/v1/${year}`).then((response) => response.data)
  }
  
  isHoliday(uf, date) {
    return axios.get(`${settings.api.url}/holidays/${uf}/is-holiday?date=${date}`).then((response) => response.data)
  }

}

export default new eventServiceService()
