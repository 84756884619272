import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material'
import { EventStatus } from 'models/EventStatus'
import { useEffect, useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useAppHooks } from 'hooks/useAppHooks'
import useFormatter from 'hooks/useFormatter'
import { useHasRole } from 'hooks/useHasRole'
import { ProductType } from 'models/ProductType'
import { SaleStatus } from 'models/SaleStatus'
import storeEventService from 'services/store-event.service'
import storeSalesService from 'services/store-sales.service'

const SalesReport  = ({storeId, sellerId, startDate, endDate, displayStoreTotal, displayStoreTable, saleItemClick}) => {
	const formatter = useFormatter();
	const isManager = useHasRole(['Administrator', 'Manager']) // Initialize your RoleService
	const { withLoading, message } = useAppHooks()

	const [displayTable, setDisplayTable] = useState(false);
	const [storeSales, setStoreSales] = useState([]);
	const [sellerSales, setSellerStoreSales] = useState([]);
	const [storeEventsSales, setStoreEventsSales] = useState([]);
	const [storeEvents, setStoreEvents] = useState([]);
	const [actAsSpeaker, setActAsSpeaker] = useState(0);

	useEffect(() => {

        if(storeId && sellerId)
        {
            withLoading(async () => {

                const s = new Date(Date.UTC(startDate.getFullYear(),  startDate.getMonth(), 1, 3, 0, 0, 0)).toISOString();
                const e = new Date(Date.UTC(endDate.getFullYear(),  endDate.getMonth() + 1, 1, 2, 59, 59, 999)).toISOString();

                const data = await storeSalesService.search(storeId, s, e, null, null, null, sellerId, SaleStatus.Approved)

                //const filtered = data.filter((sale) => !sale.storeEventId || sale.storeEventId.length === 0)
                setSellerStoreSales(data);

            }).catch((error) => {
                message.error('Não foi possível carregar as vendas do vendedor', error)
            });


            withLoading(async () => {
                //remove Z from the end of the date
                const s = new Date(Date.UTC(startDate.getFullYear(),  startDate.getMonth(), 1, 0, 0, 0, 0)).toISOString();
                const e = new Date(Date.UTC(endDate.getFullYear(),  endDate.getMonth() + 1, 0, 23, 59, 59, 999)).toISOString();

                const data = await storeEventService.search({storeId, startDate: s, endDate: e, page: 1, pageSize: 1000});                      

                const validEvents = data.items.filter((event) => event.status !== EventStatus.Cancelled && event.status !== EventStatus.Expired && event.status !== EventStatus.Reserved);
                const events = validEvents.filter((event) => event.assignee.id == sellerId);
                //(event.status === EventStatus.Done || event.status === EventStatus.Confirmed));
                const eventIds = events.map(event => event.id);
                
                const sales = await storeSalesService.getStoreEventsSales(storeId, eventIds);
                const filtered = sales.filter((sale) => sale.status === SaleStatus.Approved);
                
                setActAsSpeaker(validEvents.filter(event => event.speaker.id === sellerId).length);
                setStoreEvents(events);
                setStoreEventsSales(filtered);
            }).catch((error) => {
                message.error('Não foi possível carregar as vendas de eventos', error)
            });
        }
        else{
            setSellerStoreSales([]);
            setStoreEvents([]);
            setStoreEventsSales([]);

        }

        if(displayStoreTotal){
            
            withLoading(async () => {

                const s = new Date(Date.UTC(startDate.getFullYear(),  startDate.getMonth(), 1, 3, 0, 0, 0)).toISOString();
                const e = new Date(Date.UTC(endDate.getFullYear(),  endDate.getMonth() + 1, 1, 2, 59, 59, 999)).toISOString();

                const data = await storeSalesService.search(storeId, s, e, null, null, null, null, SaleStatus.Approved)

                setStoreSales(data);

            }).catch((error) => {
                message.error('Não foi possível carregar as vendas da loja', error)
            });
        }

	}, [storeId, sellerId, startDate, endDate]);

    
    const sellerProductItems = sellerSales.flatMap(x=>x.items);
    const giftCardItems = sellerProductItems.filter(item => item.product.productType === ProductType.GiftCard).map(item => ({total: item.total}));
	const sellerSalesAmount = sellerSales.length

    //soma do valor dos giftcards vendidos, quem ganha comissão é o vendedor que vende o produto utilizando o gift-card.
    const sellerGiftCardItemsTotal = giftCardItems.reduce((total, sale) => total + sale.total, 0);

    //soma do valor utilizado de gift card
	const sellerGiftCardTotal = sellerSales.reduce((total, sale) => total + sale.giftCardTotal, 0);
    const sellerSalesTotal = sellerSales.reduce((total, sale) => total + sale.total - sale.couponTotal, 0) - sellerGiftCardItemsTotal;
	const sellerSalesAverage = sellerSalesAmount > 0 ? sellerSalesTotal / sellerSalesAmount : 0;
    const salesComissionTotal = sellerSalesTotal * 0.013;

    console.log(`gift-card sold total: ${sellerGiftCardItemsTotal}, gift-card used total: ${sellerGiftCardTotal}`);

    const getStoreEventSales = (storeEventSales) => {

        const productItems = storeEventSales.flatMap(x=>x.items);
        const services = productItems.filter(item => item.product.productType === ProductType.Service || item.product.productType === ProductType.EventService)
                                     .map(item => ({total: item.subtotal - item.discount + item.added}));

                                     
        //const giftCardTotal = storeEventSales.reduce((total, sale) => total + sale.giftCardTotal, 0);
        const serviceTotal = services.reduce((total, item) => total + item.total, 0);
        const total = storeEventSales.reduce((total, sale) => total + sale.total - sale.couponTotal, 0) - serviceTotal;
        const comission = total * 0.013;

        // console.log('storeEventSales', serviceTotal, storeEventSales.reduce((total, sale) => total + sale.total, 0));
        // console.table(storeEventSales, [`storeEventId`, `total`, `status`, `couponTotal`, `giftCardTotal`]);

        return {serviceTotal, total, comission};
    };

    const {serviceTotal, total: storeEventSalesTotal, comission: storeEventSalesComissionTotal  } = getStoreEventSales(storeEventsSales);


    const TitleBox = styled(Typography)(({ theme, backgroundColor }) => ({
        backgroundColor: backgroundColor || theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1),
        textAlign: 'center',
    }));

    const LabelAndValue = ({ label, value }) => (
        <Stack direction="column" alignItems="center" justifyContent="space-between">
            <Typography variant="caption">{label}</Typography>
            <Typography variant="h2">{value}</Typography>
        </Stack>
    );

    const storeTotalizers = () =>{        
    
        const storeSalesAmount = storeSales.length;
        //const storeSalesTotal = storeSales.reduce((total, sale) => total + sale.total - sale.couponTotal, 0)
        const storeSalesTotal = storeSales.reduce((total, sale) => total + sale.total , 0);
        const couponTotal = storeSales.reduce((total, sale) => total+ sale.couponTotal, 0);
        const liquidSalesTotal = storeSalesTotal - couponTotal;
        const storeSalesAverage = storeSalesAmount > 0 ? liquidSalesTotal / storeSalesAmount : 0;

        return (<Grid xs={12} md={12} sx={{marginTop: 6}}>
            <TitleBox backgroundColor={'warning.main'}>Vendas Total da Loja</TitleBox>
            <Stack direction="row" alignItems="center" justifyContent="space-evenly">
                <LabelAndValue label="Qtd de Vendas" value={storeSalesAmount} />
                <LabelAndValue label="Valor Total" value={formatter(storeSalesTotal, 'currency')} />
                <LabelAndValue label="Total Devoluções" value={formatter(couponTotal, 'currency')} />
                <LabelAndValue label="Venda Líquida Total" value={formatter(liquidSalesTotal, 'currency')} />
                <LabelAndValue label="Ticket Médio" value={formatter(storeSalesAverage, 'currency')} />
            </Stack>
        </Grid>);
    }

	return (            
        <Grid container spacing={3}>            
            <Grid xs={12} md={12}>
                <TitleBox>Vendas de loja</TitleBox>
                <Stack direction="row" alignItems="center" justifyContent="space-evenly">
                    <LabelAndValue label="Qtd de Vendas" value={sellerSalesAmount} />
                    <LabelAndValue label="Valor Total" value={formatter(sellerSalesTotal, 'currency')} />
                    <LabelAndValue label="Ticket Médio" value={formatter(sellerSalesAverage, 'currency')} />
                    <LabelAndValue label="Comissão" value={formatter(salesComissionTotal, 'currency')} />
                </Stack>
                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 3}}>
                    {!displayTable && (<Typography variant="caption" sx={{cursor: 'pointer'}} onClick={()=> setDisplayTable(true)}>Exibir tabela detalhada das vendas</Typography>)}
                    {displayTable && (<Typography variant="caption" sx={{cursor: 'pointer'}} onClick={()=> setDisplayTable(false)}>Ocultar tabela detalhada das vendas</Typography>)}
                </Box>

                
                {displayTable && sellerSales && sellerSales.length > 0 && (
                <TableContainer component={Paper} sx={{m: 1, marginTop: 3, width: 'auto'}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Data da venda </TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell align="right">Forma Pagamento</TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell align="right">Comissão</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sellerSales.map((sale) => {

                                const total = sale.total - sale.couponTotal + sale.giftCardTotal;

                                return (
                                    <TableRow
                                        key={sale.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => saleItemClick && saleItemClick(sale)}
                                    >
                                        <TableCell component="th" scope="row">
                                            {formatter(sale.createdAt, 'date')}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {sale.customer?.name}
                                        </TableCell>
                                        <TableCell align="right">{sale.invoices.map(i=> i.paymentMethod.description + " (" + formatter(i.total, 'currency') + ")").join(", ")}</TableCell>
                                        <TableCell align="right">{formatter(total, 'currency')}</TableCell>
                                        <TableCell align="right">{formatter(total * 0.013, 'currency')}</TableCell>
                                    </TableRow>
                                )}
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                )}
            </Grid>
            <Grid xs={12} md={12}>
                <TitleBox>Vendas de Eventos</TitleBox>
                <Stack direction="row" alignItems="center" justifyContent="space-evenly">
                    <LabelAndValue label="Qtd de eventos" value={storeEvents.length} />
                    {isManager && <LabelAndValue label="Qtd de palestras" value={actAsSpeaker} />}
                    <LabelAndValue label="Valor Total" value={formatter(storeEventSalesTotal, 'currency')} />
                    <LabelAndValue label="Serviços Total" value={formatter(serviceTotal, 'currency')} />
                    <LabelAndValue label="Comissão" value={formatter(storeEventSalesComissionTotal, 'currency')} />
                </Stack>
                
                {storeEvents && storeEvents.length > 0 && (
                <TableContainer component={Paper} sx={{m: 1, marginTop: 3, width: 'auto'}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Data do evento</TableCell>
                                <TableCell>Palestrante</TableCell>
                                <TableCell>Noiva</TableCell>
                                <TableCell align="right">Vendas</TableCell>
                                <TableCell align="right">Total Serviços</TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell align="right">Comissão</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {storeEvents.map((event) => {
                                
                                const bridgeName = event?.bridge?.name;
                                const eventSales = storeEventsSales.filter(sale => sale.storeEventId === event.id);
                                
                                //if(eventSales.length === 0) return null;

                                const {serviceTotal, total, comission } = getStoreEventSales(eventSales);


                                return (
                                    <TableRow
                                        key={event.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {formatter(event.eventDate, 'date')} - {formatter(event.eventStartAt, 'time')}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {event.speaker.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {bridgeName}
                                        </TableCell>
                                        <TableCell align="right">{eventSales.length}</TableCell>
                                        <TableCell align="right">{formatter(serviceTotal, 'currency')}</TableCell>
                                        <TableCell align="right">{formatter(total, 'currency')}</TableCell>
                                        <TableCell align="right">{formatter(comission, 'currency')}</TableCell>
                                    </TableRow>
                                )}
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                )}
            </Grid>

            { displayStoreTotal && storeTotalizers() }
        </Grid>
	)
}

export default SalesReport;
