const { Box, Typography } = require("@mui/material");

const TitleBox = ({ title, children, sx }) => {
    const sxDefault = {
        backgroundColor: 'grey.100',
        padding: '10px 15px', 
        marginTop: 2,
        borderRadius: '8px 8px 0px 0px'
    };
    sx = {...sxDefault, ...sx};

    return (
        <Box sx={sx}>
            <Typography variant="h6" component="div">
                {title}
            </Typography>
            {children}
        </Box>
    );
};
export default TitleBox;
