import {
	Alert,
	FormControl,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import productService from "services/product.service";
import { useAppHooks } from "hooks/useAppHooks";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";

const AvailableProducts = ({ products, onAddProduct, storeId }) => {
	const [filter, setFilter] = useState("");
	const [query, setQuery] = useState([]);
	const { withLoading, message } = useAppHooks();
	const currencyFormatter = useCurrencyFormatter();

	const handleFilterChange = (event) => {
		setFilter(event.target.value);
	};

    let filteredItems = [...products].sort((a, b) => (a.product.name > b.product.name) ? 1 : -1);

    if(filter.length > 0){
		const ids = query.filter((item) =>
			item.name.toLowerCase().includes(filter.toLowerCase()) ||
			item.sku.toLowerCase().includes(filter.toLowerCase()) ||
			item.storeProduct.code.toLowerCase().includes(filter.toLowerCase())
		).map((item) => item.id);

        filteredItems = products.filter((item) => ids.includes(item.product.id));
    }

	useEffect(() => {

		withLoading(async () => {

			const ids = products.map((item) => item.product.id);

			var searchingBase = await productService.list(storeId, ids);

			setQuery(searchingBase);

		}).catch((err) => {
			message.error('Erro ao filtrar produtos', err);
		});
	}, [products]);


	return (
		<>
            <FormControl fullWidth>
				<TextField
					id="search"
					label="Busca de produtos"
					type="search"
					value={filter}
					onChange={handleFilterChange}
				/>

            </FormControl>

			{filteredItems.length > 0 ? (
				<TableContainer component={Paper} sx={{mt: 1}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Produto</TableCell>
								<TableCell>Preço</TableCell>
								<TableCell>Qtd</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredItems.map((item) => (
								<TableRow
									key={item.id}
                                    style={{cursor: 'pointer'}}
									onClick={() => onAddProduct(item)}
								>									
									<TableCell>
										<Typography variant="caption" sx={{fontWeight: 'bold'}}>
										{item.product.sku} 
										</Typography>
										- {item.product.name}</TableCell>
									<TableCell>
										{currencyFormatter(item.price)}
									</TableCell>
									<TableCell>{item.quantity}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
                <Alert severity="warning" sx={{mt: 1}}>
                    <strong>Nenhum produto encontrado</strong>
                </Alert>
			)}
		</>
	);
};

export default AvailableProducts;
