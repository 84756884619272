import { Box, IconButton, Typography, styled } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import TitleBox from 'components/Layout/TitleBox';
import signatureService from '../../../services/signature.service';
import { useAppHooks } from 'hooks/useAppHooks';

const ServicesContainer = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: '0',
  margin: '0',
  '& li': {
    padding: '5px 0',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    }
  },
  '& .signing': {
    color: '#ff5e00',
    fontWeight: 500,
    fontSize: '80%',
    '&.signed': {
      color: '#039903',
    }
  }
}));

const ServicesSummary = (props) => {
  const { storeEvent } = props;
  const { message } = useAppHooks();

  const getDocumentUrl = (service) => {
    if (service.signatureId) {
      return signatureService.signedDocumentUrl(storeEvent.id, service.signatureId);
    } else {
      return '#';
    }
  };

  const handleCopy = (event) => {
    //copy to clipboard and display a message to the user that the link was copied
    const location = 'https://app.amelingerie.com/signature?store-event=' + storeEvent.id;
    navigator.clipboard.writeText(location);
    
    message.success('Link copiado para a área de transferência');
    
  }

  if (!storeEvent) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <TitleBox title="Serviços contratados" sx={{ mt: 0, mb: 2, position: 'relative' }}>
        
        <IconButton
          onClick={handleCopy}
          sx={{position: 'absolute', right: '4px', top: '4px'}}
        >          
          <ShareIcon />
        </IconButton>
      </TitleBox>

      <ServicesContainer>
        {storeEvent.services.map((service) => (
          <li key={service.id}>
            <a href={getDocumentUrl(service)} target="_blank" rel="noopener noreferrer">
              {service.eventService?.name}
            </a>
            <Box component="span" sx={{ typography: 'body2', textAlign: 'right', float: 'right' }}>
              {service.total.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Box>
            <br />
            <span className={`signing mr-2 ${service.signed ? 'signed' : ''}`}>
              | {service.signed ? 'Assinado' : 'Pendente'}
            </span>
            {service.quantity > 1 && <mark>{`${service.quantity} pessoas`}</mark>}
          </li>
        ))}
      </ServicesContainer>
    </>
  );
};

export default ServicesSummary;
