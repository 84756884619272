import { Button, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CustomerInput from 'pages/Administration/Customer/CustomerInput';
import PaymentSelection2 from 'components/Sales/PaymentSelection2';
import ProductSelection from 'components/Sales/ProductSelection';
import TitleBox from 'components/Layout/TitleBox';
import settings from 'settings.js';
import storeSalesService from 'services/store-sales.service';
import storeService from 'services/store.service';
import { useAppHooks } from 'hooks/useAppHooks';

const SalesCreatePage = (props) => {

    const customerIsValid = useRef(false);
    const navigate = useNavigate();

    const { storeId, storeEventId, customer: customerData, coupon } = props;
    const [customer, setCustomer] = useState(customerData || {});
    const couponId  = coupon?.id || `--`;
    const customerId = customer?.cpf  || `--`;
    const [products, setProducts] = useState([]);
    const [payment, setPayment] = useState({remaining: 1, installments: [], coupons: [], giftCards: []});
    const [step, setStep] = useState(0);
    const couponRef = useRef(coupon);
    const { withLoading, message } = useAppHooks();

    const { installments, coupons, giftCards, remaining, extraValue, justify } = payment;

    const productsTotal = products && products.length > 0 ? +products.reduce((amount, product)=> amount + product.price, 0).toFixed(2) : 0;
    
    const customerUpdateHandler = (valid, customer) => {
        customerIsValid.current = valid;
        setCustomer(customer);
    }

    const productsUpdatedHandler = (products) =>{
        setProducts(products);
    }

    const paymentUpdateHandler = (payment) =>{
        setPayment(payment);
    }

    const sendSale = async ()=>{
        
        withLoading(async () => {
            const paymentMethods = await storeService.paymentMethods(storeId);
            const sortedInstallments = installments.map(x=> ({...x})).sort((a, b) => {
                if(a.name !== settings.cashPaymentMethod)
                    return -1;
                if(b.name !== settings.cashPaymentMethod)
                    return 1;
                return 0;
            });
            

            const couponsSum = +coupons.reduce((amount, item) => amount + item.value, 0).toFixed(2);
            const installmentsSum = +installments.reduce((amount, installment) => amount + installment.value, 0).toFixed(2);
            const giftCardsSum = +giftCards.reduce((amount, giftCard) => amount + giftCard.value, 0).toFixed(2);

            //Total=150, Coupon=100
            const extra = +((extraValue || 0).toFixed(2));
            
            let remaning = +Math.max(productsTotal + extra - giftCardsSum, 0).toFixed(2);

            const couponUsage = +Math.min(remaning, couponsSum).toFixed(2);

            remaning = +Math.max(remaning - couponsSum, 0).toFixed(2);

            let installmentsChange = +Math.max(installmentsSum - remaning, 0).toFixed(2);

            //if installment has change, perform all installments reducing the value until get the change value equals to 0, first do it using the Dinheiro payment method.
            if(installmentsChange > 0){

                for (let index = sortedInstallments.length - 1; index >= 0; index--) {
                    const installment = sortedInstallments[index];
                    let installmentValue = installment.value - installmentsChange;

                    installment.value = installmentValue;

                    if(installmentValue <= 0){
                        sortedInstallments.splice(index, 1);
                        installmentsChange = Math.abs(installmentValue);
                    }
                    else{
                        break;
                    }
                }

            }

            const discountTotal = extraValue < 0 ? extraValue : 0;
            const addingTotal = extraValue > 0 ? extraValue : 0;
            const discountPercentage = discountTotal / productsTotal;
            const addingPercentage = addingTotal / productsTotal;

            const sale = {
                storeId: storeId,
                customer: customer,
                storeEventId: storeEventId,
                items: products.map(x => ({ id: x.id, quantity: 1, discount: +(x.price * discountPercentage).toFixed(2), adding: +(x.price * addingPercentage).toFixed(2) })),
                returnCouponIds: coupons.map(x=> x.id),
                giftCardIds: giftCards.map(x=> x.id),
                justify: justify,
                invoices: installments.map( (x, i) => ({
                    item: i + 1,
                    paymentMethod: {id: +x.externalId, description: x.name},
                    installments: x.installments,
                    total: x.value
                }))
            };

            //adjust discount and adding values when percentage is not exact
            const discountTotalSum = sale.items.reduce((amount, item) => amount + item.discount, 0);
            const addingTotalSum = sale.items.reduce((amount, item) => amount + item.adding, 0);

            const discountDiff = +(discountTotal - discountTotalSum).toFixed(2);
            const addingDiff = +(addingTotal - addingTotalSum).toFixed(2);

            console.log(`discountDiff`, discountDiff, discountTotalSum, discountTotal)

            if(discountDiff !== 0){
                sale.items[0].discount += discountDiff;
            }
            if(addingDiff !== 0){
                sale.items[0].adding += addingDiff;
            }

            if(coupons.length && couponUsage > 0){
                const couponPaymentMethod = paymentMethods.find(x=>x.name === settings.returnInstallment);
                const couponInstallment = {
                    item: sale.invoices.length + 1,
                    paymentMethod: {id: +couponPaymentMethod.externalId, description: couponPaymentMethod.name},
                    installments: 1,
                    total: couponUsage
                };
                sale.invoices.push(couponInstallment);
            }

            sale.invoices = sale.invoices.map( (x) => {
                const installment = [];
                
                const value = x.total / x.installments;
                var date = new Date();

                for (let index = 1; index <= x.installments; index++) {
                                
                    if(x.installments > 1)
                        date.setDate(date.getDate() + 30);
                    

                    installment.push({
                        item: index,
                        value,
                        dueDate: date
                    });                
                }
                
                return {...x, installment}
                
            });

            var data = await storeSalesService.create(storeId, sale);
            
            message.success("Venda registrada com sucesso.");
            
            if(storeEventId)
                navigate(`/store-event/${storeEventId}/sales/${data.sale.id}`, { state: { sale: data.sale, coupon: data.coupon }});
            else
                navigate(`/store/${storeId}/sales/${data.sale.id}`, { state: { sale: data.sale, coupon: data.coupon }});
            
        }).catch((e) => {
            console.error(e);
            message.error("Não foi posssível registrar a venda.", e);
        });

    }


    const prevHandler = (event) =>{
        setStep((s) => s-1);
    }
    const nextHandler = (event) =>{

        if(step === steps.length - 1){
            sendSale();
        }
        else{
            setStep((s) => s+1);
        }
    }

    const nextDisabled = step === 0 ? !customerIsValid.current:
                         step === 1 ? products.length < 1 :
                         step === 2 ? remaining > 0 :
                         false;


    const steps = [ 'Dados do comprador', 'Seleção de Produtos', 'Pagamento'];

    return (
        <Box sx={{ width: '100%' }}>
            <TitleBox title={steps[step]} sx={{ mt: 3, mb: 2 }} />
            {step === 0 && <CustomerInput onUpdated={customerUpdateHandler} customer={customer}  />}
            {step === 1 && <ProductSelection products={products} onProductsUpdated={productsUpdatedHandler} storeId={storeId} />}
            {step === 2 && <PaymentSelection2 installments={installments} giftCards={giftCards} coupons={coupons} coupon={couponRef.current?.id} extraValue={extraValue} justify={justify} onUpdated={paymentUpdateHandler} storeId={storeId} productsTotal={productsTotal}  />}
            <br />
            <br />
            <Stack direction='row' gap={1} justifyContent='space-between'>
                <Button variant='outlined' onClick={prevHandler} disabled={step === 0}>Voltar</Button>

                <span> {step+ 1} / {steps.length}</span>

                
                <Stack direction='row' gap={2}>
                    {step === 0 && nextDisabled && <Button variant='outlined' color='secondary' onClick={() => { setCustomer({}); nextHandler();}}>Pular</Button>}
                    <Button variant='contained' onClick={nextHandler} disabled={nextDisabled}>{ step === steps.length - 1? "Finalizar venda" : "Avançar"}</Button>

                </Stack>
            </Stack>

        </Box>
    );
};

export default SalesCreatePage;