import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { authActions } from "store/slice/auth-slice";

const LogoutPage = () => {
  //get return url from queryString logout then navigate to login page
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = new URLSearchParams(location.search).get('returnUrl') || '/';
  
  const dispatch = useDispatch();
  
  const logout = () => {
    dispatch(authActions.logout())
  }

  useEffect(() => {
    logout()
  }, []);
  
  return <Navigate to={{
                        pathname: "/login" ,
                        search: `?returnUrl=${returnUrl}`
                    }}
                    replace 
                    />;
}

export default LogoutPage
