import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AvailableProducts from "components/Returns/AvailableProducts";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ProductSelection from "components/Returns/ProductSelection";
import { setReturnLimit } from "store/slice/store-event.slice";
import storeEventService from "services/store-event.service";
import storeSalesReturnService from "services/store-sales-return.service";
import storeSalesService from "services/store-sales.service";
import styled from "@emotion/styled";
import { useAppHooks } from "hooks/useAppHooks";
import { useHasRole } from "hooks/useHasRole";
import { useNavigate } from "react-router-dom";

const ReturnCreatePage = () => {
	const storeEvent = useSelector((state) => state.storeEvent.event);
	const [products, setProducts] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);
    const isManager = useHasRole(["Administrator", "Manager"]); // Initialize your RoleService
    const { withLoading, message } = useAppHooks();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        withLoading(async () => {
            try {
                const [returns, sales] = await Promise.all([
                    storeSalesReturnService.getByStoreEvent(storeEvent.id),
                    storeSalesService.all(storeEvent.store.id, storeEvent.id)
                ]);

                const returnItems = returns.flatMap((r) => r.items);
                const approved = sales.filter((sale) => sale.status == 30);
                const items = approved
                    .map((sale) =>
                        sale.items.map((item) => {
                            const newItem = {
                                ...sale,
                                ...item,
                                quantity: +item.quantity,
                                price: (item.subtotal - item.discount) / item.quantity,
                                saleId: sale.id
                            };

                            return newItem;
                        })
                    )
                    .flat();

                returnItems.forEach((r) => {
                    var soldItem = items.find(
                        (i) =>
                            i.product.id == r.product.id &&
                            i.saleId == r.saleId &&
                            i.quantity > 0
                    );

                    if (soldItem) {
                        soldItem.quantity--;
                    }
                });

                const filteredItems = items.filter((item) => item.quantity > 0);
                setProducts(filteredItems);
            } catch (error) {
                message.error("Erro ao carregar produtos disponíveis!", error);
            }
        });
        
    }, []);

	const onAddProduct = (product) => {
		setSelectedProducts([...selectedProducts, product]);
		// remove added product from the available products list
		setProducts(products.filter((p) => p.id !== product.id));
	};

	const onRemoveProduct = (product) => {
		// add the product back to the available products list
		setProducts([...products, product]);
		setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
	};

    const onSave = () => {

        if(selectedProducts.length === 0) return;


        const products = selectedProducts.map((item) => ({
            product: { ...item.product, sku: item.product.sku ?? item.product.code },  
            unitaryValue: (+item.price).toFixed(2),
            saleId: item.saleId,
        }));

        withLoading( async () => {

            try {
                const data = await storeSalesReturnService.create(storeEvent.store.id, storeEvent.id, null, products, storeEvent.bridge);
                message.success("Devolução realizada com sucesso!", "success");
                navigate(`/store-event/${storeEvent.id}/return/${data.salesReturn.id}`);
            } catch (error) {
                message.error("Erro ao realizar devolução!", error);
            }

        });
    }

    const onReturnLimitClickHandler = () => {

        withLoading( async () => {

            try{
                const date = await storeEventService.setReturnLimit(storeEvent.id);
                dispatch(setReturnLimit(date));
            } catch(error){
                message.error("Erro ao forçar devolução!", error);
            }
        });
    };

    const StickyGrid = styled(Grid2)({
        position: "sticky",
        top: "68px",
        height: 'max-content'
    });

    if(storeEvent && new Date(storeEvent.returnLimit) < new Date()){
        return (
            <Box
                display='flex' flexDirection='column' justifyContent='center' alignItems='center'
                sx={{ height: '100vh' }}>
                <Typography variant='h5' color='text.secondary'>
                    O prazo para devolução de produtos do evento expirou.
                </Typography>
                {isManager && (
                    <Button variant="contained" color="primary" 
                        onClick={onReturnLimitClickHandler}>Forçar devolução</Button>
                )}
            </Box>
        );
    }

	return (
		<Grid2 container spacing={2}>
			<Grid2 xs={12} md={6}>
				<AvailableProducts products={products}  storeId={storeEvent.store.id}  onAddProduct={onAddProduct} />
			</Grid2>
			<StickyGrid xs={12} md={6}>
				<ProductSelection
					products={selectedProducts}
					onRemoveProduct={onRemoveProduct}
				/>
                <Box
                    display='flex' justifyContent='flex-end'
                    sx={{ mt: 2 }}>

                    <Button variant="contained" color="primary" 
                        disabled={selectedProducts.length === 0} onClick={onSave}>Confirmar devolução</Button>
                </Box>
			</StickyGrid>
		</Grid2>
        
	);
};

export default ReturnCreatePage;
