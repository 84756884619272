import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import AttachFile from './AttachFile'
import TitleBox from 'components/Layout/TitleBox'
import docIcon from 'assets/icons/doc.png'
import imageIcon from 'assets/icons/image.png'
import pdfIcon from 'assets/icons/pdf.png'
import storeEventService from 'services/store-event.service'
import { useAppHooks } from 'hooks/useAppHooks'

const Attachments = ({ storeEventId }) => {
	const [files, setFiles] = useState([])
	const { withLoading, message, formatter } = useAppHooks()

	useEffect(() => {
		if (storeEventId) {
			withLoading(async () => {
				var files = await storeEventService.attachments(storeEventId);
				setFiles(files)
			}).catch((e) => {
				message.error('Erro ao carregar os arquivos', e)
			})
		}
	}, [storeEventId])

	const downloadFile = (file) => {
		
		withLoading(async () => {
			var blob = await storeEventService.downloadAttachment(storeEventId, file.name);

            const blobType = blob.type || 'application/octet-stream'; // Set a default type if not present
            const url = window.URL.createObjectURL(new Blob([blob], { type: blobType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            link.remove();
						
		}).catch((e) => {
			message.error('Erro ao carregar os arquivos', e)
		});

	}

	return (
		<Box sx={{marginTop: '20px'}}>

			<TitleBox sx={{marginTop: 5, justifyContent: 'space-between', alignItems: 'center', gap: 2, display: 'flex', flexDirection: 'row'}}
					title="Anexos" children={<AttachFile storeEventId={storeEventId} onUploaded={(uploaded) => setFiles([...files, uploaded])} />} />

			<Stack direction='row' gap={2} sx={{marginTop: 2}}>
			{files.map((file, index) => {
				const name = file.name;
				const icon = name.endsWith('.pdf') ?
								pdfIcon : name.endsWith('.doc') || name.endsWith('.docx') ?
								docIcon : imageIcon;
				
				return (
				<Stack key={index} onClick={() => downloadFile(file)} sx={{alignItems: 'center', cursor: 'pointer'}}>
					<img src={icon} alt="icon" width="70" height="70" />
					<Typography variant="caption" gutterBottom component="div">
						{formatter(file.created, 'dateTime')}
					</Typography>
				</Stack>
			)})}
			</Stack>
		</Box>
	)
}

export default Attachments;