// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarSeverity: 'error',
};

const globalSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSnackbar: (state, action) => {
      const { message, severity } = action.payload;
      state.snackbarMessage = message;
      state.snackbarSeverity = severity;
      state.snackbarOpen = true;
    },
    closeSnackbar: (state) => {
      state.snackbarOpen = false;
    },
  },
});

export const { setLoading, setSnackbar, closeSnackbar } = globalSlice.actions;

export default globalSlice;
