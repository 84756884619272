import { Alert, AlertTitle, Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material'
import { SaleStatus, SaleStatusColor, SaleStatusText } from '../../../models/SaleStatus'

import { OriginText } from '../../../models/Origin'
import ProductTable from './ProductTable'
import { ProductType } from 'models/ProductType'
import React from 'react'
import styled from '@emotion/styled'
import useFormatter from 'hooks/useFormatter'

const SalesList = ({ sales, onClick }) => {
  const formatter = useFormatter();  

  const Tag = styled(Typography)(({ theme, status }) => ({
    '&': {
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize,
      display: 'inline-block',
      width: 'fit-content',
      textAlign: 'center',
      backgroundColor: SaleStatusColor[status],
    },
  }))

  const CustomTable = styled(Table)(({ theme }) => ({
    '&': {

    },

    '&>tbody>tr:nth-child(odd)': {

      borderBottom: 'none',
      '&>td': {
        padding: theme.spacing(2, 2, 0.5, 2),
        borderBottom: 'none',
      }

    },
    '&>tbody>tr:nth-child(even)': {

      borderBottom: 'none',
      '&>td': {
        padding: theme.spacing(0.5, 2, 4, 2),
      }

    }
  }));

  const CustomAlert = styled(Alert)(({ theme }) => ({
    '& .MuiAlert-message': {
      flexGrow: 1,
    },
  }));

  if(!sales || sales.length < 1) {
    return (
      <Typography variant="body1" className="text-center" sx={{ color: 'text.secondary' }}>
        Nenhum registro encontrado
      </Typography>
    );
  }

          
  const totalDenied = sales.filter((sale) => sale.status === SaleStatus.Denied).reduce((acc, cur) => acc + cur.total, 0);
  const totalIndifferent = sales.filter((sale) => sale.status === SaleStatus.Indifferent).reduce((acc, cur) => acc + cur.total, 0);
  const totalTyping = sales.filter((sale) => sale.status === SaleStatus.Typing).reduce((acc, cur) => acc + cur.total, 0);

  const approved = sales.filter((sale) => sale.status === SaleStatus.Approved);
  const totalApproved = approved.reduce((acc, cur) => acc + cur.total - cur.couponTotal, 0)
  const serviceItems = approved.flatMap(sale => sale.items).filter(item => item.product.productType == ProductType.Service || item.product.productType == ProductType.EventService);
  const totalService = serviceItems.reduce((acc, cur) => acc + cur.subtotal - cur.discount + cur.added, 0);
  const totalLiquid = Math.max(0, (totalApproved - totalService));

  return (
    <Box component="div" className="container-fluid">
        <TableContainer>
          <CustomTable>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Vendedora</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Origem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales.filter(x=> x.items && x.invoices).map((sale, index) => {
                const payment = sale.invoices.map(i=> `${i.paymentMethod.description} (${i.installments}x${formatter(i.total/i.installments, 'currency')})`).join(', ');
                return (
                <React.Fragment key={sale.number}>
                  <TableRow sx={{ cursor: 'pointer' }} onClick={onClick.bind(null, sale)}>
                    <TableCell>
                      <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
                      {formatter(sale?.createdAt, 'dateTime')}
                      </Typography>
                      <Typography variant="caption" component="span" sx={{ fontWeight: 'bold', ml: 3 }}>
                      {sale?.series?.toString().padLeft(3, '0')}{sale.number}
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Tag status={sale.status}>{sale.customer?.name || '--'}</Tag>                      
                    </TableCell>
                    <TableCell>{sale.seller.name}</TableCell>
                    <TableCell>{SaleStatusText[sale.status]}</TableCell>
                    <TableCell>{OriginText[sale.origin]}</TableCell>
                  </TableRow>
                  <TableRow sx={{ cursor: 'pointer' }} onClick={onClick.bind(null, sale)}>
                    <TableCell colSpan={5} >
                      <ProductTable items={sale.items} discount={sale.discount} total={sale.total - sale.couponTotal} payment={payment} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )})
            }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} align="right">
                  
                { (totalDenied > 0 || totalIndifferent > 0 || totalTyping > 0) && (
                  <CustomAlert severity="warning" variant="filled" sx={{mb: 2, textAlign: 'left'}} >
                    <AlertTitle sx={{color: 'black', fontWeight: 'bold'}}>Existem notas pendentes, verifique no TagPlus</AlertTitle>
                    <Stack direction="row" gap={2} justifyContent='space-between'>
                      <Typography variant="caption">
                        <strong>Denegado:</strong> {formatter(totalDenied, 'currency')}
                      </Typography>
                      <Typography variant="caption">
                        <strong>Indiferente:</strong> {formatter(totalIndifferent, 'currency')}
                      </Typography>
                      <Typography variant="caption">
                        <strong>Em digitação:</strong> {formatter(totalTyping, 'currency')}
                      </Typography>

                    </Stack>                    
                  </CustomAlert>
                  )}
                  <CustomAlert severity="info" sx={{mb: 2}}>
                    <Stack direction="row" gap={2} justifyContent='space-between'>
                      <Typography variant="caption">
                        <strong>Inutilizadas:</strong> {formatter(sales.filter((sale) => sale.status === SaleStatus.Disenable).reduce((acc, cur) => acc + cur.total, 0), 'currency')}
                      </Typography>
                      <Typography variant="caption">
                        <strong>Cancelados:</strong> {formatter(sales.filter((sale) => sale.status === SaleStatus.Canceled).reduce((acc, cur) => acc + cur.total, 0), 'currency')}
                      </Typography>
                      <Typography variant="caption">
                        <strong>Aprovado:</strong> {formatter(totalApproved, 'currency')}
                      </Typography>

                    </Stack>
                  </CustomAlert>
                  
                  <CustomAlert severity="success" sx={{mb: 2 }} > 

                    <Stack direction="row" gap={2} justifyContent='space-between'>
                        <Typography variant="body1">
                            <strong>Total Bruto:</strong> {formatter(totalApproved, 'currency')}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Total Serviços:</strong> {formatter(totalService, 'currency')}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Total Evento:</strong> {formatter(totalLiquid, 'currency')}
                        </Typography>
                    </Stack>

                  </CustomAlert>
                </TableCell>
              </TableRow>
            </TableFooter>
          </CustomTable>
        </TableContainer>

    </Box>
  )
}

export default SalesList
