import { Box, Typography } from "@mui/material";

import useFormatter from "hooks/useFormatter";

export const EventListItem = ({ storeEvent, onClick }) => {
    const formatter = useFormatter();

    const name = storeEvent?.bridge?.name?.split(" ")[0] || storeEvent?.contractorName?.split(" ")[0];

    return (
        <Box
            sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start',
                p: 1.2, cursor: 'pointer', borderBottom: 1, borderColor: 'divider', '&:hover': { backgroundColor: 'grey.50' }
            }}
            onClick={onClick?.bind(null, storeEvent)}
        >
            <Box>
                <Typography variant="h6" gutterBottom>
                    Chá da {name} - {formatter(storeEvent?.eventDate, 'date')} {formatter(storeEvent?.eventStartAt, 'time')}
                </Typography>
                <Typography variant="body2" component="div" sx={{ color: 'text.secondary' }}>
                    Registrado por: {storeEvent.assignee.name}
                </Typography>
            </Box>
            <Box>
                <Typography variant="body2" component="div" sx={{ color: 'text.secondary' }}>
                    Criado em:
                </Typography>
                <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                    {formatter(storeEvent.createdOn, 'date')}
                </Typography>
            </Box>
        </Box>
    );
};
