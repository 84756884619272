//create a component that represent the filters to search for a store event, we can use the same component for the store sales search
//this component will have a form with the following fields: start date, end date, store, query and search button
//please us MUI material components for the form
//the search button will trigger the search function that will be passed as a prop

import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const StoreEventSearchFilter = ({ onSearch, storeId }) => {
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        search: '',
        pageSize: 100,
        page: 1
    });

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        onSearch(filters);
    }

    useEffect(() => {
        //initialize start and end date with latest 7 days
        const today = new Date();
        const endDate = today.toISOString().split('T')[0];
        today.setDate(today.getDate() - 7);
        const startDate = today.toISOString().split('T')[0];
        const updatedFilters = {
            ...filters,
            startDate,
            endDate,
        };

        setFilters(updatedFilters);
        onSearch(updatedFilters);
    }, []);

    return (
        <form onSubmit={handleSearch}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="Data Inicial"
                        name="startDate"
                        onChange={handleFilterChange}
                        type="date"
                        value={filters.startDate}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="Data Final"
                        name="endDate"
                        onChange={handleFilterChange}
                        type="date"
                        value={filters.endDate}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="Filtro"
                        name="search"
                        onChange={handleFilterChange}
                        value={filters.search}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Pesquisar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default StoreEventSearchFilter;
