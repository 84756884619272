import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import employeeService from 'services/employee.service'
import { useSelector } from 'react-redux'

const EmployeesAutoComplete = (props) => {
  const { css, title, placeholder, controlName, onChange, selected, multiple } = props;
  const [options, setOptions] = useState([]);
  const [formValue, setFormValue] = useState(multiple ? [] : null);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    employeeService.list().then((e) => {

      const employees = e.filter((employee) => employee.hiringDate !== "0001-01-01T00:00:00" && employee.dismissingDate === null);

      const currentEmployeeIndex = employees.findIndex((employee) => employee.id === currentUser.EmployeeIdentifier)
      const [firstEmployee] = employees.splice(currentEmployeeIndex, 1)
      employees.unshift(firstEmployee)

      console.log('employees', employees);

      setOptions(employees)
    })
  }, []);

  useEffect(() => {
    if (selected && options.length > 0) {
      //check if selected is an array or an object, then find the item in the options array and set it to formValue
      if (Array.isArray(selected)) {
        const items = selected.map((item) => options.find((option) => option.id === item.id || option.id === item));
        setFormValue(items);
      } else if (typeof selected === 'object') {
        const item = options.find((option) => option.id === selected.id || option.id === selected);
        setFormValue(item);
      } else{
        const item = options.find((option) => option.id === selected);
        setFormValue(item);
      }
    }
  }, [selected, options]);

  const changeHandler = (event, value) => {
    setFormValue(value)
    onChange(value)
  }

  const { childHelperText, childError } = props;

  return (
    <div className={css}>
      <Autocomplete 
        {...props}
        id={controlName} 
        options={options} 
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
        value={formValue} 
        onChange={changeHandler} 
        renderInput={(params) => 
            <TextField {...params} 
                label={title} 
                error={childError}
                helperText={childHelperText}
                placeholder={placeholder}  />} />
    </div>
  )
}

export default EmployeesAutoComplete
