import { Box, Button, Checkbox, Chip, Container, Divider, FormControl, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'

import EmployeesAutoComplete from 'components/Employee/EmployeesAutoComplete'
import EventPeriods from 'components/StoreEvent/EventPeriods'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import PhoneInput from 'components/PhoneInput'
import ServiceSelection from 'components/StoreEvent/Services/ServiceSelection'
import StoreSelector from 'components/Store/StoreSelector'
import storeEventService from 'services/store-event.service'
import styled from '@emotion/styled'
import { useAppHooks } from 'hooks/useAppHooks'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

const RegistrationPage = () => {
  const defaultStore = useSelector((state) => state.stores.default);
  const { formatter } = useAppHooks();
  const [ selectedServices, setSelectedServices ] = useState([]);
  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, control } = useForm();
  const { withLoading, message } = useAppHooks();
  const navigate = useNavigate();

  //keep watching the form values to get notified when they change, remove it will cause the form to not update the services
	const storeValue = watch('store')
	const eventDateValue = watch('eventDate')
	const eventTimeValue = watch('eventTime')

  
  const selectStoreHandler = (storeId) => {
    setValue('store', storeId)
  }

  const contractClosedByHandler = (employee) => {
    setValue('contractClosedBy', employee)
  }

  const eventPeriodChangeHandler = (eventTime) => {
    setValue('eventTime', eventTime);
  }

  const servicesChangeHandler = (services) => {
    setSelectedServices([...services]);
  }

  useEffect(() => {
    if(defaultStore) {
      setValue('store', defaultStore.id);
    }
  }, []);

  const saveHandler = (data) => {

    const { eventDate, eventTime, store, contractClosedBy, name, email, phone } = data;
    const storeEvent = {
      assigneeId: contractClosedBy?.id,
      storeId: store,
      eventDate,
      startAt: eventTime?.startAt,
      endAt: eventTime?.endAt,
      contractorName: name,
      contractorMail: email,
      contractorPhone: phone,
      services: selectedServices.map((service) => ({ 
        id: service.service.id, 
        quantity: service.quantity,
        total: service.total,
      })),
    };

    withLoading(async ()=> {
        try {
          var data = await storeEventService.create(storeEvent);
          message.success('Evento registrado com sucesso');
          navigate(`/store-event/${data.id}`);
        } catch (error) {
            message.error("Não foi possível registrar o evento", error);
        }
    });
  };

  const DividerCustom = styled(Divider)(({ theme }) => ({

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

  }));

  const formData = getValues();
  const total = selectedServices.reduce((total, service) => total + service.total, 0);


  return (
    <Container maxWidth={false}>
        <DividerCustom textAlign='left'>
            <Chip label="Dados do evento" color='secondary'  />
        </DividerCustom>

        <Grid2 container spacing={2}>
            <Grid2 xs={12} md={6}>
                <StoreSelector 
                  label="Loja"
                  name="store"
                  onSelectStore={selectStoreHandler}
                  selectedStore={formData.store}
                  register={register('store', { required: true })}
                  error={!!errors.store}
                  helperText={errors.store && 'Este campo é obrigatório'} />
            </Grid2>
            <Grid2 xs={12} md={3}>
              <Controller
                name="eventDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <TextField 
                        {...field}
                        label="Data do Evento"
                        fullWidth 
                        variant="outlined" 
                        type="date"
                        error={!!errors.eventDate}
                        helperText={errors.eventDate && 'Este campo é obrigatório'}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
            </Grid2>
            <Grid2 xs={12} md={3}>
              
              <Controller
                name="eventTime"
                control={control}
                rules={{ required: 'Campo obrigatório'}}
                render={({ field }) => (
                  
                  <EventPeriods 
                    name="eventTime"
                    title="Horário do evento" 
                    error={!!errors.eventTime}
                    date={formData?.eventDate} selected={formData?.eventTime}
                    onChange={eventPeriodChangeHandler}
                    />
                )}
              />
                {/* <EventPeriods title="Horário do evento" date={get.eventDate} selected={formData.eventTime} /> */}
            </Grid2>
            <Grid2 xs={12} md={6}>
                <EmployeesAutoComplete
                    name="contractClosedBy"
                    register={register('contractClosedBy', { required: true })}
                    title="Quem fechou o contrato" controlName="contractClosedBy" 
                    onChange={contractClosedByHandler}   
                    childError={!!errors.contractClosedBy}
                    childHelperText={errors.contractClosedBy && 'Este campo é obrigatório'} />                 
                    {/* // error={!formData.contractClosedBy} 
                    // helperText={!formData.contractClosedBy ? 'Campo obrigatório' : ''} /> */}
            </Grid2>
        </Grid2>

        <DividerCustom textAlign='left'>
            <Chip label="Dados do contratante" color='secondary'  />
        </DividerCustom>

        <Grid2 container spacing={2}>
            <Grid2 xs={12} md={6}>
              
                <TextField
                  label="Nome"
                  name="name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  inputProps={{ maxLength: 255 }}
                  {...register('name', { required: true })}
                  error={!!errors.name}
                  helperText={errors.name && 'Este campo é obrigatório'}
                />
                {/* <TextField label="Nome" name="name" onChange={inputChangeHandler} fullWidth required
                    error={!formData.name}
                    helperText={!formData.name ? 'Campo obrigatório' : ''}
                   /> */}
            </Grid2>
            <Grid2 xs={12} md={6}>
              <TextField
                label="E-mail"
                name="email"
                fullWidth
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                error={!!errors.email}
                helperText={errors.email && 'Este campo é obrigatório e deve ser um e-mail válido'}
              />
                {/* <TextField label="Email" name="email" type="email" onChange={inputChangeHandler} fullWidth
                    error={!formData.email}
                    helperText={!formData.email ? 'Campo obrigatório' : ''}
                 /> */}
            </Grid2>
            <Grid2 xs={12} md={6}>
              <PhoneInput
                  {...register('phone', { required: true })}
                  label="Telefone"
                  name="phone"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!errors.phone}
                  helperText={errors.phone && 'Este campo é obrigatório'}
                />
                {/* <PhoneInput label="Telefone" name="phone" onChange={inputChangeHandler} fullWidth /> */}
            </Grid2>

        </Grid2>
        
        <DividerCustom textAlign='left'>
            <Chip label="Serviços" color='secondary'  />
        </DividerCustom>


        <ServiceSelection storeId={formData?.store || defaultStore?.id} date={formData?.eventDate} onChange={servicesChangeHandler} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, background: '#dedede', padding: [6,4] }}>
            <Typography variant="h6" gutterBottom={false}>
              Valor total dos serviços selecionados:
            </Typography>
            <Typography variant="h6" gutterBottom={false} sx={{ marginLeft: 2 }}>
              {formatter(total, 'currency')}
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, marginBottom: 3 }}>
            <Button variant="contained" color="primary" size="large" onClick={handleSubmit(saveHandler)}>Salvar</Button>
        </Box>
      {/* <ServiceSelection storeId={formData.store} required={false}  date={formData.eventDate}  onChange={servicesChangeHandler} /> */}
    </Container>
  )
}

export default RegistrationPage
