import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AvailableProducts from "../../../../components/Returns/AvailableProducts";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ProductSelection from "../../../../components/Returns/ProductSelection";
import storeSalesReturnService from "../../../../services/store-sales-return.service";
import storeSalesService from "../../../../services/store-sales.service";
import styled from "@emotion/styled";
import { useLoading } from "../../../../hooks/useLoading";
import { useMessage } from "../../../../hooks/useMessage";
import { useSelector } from "react-redux";

const ReturnCreatePage = () => {
    const { storeId, saleId } = useParams();
	const [products, setProducts] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [sale, setSale] = useState(null);
    const loading = useLoading();
    const message = useMessage();
    const navigate = useNavigate();

	useEffect(() => {
        Promise.all([
            storeSalesReturnService.getBySale(saleId),
            storeSalesService.get(saleId)
          ]).then(([returns, sale]) => {
            const returnItems = returns.flatMap((r) => r.items);
            return [returnItems, sale];
          }).then(([returns, sale]) => {
            const items = sale.items.map((item) => {
                                const newItem = {
                                    ...sale,
                                    ...item,
                                    quantity: +item.quantity,
                                    price: (item.subtotal - item.discount) / item.quantity,
                                    saleId: sale.id
                                };
                                
                                return newItem;
                            })
                            .flat();


            returns.forEach((r) => {
            var soldItem = items.find((i) => i.product.id == r.product.id && i.saleId == r.saleId && i.quantity > 0);

            if(soldItem){
                soldItem.quantity--;
            }
            });

            return [items.filter((item) => item.quantity > 0), sale];
          }).then(([items, sale]) => {
            setProducts(items);
            setSale(sale);
        });

	}, []);

	const onAddProduct = (product) => {
		setSelectedProducts([...selectedProducts, product]);
		// remove added product from the available products list
		setProducts(products.filter((p) => p.id !== product.id));
	};

	const onRemoveProduct = (product) => {
		// add the product back to the available products list
		setProducts([...products, product]);
		setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
	};

    const onSave = () => {

        if(selectedProducts.length === 0) return;


        const products = selectedProducts.map((item) => ({
            product: { ...item.product, sku: item.product.sku ?? item.product.code },  
            unitaryValue: (+item.price).toFixed(2),
            saleId: item.saleId
        }));

        loading.show();
        storeSalesReturnService.create(storeId, null, sale.id, products, sale.customer).then((data) => {
            message.success("Devolução realizada com sucesso!");
            console.log(data);
            navigate(`/store/${storeId}/return/${data.salesReturn.id}`);

        }).catch((error) => {
            message.error("Erro ao realizar devolução!", error);
        }).finally(() => {
            loading.hide();
        });
        
    }

    const StickyGrid = styled(Grid2)({
        position: "sticky",
        top: "68px",
        height: 'max-content'
    });

	return (
		<Grid2 container spacing={2}>
			<Grid2 xs={12} md={6}>
				<AvailableProducts products={products} storeId={storeId} onAddProduct={onAddProduct} />
			</Grid2>
			<StickyGrid xs={12} md={6}>
				<ProductSelection
					products={selectedProducts}
					onRemoveProduct={onRemoveProduct}
				/>
                <Box
                    display='flex' justifyContent='flex-end'
                    sx={{ mt: 2 }}>

                    <Button variant="contained" color="primary" 
                        disabled={selectedProducts.length === 0} onClick={onSave}>Confirmar devolução</Button>
                </Box>
			</StickyGrid>
		</Grid2>
        
	);
};

export default ReturnCreatePage;
