import { Alert, Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Barcode from "react-barcode";
import PrintHeader from "components/Layout/PrintHeader";
import customerService from "services/customer.service";
import printDocument from "services/print";
import { useAppHooks } from "hooks/useAppHooks";

function GiftCardDetails(props) {
	const { giftCard, storeId } = props;
	//get width from query string
	const params = new URLSearchParams(document.location.search);
	const width = params.get('width' ) || 2;
	const format = params.get('format' ) || `CODE128B`;
	//const { storeId } = coupon;
    const stackRef = React.useRef(null);
	const [ customer, setCustomer ] = useState(giftCard?.customer);
	const { withLoading, formatter } = useAppHooks();

	useEffect(() => {

		withLoading(async () => {

			if(giftCard.customerId){
				const data = await customerService.get(giftCard.customerId);

				setCustomer(data);
			}

			setTimeout(() => {
				printDocument(5, stackRef.current);
			}, 2000);
			
		});
	}, [giftCard]);

	const customerName = customer ? customer.name.split(' ')[0] : '';

	const FieldAndValue = ({ field, value, align }) => (
		<Box sx={{ justifyContent: align || "left", display: 'flex', padding: `8px 0` }}>
			<Typography variant="body1"><strong>{field}:</strong></Typography>
			<Typography variant="body1">{value}</Typography>
		</Box>
	);

	return (
		<Box
			ref={stackRef}
			sx={{ p: 3, textAlign: "center" }}
			className="print"
		>
			<PrintHeader storeId={storeId} />
			
			<br />

			<Stack spacing={0} direction="row" justifyContent="space-between" alignItems="center">
				<FieldAndValue field="Vendedor" value={giftCard.createdBy?.name} align='flex-start' />
				<FieldAndValue field="Criado em" value={formatter(giftCard.createdAt, "dateTime")} align='flex-end' />
			</Stack>

			<Grid container justifyContent="center" spacing={0.5}>				
				

				<Grid item xs={12} style={{ textAlign: 'center', marginBottom: 20, padding: 5, textTransform: 'uppercase', 
					borderTop: `1px dashed black`, borderBottom: `1px dashed black` }}>
					<Typography variant="h2" gutterBottom={false}>Vale presente</Typography>
				</Grid>

				{customer && (
					
					<Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 20 }}>
						<Typography variant="h5" gutterBottom={false}>Você recebeu um vale presente de <strong>{customerName}</strong></Typography>
					</Grid>
					
				)}

				{!customer && (
					
					<Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 20 }}>
						<Typography variant="h5" gutterBottom={false}>Você recebeu um vale presente</Typography>
					</Grid>
					
				)}

				<br /><br />

				<Typography variant="h4" sx={{fontWeight: `bold`}}>Valor: {formatter(giftCard.value, 'currency')}</Typography>
			
				<br /><br />

			</Grid>


			<Box width='100%' >
				<Barcode value={giftCard.id} format={format} width={width} height={150} textAlign='center'  />
			</Box>
            <div className="break"></div>
			<br />
			<br />
			<br />

		</Box>
	);
}

export default GiftCardDetails;
