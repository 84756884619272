import { createSlice } from "@reduxjs/toolkit";

const storeEventSlice = createSlice({
    name: 'storeEvent',
    initialState: {
        event: null,
        orders: null,
        sales: [],
        returns: [],
        activities: [],
    },
    reducers: {
        setEvent: (state, action) => {
            state.event = action.payload;
        },
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setSales: (state, action) => {
            state.sales = action.payload;
        },
        setReturns: (state, action) => {
            state.returns = action.payload;
        },
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        setReturnLimit: (state, action) => {
            state.event.returnLimit = action.payload;
        }
    },
});

export const {
    setEvent,
    setOrders,
    setSales,
    setReturns,
    setActivities,
    setReturnLimit,
} = storeEventSlice.actions;

export default storeEventSlice;







        