import { Alert, Box, Grid, Typography } from '@mui/material'

import React from 'react'
import useFormatter from '../../hooks/useFormatter'

export default function BasicInfo({ checkoutCounter }) {
  const formatter = useFormatter()

  const TitleAndValue = ({ title, value, severity }) => (
    <Alert severity={severity}>
      <Box display="flex" justifyContent="space-between" gap={1}>
        <Typography><strong>{title}</strong></Typography>
        <Typography variant='h4'>{value}</Typography>
      </Box>
    </Alert>
  )

  return (
    <Grid container spacing={2} pt={3}>
      <Grid item xs={12} sm={6}>
        <Typography>
          <strong>Aberto às: </strong>
          {formatter(checkoutCounter.openAt, 'dateTime')}
        </Typography>
        {checkoutCounter.closedAt && (
          <div>
            <Typography><strong>Fechado às:</strong> {formatter(checkoutCounter.closedAt, 'dateTime')}</Typography>
            <Typography><strong>Fechado por:</strong> {checkoutCounter.closedBy?.name || 'N/A'}</Typography>
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TitleAndValue title="Dinheiro na abertura:" value={formatter(checkoutCounter.initialCash, 'currency')} severity="info" />
        <TitleAndValue title={checkoutCounter.closedAt ? "Dinheiro no fechamento:" : "Dinheiro em caixa:"} 
                      value={formatter(checkoutCounter.totalCash, 'currency')} severity="success" />

        
      </Grid>
    </Grid>
  )
}
