import { Button, Paper, Stack, Typography } from '@mui/material'

import StoreSelector from '../Store/StoreSelector'
import checkoutCounterService from '../../services/checkout-counter.service'
import { useLoading } from '../../hooks/useLoading'
import { useMessage } from '../../hooks/useMessage'
import { useState } from 'react'

const Create = ({ store, onCancel, onSuccess }) => {
  const [selectedStore, setSelectedStore] = useState(store)
  const loading = useLoading()
  const message = useMessage()

  const confirmHandle = () => {
    if (!selectedStore) {
      return
    }

    loading.show()

    checkoutCounterService
      .open(selectedStore)
      .then((checkoutCounter) => {
        message.success('Caixa aberto com sucesso');
        onSuccess(checkoutCounter);
      })
      .catch((err) => {
        message.error('Não foi possível abrir o caixa', err);
      })
      .finally(() => {
        loading.hide()
      })
  }

  return (
    <Paper style={{ width: 300, padding: 20 }}>
      <Typography variant="h6">Você tem certeza que deseja realizar abertura do caixa?</Typography>
      <br />

      {!store && (  
        <StoreSelector onSelectStore={setSelectedStore} />
      )}
      <br />
      <br />
      <Stack direction="row" spacing={2} style={{ marginTop: 20 }} justifyContent="space-between">
        <Button variant="outlined" onClick={onCancel}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={confirmHandle}>
          Confirmar
        </Button>
      </Stack>
    </Paper>
  )
}

export default Create
