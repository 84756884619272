//this component will use the SearchFilter component to render the filters and the StoreEventList component to render the list of store events

import { Button, Typography } from "@mui/material";

import StoreEventList from "./StoreEventList";
import StoreEventSearchFilter from "./StoreEventSearchFilter";
import storeEventService from "services/store-event.service";
import { useAppHooks } from "hooks/useAppHooks";
import { useState } from "react";

const StoreEventSelection = ({ storeId, onSelect }) => {
    const [storeEvents, setStoreEvents] = useState([]);
    const { withLoading, message } = useAppHooks();

    const handleSearch = (filters) => {

        withLoading(async () => {

        try {
            const storeEvents = await storeEventService.search(filters);
            console.log('loaded store events', storeEvents)
            setStoreEvents(storeEvents.items);
        } catch (error) {
            message.error('Erro ao carregar eventos', error);
        }});
    }

    const handleSelect = (storeEvent) => {
        onSelect(storeEvent);
    }


    return (
        <>

            <Typography variant="h6" sx={{pb: 3}}>Selecione o evento ao qual deseja associar a venda ou clique em Venda de loja</Typography>

            <StoreEventSearchFilter storeId={storeId} onSearch={handleSearch} />
            <br />
            <StoreEventList storeEvents={storeEvents} onSelect={handleSelect} renderAction={(record) => (
                //add a button to select the store event
                <Button variant="outlined" size="small" onClick={() => handleSelect(record)}>Associar a esse evento</Button>
            )} />
        </>
    );
}

export default StoreEventSelection;