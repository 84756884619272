import { FormControl, TextField } from "@mui/material";
import { forwardRef, useState } from "react";

import useFormatter from "../../hooks/useFormatter";

const CurrencyInput = forwardRef((props, ref) => {
  const { label, variant, defaultValue, onChange, onKeyUp } = props;
  const [value, setValue] = useState(defaultValue || '');
  const formatter = useFormatter();

  const valueChangedHandler = (event) => {
    const temp = parseCurrency(event.target.value)
    if(onChange){
      onChange(temp);
    }
    setValue(temp);
  }

  const parseCurrency = (value) => {
    if (typeof value === 'number') {
      return +value.toFixed(2)
    }

    let temp = value.replace(/\D/g, '') // Remove non-digit characters
    temp = parseFloat(temp / 100).toFixed(2)
    return +temp
  }

  const copy = {...props, defaultValue: undefined };

  return (
    
    <FormControl fullWidth>
      <TextField 
                {...copy}
                label={label || "Valor"} 
                variant={variant || "outlined"}
                value={value === '' ? '' : formatter(value, 'currency')} 
                onChange={valueChangedHandler} 
                onKeyUp={onKeyUp}
                inputRef={ref}  />
      </FormControl>
  );
});

export default CurrencyInput;
