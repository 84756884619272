import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { PendingItem } from "./PendingItem";
import TitleBox from "components/Layout/TitleBox";
import storeSalesReturnService from "services/store-sales-return.service";
import { useAppHooks } from "hooks/useAppHooks";
import { useSelector } from "react-redux";

const PendingList = ({ onClick }) => {
    const { withLoading, message } = useAppHooks();
    const { user } = useSelector((state) => state.auth);
    const [pending, setPending] = useState([]);
    const stores = user?.EmployeeStores?.split(',');

    useEffect(() => {

        if(!stores) return;


        withLoading(async () => {
            try {
                const response = await storeSalesReturnService.getPending(stores);
                setPending(response);
            } catch (error) {
                message.error("Erro ao carregar devoluções pendentes!", error);
            }
        });
    }, []);

    if(!pending || pending.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
                    Não há devoluções pendentes
                </Typography>
            </Box>
        );
    }

    return (

        <Box>

            <TitleBox title="Devoluções pendentes" />
            
            {pending.map((item) => (
                <PendingItem
                    key={item.id}
                    item={item}
                    onClick={onClick}
                />
            ))}
        </Box>

    );

};


export default PendingList;

