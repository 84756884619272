import { useLocation, useParams } from 'react-router-dom';

import CreatePage from 'pages/Administration/Sales/SalesCreatePage';

const SalesCreatePage = () => {

    const location = useLocation();
    const coupon = location?.state?.coupon;
    const customer = location?.state?.customer;
    const { storeId } = useParams();

    return (
        <CreatePage storeId={storeId} customer={customer} coupon={coupon} />
    );

};

export default SalesCreatePage;