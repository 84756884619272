import { TextField } from '@mui/material';
import MaskedInput from 'react-text-mask';

const CPFMask = (props) => {
  const { inputRef, ...other } = props;

  return (

    
    <MaskedInput
        {...other}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-',/\d/, /\d/]}
        render={(innerRef, props) => (
          <TextField
            {...props}
            inputRef={innerRef}
          />
        )}
      ></MaskedInput>
  );
}

export default CPFMask;
     