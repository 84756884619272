const settings = {
    api: {
        url: process.env.REACT_APP_API_URL
        //url: 'https://api.amelingerie.com/v1',
        //url: 'https://api-hom.amelingerie.com/v1',
        //url: 'http://localhost:5000/v1'
        
    },
    events: {
        //url: 'http://localhost:4003',
        url: process.env.REACT_APP_EVENTS_URL
    },
    returnInstallment: 'Cupom de devolução',
    giftCardInstallment: 'Vale Presente',
    cashPaymentMethod: 'Dinheiro'
}

export default settings;