import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

// import { Modal, Snackbar } from '@mui/material';
// import AlertSnackbar from './AlertSnackbar';
// import { useSelector } from 'react-redux';

const AlertSnackbarWrapper = (props) => {
  //const snackbarOpen = useSelector((state) => state.global.snackbarOpen);

  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
    // <Snackbar {...props} open={snackbarOpen} autoHideDuration={6000}>
    //   <div>

    //     <AlertSnackbar />
    //   </div>
    // </Snackbar>
  );
  };
  
export default AlertSnackbarWrapper;