import CheckoutCounterDetailsPage from "../pages/Administration/CheckoutCounter/DetailsPage";
import CheckoutCounterSearchPage from "../pages/Administration/CheckoutCounter/SearchPage";
import DashboardPage from "../pages/Administration/Dashboard/DashboardPage";
import ErrorPage from "../pages/ErrorPage";
import GeneralPage from "../pages/Administration/StoreEvent/GeneralPage";
import IndexPage from "../pages/Administration/IndexPage";
import Login from "../components/Auth/Login";
import LogoutPage from "pages/LogoutPage";
import ProtectedRoute from "../components/Layout/ProtectedLayout";
import ReturnCreatePage from "../pages/Administration/StoreEvent/Returns/ReturnCreatePage";
import ReturnDetailsPage from "../pages/Administration/SalesReturn/ReturnDetailsPage";
import ReturnsPage from "../pages/Administration/StoreEvent/Returns/ReturnsPage";
import SaleSearchPage from "../pages/Administration/Sales/SalesSearchPage";
import SalesCreatePage from "../pages/Administration/StoreEvent/Sales/SalesCreatePage";
import SalesDetailsPage from "../pages/Administration/Sales/SalesDetailsPage";
import SalesSearchPage from "../pages/Administration/StoreEvent/Sales/SalesSearchPage";
import SellerSalesReportPage from "pages/Administration/Seller/SalesReportPage";
import StoreEventEditPage from "pages/Administration/StoreEvent/EditPage";
import StoreEventPage from "../pages/Administration/StoreEvent/IndexPage";
import StoreEventRegistrationPage from "pages/Administration/StoreEvent/RegistrationPage";
import StoreGeneralPage from 'pages/Administration/Store/GeneralPage';
import StoreIndexPage from 'pages/Administration/Store/IndexPage';
import StoreSalesCreatePage from 'pages/Administration/Store/Sales/SalesCreatePage';
import StoreSalesReturnCreatePage from "pages/Administration/Store/Returns/ReturnCreatePage";
import StoreSalesSearchPage from 'pages/Administration/Store/Sales/SalesSearchPage';
import { createBrowserRouter } from "react-router-dom";

const routes = createBrowserRouter([
	{
		path: "/",
		errorElement: <ErrorPage />,
		element: (
			<ProtectedRoute>
				<IndexPage />
			</ProtectedRoute>
		),
		children: [
			{
				path: "/",
				element: <DashboardPage />,
			},
			{
				path: "/store-event/register",
				element: <StoreEventRegistrationPage />,
			},
			{
				path: "/store-event/:storeEventId",
				element: <StoreEventPage />,
				children: [
					{
						index: true,
						element: <GeneralPage />,
					},
					{
						path: "edit",
						element: <StoreEventEditPage />,
					},
					{
						path: "sales",
						element: <SalesSearchPage />,
					},
					{
						path: "sales/create",
						element: <SalesCreatePage />,
					},
					{
						path: "sales/:saleId",
						element: <SalesDetailsPage />,
					},
					{
						path: "returns",
						element: <ReturnsPage />,
					},
					{
						path: "return",
						element: <ReturnCreatePage />,
					},
					{
						path: "return/:returnId",
						element: <ReturnDetailsPage />,
					},
				],
			},
			{
				path: "/seller/sales-report",
				element: <SellerSalesReportPage />,
			},
			// {
			// 	path: "/checkout-counter/search",
			// 	element: <CheckoutCounterSearchPage />,
			// },
			// {
			// 	path: "/checkout-counter/:storeId/:id",
			// 	element: <CheckoutCounterDetailsPage />,
			// },			
			{
				path: "/sales/search",
				element: <SaleSearchPage />,
			},	
			{
				path: "/sales/:saleId",
				element: <SalesDetailsPage />,
			},
			{
				path: "/store/:storeId",
				element: <StoreIndexPage />,
				children: [
					{
						index: true,
						element: <StoreGeneralPage />,
					},
					{
						path: "checkout-counter/search",
						element: <CheckoutCounterSearchPage />,
					},
					{
						path: "checkout-counter/:id",
						element: <CheckoutCounterDetailsPage />,
					},	
					{
						path: "sales",
						element: <StoreSalesSearchPage />,
					},
					{
						path: "sales/create",
						element: <StoreSalesCreatePage />,
					},
					{
						path: "sales/:saleId",
						element: <SalesDetailsPage />,
					},
					{
						path: "returns",
						element: <ReturnsPage />,
					},
					{
						path: "sales/:saleId/return",
						element: <StoreSalesReturnCreatePage />,
					},
					{
						path: "return/:returnId",
						element: <ReturnDetailsPage />,
					},
				],
			},
		],
	},
	{
		path: "/login",
		errorElement: <ErrorPage />,
		element: <Login />,
	},
	{
		path: "/logout",
		errorElement: <ErrorPage />,
		element: <LogoutPage />,

	},
]);

export default routes;
