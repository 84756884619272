import { Box, Button, TextField } from '@mui/material'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import CPFMask from 'components/CPFMask'
import EmployeesAutoComplete from 'components/Employee/EmployeesAutoComplete'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SalesList from 'components/StoreEvent/Sales/SalesList'
import storeSalesService from 'services/store-sales.service'
import { useAppHooks } from 'hooks/useAppHooks'

const defaultDays = 7;
const SalesSearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId } = useParams();
  const [sales, setSales] = useState([]);
  const { withLoading, message } = useAppHooks();

  const handleSearchFilter = (filters) => {
    if (!storeId) {
      message.error('Selecione uma loja para realizar a busca.')
      return
    }
    // To save the history
    //navigate(`/store/${storeId}/sales?startDate=${filters.startDate}&endDate=${filters.endDate}&cpf=${filters.cpf}&name=${filters.name}&couponNumber=${filters.couponNumber}`);

    // Create a new URLSearchParams instance
    const params = createSearchParams({
      startDate: filters.startDate,
      endDate: filters.endDate,
      cpf: filters.cpf,
      name: filters.name,
      couponNumber: filters.couponNumber,
      seller: filters.seller,
    }); 

    // To save the history
    navigate(`/store/${storeId}/sales?${params.toString()}`);
    
  }

  const handleSearch = (filters) => {
    if (!storeId) {
      message.error('Selecione uma loja para realizar a busca.')
      return
    }

    withLoading(async () => {

      //set end date to 23:59:59
      if (filters.endDate) {
        filters.endDate = `${filters.endDate}T23:59:59`
      }

      var sales = await storeSalesService.search(storeId, filters.startDate, filters.endDate, filters.cpf, filters.name, filters.couponNumber, filters.seller)
      setSales(sales);
      
    }).catch((error) => {
      message.error('Ocorreu um erro ao buscar as vendas.', error)
    })
  }
  
  const saleClickHandler = (sale) => {
    if (sale.storeEventId && sale.storeEventId.length > 0) {
      navigate(`/store-event/${sale.storeEventId}/sales/${sale.id}`)
    } else {
      navigate(`/store/${sale.storeId}/sales/${sale.id}`)
    }
  }
  
  const urlParams = new URLSearchParams(location.search);
  let startDate = urlParams.get('startDate');
  let endDate = urlParams.get('endDate');
  const cpf = urlParams.get('cpf');
  const name = urlParams.get('name');
  const couponNumber = urlParams.get('couponNumber');
  const seller  = urlParams.get('seller');

  useEffect(() => {

    handleSearch({
      startDate,
      endDate,
      cpf,
      name,
      couponNumber,
      seller
    });
  }, [startDate, endDate, cpf, name, couponNumber, storeId, seller])


  if (!storeId) return null;


  return (
    <div>
      <SalesFilter onSearch={handleSearchFilter} storeId={storeId} startDate={startDate} endDate={endDate} cpf={cpf} name={name} couponNumber={couponNumber} seller={seller} />

      <br />
      <SalesList sales={sales} onClick={saleClickHandler} />
      <br />
      <br />
    </div>
  )
}

const SalesFilter = ({ onSearch, storeId, startDate, endDate, cpf, name, couponNumber, seller }) => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    startDate: startDate || '',
    endDate: endDate || '',
    cpf: cpf || '',
    name: name || '',
    couponNumber: couponNumber || '',
    seller: seller || ''
  })

  const handleFilterChange = (event) => {
    const { name, value } = event.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }
  const handleSellerchange = (s) => {
    setFilters({
      ...filters,
      seller: s?.id
    })
  }

  const handleSearch = (event) => {
    event.preventDefault()
    onSearch(filters)
  }

  
  useEffect(() => {

    //if we don't have any search params, we will use the default days
    if (!filters.startDate && !filters.endDate && !filters.cpf && !filters.name && !filters.couponNumber && !filters.seller) {
      const today = new Date();
      endDate = today.toISOString().split('T')[0];
      today.setDate(today.getDate() - defaultDays);
      startDate = today.toISOString().split('T')[0];

      const updatedFilters = {
        ...filters,
        startDate,
        endDate,
      }
  
      setFilters(updatedFilters);
      onSearch(updatedFilters);
    }

  }, [])

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12} md={4}>
        <TextField fullWidth label="Data inicial" name="startDate" type="date" InputLabelProps={{ shrink: true }} onChange={handleFilterChange} value={filters.startDate} />
      </Grid2>
      <Grid2 xs={12} md={4}>
        <TextField fullWidth label="Data final" name="endDate" type="date" InputLabelProps={{ shrink: true }} onChange={handleFilterChange} value={filters.endDate} />
      </Grid2>
      <Grid2 xs={12} md={4}>
        <CPFMask fullWidth label="CPF do cliente" name="cpf" onChange={handleFilterChange} value={filters.cpf} />
      </Grid2>
      <Grid2 xs={12} md={3}>
        <TextField fullWidth label="Nome do cliente" name="name" onChange={handleFilterChange} value={filters.name} />
      </Grid2>
      <Grid2 xs={12} md={3}>
        <TextField fullWidth label="Número do cupom" name="couponNumber" onChange={handleFilterChange} value={filters.couponNumber} />
      </Grid2>
      <Grid2 xs={12} md={3}>
        <EmployeesAutoComplete
                      name="seller"
                      title="Vendedor"
                      onChange={handleSellerchange}
                      selected={filters.seller}    />
      </Grid2>
      <Grid2 xs={12} md={3}>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Button variant="contained" onClick={handleSearch}>
            Pesquisar
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default SalesSearchPage
