import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import AlertSnackbarWrapper from 'components/Layout/AlertSnackbarWrapper';
import { authenticate } from "../../store/actions/auth-actions";
import classes from './Login.module.css';
import { useAppHooks } from "hooks/useAppHooks";
import { useEffect } from "react";
import { useForm } from 'react-hook-form';

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { withLoading, message } = useAppHooks();
    const returnUrl = new URLSearchParams(location.search).get('returnUrl');
    //const [returnUrl, setReturnUrl] = useState(location.state.from || '/');
    const { register, handleSubmit, formState: { errors } } = useForm();
    //const history = useHistory();
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);
    //const authService = new AuthService();
    //const { loading, showSnackbar } = React.useContext(AuthContext);
    useEffect(() => {
      const refresh = new URLSearchParams(location.search).get('refresh');
      if (refresh) {
        window.location.href = '/auth/login';
      }
  
    }, []);

    useEffect(() => {
      if (isLoggedIn) {
        navigate(returnUrl);
      }
    }, [isLoggedIn]);
  
    const onSubmit = async (data) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
  

      withLoading(async () =>{
        const { username, password } = data;
        await dispatch(authenticate({username, password}));
        navigate(returnUrl);
      }).catch((error) => {
        message.error('Não foi possível concluir a autenticação', error);
      });

    };
  
    return (
      <div className={classes.window}>
        <img src="/assets/img/logo.svg" alt="Logo" className={classes.logo} />
        <h6>Informe seu usuário e senha para prosseguir.</h6>
  
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className={classes.formControlGroup}
            label="E-mail"
            placeholder="Informe seu e-mail"
            name="username"
            autoComplete="off"
            error={!!errors.username}
            helperText={errors.username && 'Informe seu e-mail ou id de usuário'}
            {...register('username', { required: true })}
          />
  
          <TextField
            className={classes.formControlGroup}
            label="Senha"
            placeholder="Informe sua senha"
            type="password"
            name="password"
            autoComplete="off"
            error={!!errors.password}
            helperText={errors.password && 'Informe sua senha'}
            {...register('password', { required: true })}
          />
  
            <Button variant="contained" type="submit">Login</Button>
        </form>
      </div>
    );
  };

  export default Login;