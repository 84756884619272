import { Alert, Box, Button, Divider, Drawer, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography, styled } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'

import CurrencyInput from 'components/Layout/CurrencyInput'
import DeleteIcon from '@mui/icons-material/Delete'
import couponService from 'services/coupon.service'
import giftCardService from 'services/gift-card.service'
import settings from 'settings.js'
import storeService from '../../services/store.service'
import { useAppHooks } from 'hooks/useAppHooks'
import { useTheme } from '@emotion/react'

const PaymentSelection2 = (props) => {
	const defaultPaymentMethods = [
		{ id: 'SALES_RETURN', name: settings.returnInstallment },
		{ id: 'GIFT_CARD', name: 'Vale presente' },
	]

	const { installments = [], coupons = [], giftCards = [], extraValue = 0, justify = '', onUpdated, storeId, productsTotal, coupon } = props
	const theme = useTheme()
	const [paymentMethods, setPaymentMethods] = useState(defaultPaymentMethods)
	const [paymentMethod, setPaymentMethod] = useState(null)
	const [showExtraValue, setShowExtraValue] = useState(false)
	const [installmentValue, setInstallmentValue] = useState([])
	const [code, setCode] = useState('')

	const total = productsTotal + extraValue

	console.log('payment selection', props.installments)

	const calculate = (installments, coupons, giftCards, extraValue) => {
		const couponsSum = +coupons.reduce((amount, item) => amount + item.value, 0).toFixed(2)
		const installmentsSum = +installments.reduce((amount, installment) => amount + installment.value, 0).toFixed(2)
		const giftCardsSum = +giftCards.reduce((amount, giftCard) => amount + giftCard.value, 0).toFixed(2)

		const extra = +(extraValue?.toFixed(2) ?? 0)
		let remaining = +Math.max(productsTotal + extra - giftCardsSum, 0).toFixed(2)

		const couponChange = +Math.max(couponsSum - remaining, 0).toFixed(2)

		remaining = +Math.max(remaining - couponsSum, 0).toFixed(2)

		const installmentsChange = +Math.max(installmentsSum - remaining, 0).toFixed(2)

		remaining = +Math.max(remaining - installmentsSum, 0).toFixed(2)

		return { remaining, couponChange, installmentsChange, couponsSum, installmentsSum, giftCardsSum }
	}

	const { remaining, couponChange, installmentsChange, couponsSum, installmentsSum, giftCardsSum } = calculate(installments, coupons, giftCards, extraValue)

	const [value, setValue] = useState(remaining)

	const { withLoading, message, formatter } = useAppHooks()

	const isSaleReturnMethod = paymentMethod && paymentMethod.id === 'SALES_RETURN'
	const isGiftCardMethod = paymentMethod && paymentMethod.id === 'GIFT_CARD'
	const isDefaultPaymentMethod = isSaleReturnMethod || isGiftCardMethod
	const hasPaymentAdded = installments.length > 0 || coupons.length > 0 || giftCards.length > 0 || extraValue != 0

	useEffect(() => {
		setValue(remaining)
	}, [remaining])

	useEffect(() => {
		withLoading(async () => {
			const response = await storeService.paymentMethods(storeId)
			const options = [...response.filter((x) => x.name != settings.returnInstallment), ...defaultPaymentMethods].sort((a, b) => a.name.localeCompare(b.name))
			setPaymentMethods(options)
		}).catch((error) => {
			message.error('Erro ao carregar formas de pagamento', error)
		})
	}, [])

	useEffect(() => {
		if (coupon && coupons.length === 0) {
			AddSaleReturnHandler(coupon)
		}
	}, [coupon])

	const paymentMethodChangeHandler = (event) => {
		const method = paymentMethods.find((p) => p.id === event.target.value)

		if (!method) {
			return
		}

		setPaymentMethod(method)
		setInstallmentValue(1)
	}

	const pressEnterHandler = (event) => {
		if (event.key === 'Enter') {
			addHandler()
		}
	}

	const valueChangedHandler = (event) => {
		const temp = parseCurrency(event.target.value)
		setValue(temp)
	}

	const parseCurrency = (value) => {
		if (typeof value === 'number') {
			return +value.toFixed(2)
		}

		let temp = value.replace(/\D/g, '') // Remove non-digit characters
		temp = parseFloat(temp / 100).toFixed(2)
		return +temp
	}

	const installmentsAvailable = []

	if (paymentMethod) {
		for (let i = 1; i <= paymentMethod.maxInstallments; i++) {
			installmentsAvailable.push(i)
		}
	}

	const raiseUpdated = (updated) => {
		let arg = { installments, coupons, giftCards, extraValue, justify, ...updated }

		arg.remaining = calculate(arg.installments, arg.coupons, arg.giftCards, arg.extraValue).remaining

		console.log('raise updated', arg)
		onUpdated(arg)
	}

	const removeInstallmentHandler = (installment) => {
		const updatedInstallments = installments.filter((i) => i !== installment)
		raiseUpdated({ installments: updatedInstallments })
	}
	const removeCouponHandler = (coupon) => {
		const updatedCoupons = coupons.filter((c) => c.id !== coupon.id)
		raiseUpdated({ coupons: updatedCoupons })
	}
	const removeGiftCardHandler = (giftCard) => {
		const updatedGiftCards = giftCards.filter((c) => c.id !== giftCard.id)
		raiseUpdated({ giftCards: updatedGiftCards })
	}

	const addHandler = () => {
		if (!paymentMethod) {
			return
		}

		if (isSaleReturnMethod) AddSaleReturnHandler(code)
		else if (isGiftCardMethod) AddGiftCardHandler(code)
		else AddInstallmentHandler()
	}

	const AddInstallmentHandler = () => {
		if (value === 0) {
			return
		}

		const installment = {
			id: paymentMethod.id,
			externalId: paymentMethod.externalId,
			name: paymentMethod.name,
			installments: installmentValue,
			value: parseCurrency(value),
		}
		const updatedInstallments = [...installments, installment]
		raiseUpdated({ installments: updatedInstallments })
		setPaymentMethod(null)
	}

	const AddSaleReturnHandler = (code) => {
		if (code === '') {
			return
		}

		withLoading(async () => {
			var response = await couponService.get(code)
			if (coupons.find((c) => c.id === response.id)) {
				message.error('Cupom já adicionado')
				return
			}

			if (response.alreadyUsed) {
				message.error('Cupom já utilizado em ' + formatter(response.usedAt, 'dateTime'))
				return
			}

			if (response.expired) {
				message.error('Cupom expirado')
				return
			}

			const couponsUpdated = [...coupons, response]
			raiseUpdated({ coupons: couponsUpdated })
			setCode('')
		}).catch((error) => {
			if (error.response.status === 404) {
				message.error('Cupom não encontrado')
			} else {
				message.error('Erro ao tentar carregar o cupom', error)
			}
		})
	}

	const AddGiftCardHandler = (code) => {
		if (code === '') {
			return
		}

		withLoading(async () => {
			var response = await giftCardService.get(code)
			if (giftCards.find((c) => c.id === response.id)) {
				message.error('Vale presente já adicionado')
				return
			}

			if (response.alreadyUsed) {
				message.error('Vale presente já utilizado em ' + formatter(response.usedAt, `dateTime`))
				return
			}

			const giftCardUpdated = [...giftCards, response]
			raiseUpdated({ giftCards: giftCardUpdated })
			setCode('')
		}).catch((error) => {
			if (error.response.status === 404) {
				message.error('Vale presente não encontrado')
			} else {
				message.error('Erro ao tentar carregar o vale presente', error)
			}
		})
	}

	const onExtraValueHandler = (e) => {
		raiseUpdated({ extraValue: e.value, justify: e.justify })
	}

	const MoneyBox = styled(Box)(({ theme, light }) => ({
		'&': {
			padding: `${theme.spacing(1)} ${theme.spacing(4)}}`,
		},
		'& label': {
			fontWeight: 'bold',
			marginRight: theme.spacing(1),
			display: 'block',
		},
	}))

	const CurrencySpan = styled('span')(({ theme, negative }) => ({
		'&': {
			fontSize: '1.2rem',
			fontWeight: 'bold',
			color: negative ? theme.palette.error.main : theme.palette.success.main,
		},
	}))

	const PaymentResume = styled(Stack)(({ theme }) => ({
		direction: 'row',
		justifyContent: 'right',
		flexDirection: 'row',
		borderRadius: '0 0 8px 8px',
		gap: 2,
		'&': {
			background: theme.palette.grey['100'],
			padding: `${theme.spacing(1)}`,
			margin: `${theme.spacing(2)} 0`,
		},
	}))

	return (
		<Stack direction="column">
			<Stack direction="row" gap={2}>
				<Stack direction="column" spacing={2} sx={{ mb: 1, flex: '0 0 33%' }}>
					<FormControl fullWidth>
						<InputLabel>Forma de pagamento</InputLabel>
						<Select value={paymentMethod?.id || ''} label="Informe a forma de pagamento" onChange={paymentMethodChangeHandler}>
							{paymentMethods.map((method) => (
								<MenuItem key={method.id} value={method.id}>
									{method.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{installmentsAvailable.length > 1 && (
						<FormControl fullWidth>
							<InputLabel>Parcelas</InputLabel>
							<Select value={installmentValue} onChange={(event) => setInstallmentValue(event.target.value)} label="Informe a quantidade de parcelas">
								{installmentsAvailable.map((installment) => (
									<MenuItem key={installment} value={installment}>
										{installment}x
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{isDefaultPaymentMethod && <TextField fullWidth label="Código" variant="outlined" value={code} onChange={(e) => setCode(e.target.value)} onKeyUp={pressEnterHandler} />}
					{!isDefaultPaymentMethod && <TextField fullWidth label="Valor" variant="outlined" value={value === '' ? '' : formatter(value, 'currency')} onChange={valueChangedHandler} onKeyUp={pressEnterHandler} />}

					<Button variant="contained" color="primary" onClick={addHandler} size="medium" disabled={remaining <= 0}>
						Adicionar
					</Button>
					<PaymentExtraValue value={extraValue} justify={justify} onAdd={onExtraValueHandler} />
				</Stack>

				{!hasPaymentAdded && (
					<Alert severity="warning" sx={{ width: '100%' }}>
						Nenhuma forma de pagamento adicionada.
					</Alert>
				)}
				{hasPaymentAdded && (
					<TableContainer component={Paper}>
						<Table sx={{ height: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell>Forma de pagamento</TableCell>
									<TableCell>Parcelas</TableCell>
									<TableCell>Valor</TableCell>
									<TableCell width={5}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{installments.map((installment, index) => (
									<TableRow key={`parcela_${index}`}>
										<TableCell>{installment.name}</TableCell>
										<TableCell>{installment.installments}x</TableCell>
										<TableCell>{formatter(installment.value, 'currency')}</TableCell>
										<TableCell>
											<IconButton onClick={() => removeInstallmentHandler(installment)} size="small">
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
								{coupons.map((coupon, index) => (
									<TableRow key={`coupon_${index}`}>
										<TableCell>Cupom de desconto</TableCell>
										<TableCell></TableCell>
										<TableCell>{formatter(coupon.value, 'currency')}</TableCell>
										<TableCell>
											<IconButton onClick={() => removeCouponHandler(coupon)} size="small">
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
								{giftCards.map((giftCard, index) => (
									<TableRow key={`coupon_${index}`}>
										<TableCell>Vale Presente</TableCell>
										<TableCell></TableCell>
										<TableCell>{formatter(giftCard.value, 'currency')}</TableCell>
										<TableCell>
											<IconButton onClick={() => removeGiftCardHandler(giftCard)} size="small">
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
								{extraValue != 0 && (
									<>
										<TableRow sx={{ background: theme.palette.grey[100] }}>
											<TableCell>{extraValue > 0 ? 'Acréscimo' : 'Desconto'}</TableCell>
											<TableCell></TableCell>
											<TableCell>{formatter(extraValue, 'currency')}</TableCell>
											<TableCell>
												<IconButton onClick={() => onExtraValueHandler({ extraValue: 0, justify: '' })} size="small">
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableRow sx={{ background: theme.palette.grey[100] }}>
											<TableCell colSpan={4}>
												<Typography variant="caption" sx={{ fontWeight: 'bold' }}>
													Justificativa:{' '}
												</Typography>
												<Typography variant="caption">{justify}</Typography>
											</TableCell>
										</TableRow>
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Stack>

			<PaymentResume>
				<MoneyBox>
					<label>Total:</label>
					<CurrencySpan>{formatter(total, 'currency')}</CurrencySpan>
				</MoneyBox>

				{remaining > 0 && (
					<>
						<Divider orientation="vertical" flexItem />

						<MoneyBox>
							<label>À Pagar:</label>
							<CurrencySpan negative={true}>{formatter(Math.abs(remaining), 'currency')}</CurrencySpan>
						</MoneyBox>
					</>
				)}
				{couponChange > 0 && (
					<>
						<Divider orientation="vertical" flexItem />

						<MoneyBox>
							<label>Saldo Cupom:</label>
							<CurrencySpan>{formatter(couponChange > 0 ? Math.abs(couponChange) : 0, 'currency')}</CurrencySpan>
						</MoneyBox>
					</>
				)}
				{installmentsChange > 0 && (
					<>
						<Divider orientation="vertical" flexItem />

						<MoneyBox>
							<label>Troco:</label>
							<CurrencySpan negative={true}>{formatter(installmentsChange, 'currency')}</CurrencySpan>
						</MoneyBox>
					</>
				)}
			</PaymentResume>
		</Stack>
	)
}

export default PaymentSelection2

const PaymentExtraValue = (props) => {
	const { handleSubmit, control, formState, getValues } = useForm()

	const { errors } = formState;
	const [showExtraValue, setShowExtraValue] = useState(false);

	const initialValue = {
		value: props.value,
		justify: props.justify,
		multiplyFactor: props.value > 0 ? 1 : props.value < 0 ? -1 : '',
	}
	// const valueRef = useRef(props.value < 0 ? props.value * -1 : props.value)
	// const [justify, setJustify] = useState(props.justify)
	// const [multiplyFactor, setMultiplyFactor] = useState(props.value > 0 ? 1 : props.value < 0 ? -1 : '')

	const options = [
		{ value: -1, label: 'Desconto' },
		{ value: 1, label: 'Acréscimo' },
	]

	const onSubmit = (data) => {
		const query = getValues();
		console.log('on submit', query);
		props.onAdd({
			value: +query.value * +query.multiplyFactor,
			justify: query.justify,
		})

		setShowExtraValue(false)
	}

	return (
		<>
			<Button variant="outlined" color="primary" sx={{ fontSize: '12px' }} onClick={() => setShowExtraValue(true)} size="medium">
				Adicionar desconto ou acréscimo
			</Button>

			<Drawer anchor="right" open={showExtraValue} onClose={() => setShowExtraValue(false)} sx={{ width: '80% !important' }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Stack direction="column" gap={2} sx={{ p: 3 }}>
						<Typography variant="h6" sx={{ mb: 1 }}>
							Adicionar desconto ou acréscimo
						</Typography>

						<FormControl fullWidth>
							<InputLabel>Acréscimo ou Desconto</InputLabel>

							<Controller
								name="multiplyFactor"
								control={control}
								defaultValue={initialValue.multiplyFactor}
								rules={{ required: true, validate: (value) => value === 1 || value === -1 }}
								render={({ field }) => (
									<Select {...field} label="Selecione a opção" error={!!errors.multiplyFactor}>
										{options.map((i) => (
											<MenuItem key={i.value} value={i.value}>
												{i.label}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>

						<Controller
							name="value"
							control={control}
							defaultValue={initialValue.value} 
							rules={{ required: true, min: 0.01 }}
							render={({ field }) => 
								<CurrencyInput {...field} 
									label="Valor" 
									variant="outlined" 
									error={!!errors.value} 
									defaultValue={initialValue.value} 
									helperText={errors.value?.message} />
							}
						/>
						

						<Controller
							name="justify"
							control={control}
							defaultValue={initialValue.justify}
							rules={{ required: true }}
							render={({ field }) => <TextField {...field} fullWidth label="Justificativa" variant="outlined" multiline rows={4} error={!!errors.justify} helperText={errors.justify?.message} />}
						/>


						<Button variant="contained" color="primary" type="submit" size="medium">
							Adicionar
						</Button>
					</Stack>
				</form>
			</Drawer>
		</>
	)
}
