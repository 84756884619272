import { useEffect, useMemo, useState } from "react";

const useTimeFormatter = (date) => {
	const formatDateTime = useMemo(() => {
		const options2 = {
			hour12: false,
			hour: "2-digit",
			minute: "2-digit",
		};

		return (date) => {

            var dateToFormat = date;
            if(typeof date === 'string') {
                dateToFormat = new Date(date);
            }


			const formattedTime = dateToFormat.toLocaleTimeString("pt-BR", options2);
			return `${formattedTime}`;
		};
	}, []);

	return formatDateTime;
};

export default useTimeFormatter;
