import { TextField } from '@mui/material';
import React, { Children, useState } from 'react';
import InputMask from '@mona-health/react-input-mask'
import MaskedInput from 'react-text-mask';

const PhoneInput = React.forwardRef(({ className, children, ...props }, ref) => {
  const [mask, setMask] = useState("(99) 99999-9999");

  return (
    
    <InputMask
        {...props}
        ref={ref}
        mask={mask}
        onBlur={e => {
        if (e.target.value.replace("_", "").length === 14) {
          setMask("(99) 9999-9999");
        }
      }}
      onFocus={e => {
        if (e.target.value.replace("_", "").length === 14) {
          setMask("(99) 99999-9999");
        }
      }}
    >
        <TextField
         />
    </InputMask>
  );
});

export default PhoneInput;