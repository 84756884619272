import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Box, FormControlLabel, FormGroup, FormLabel, IconButton, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import AdditionalServicePendingList from 'components/StoreEvents/AdditionalServicePendingList'
import AdditionalServicesList from 'components/StoreEvents/AdditionalServicesList'
import CalendarDisplay from 'components/StoreEvents/CalendarDisplay'
import ExpiredEventList from 'components/StoreEvents/ExpiredEventList'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import PaymentPendingEventList from 'components/StoreEvents/PaymentPendingEventList'
import PendingList from 'components/Return/Pending/PendingList'
import PostponedEventList from 'components/StoreEvents/PostponedEventList'
import monthNames from 'models/MothNames'
import { useHasRole } from 'hooks/useHasRole'

const DashboardPage = () => {
    const isManager = useHasRole(["Administrator", "Manager"]); // Initialize your RoleService
	const location = useLocation();
	const params = new URLSearchParams(location.search)
	const month = +(params.get('month') || new Date().getMonth())
	const year = +(params.get('year') || new Date().getFullYear())

	const startDate = new Date(year, month, 1)
	const endDate = new Date(year, month + 1, 0, 23, 59, 59, 999)

	const navigate = useNavigate()

	const loadCalendar = (month, year) => {
		const params = createSearchParams({
			month,
			year,
		})

		navigate(`/?${params.toString()}`)
	}

	const openStoreEventHandler = (storeEvent) => {
		navigate(`/store-event/${storeEvent.id}`)
	}

	const returnItemClickHandler = (saleReturn) => {
		if (saleReturn.storeEventId) {
			navigate(`/store-event/${saleReturn.storeEventId}/return/${saleReturn.id}`)
		} else {
			navigate(`/store/${saleReturn.storeId}/return/${saleReturn.id}`)
		}
	}

	return (
		<Grid2 container sx={{m: 1}} spacing={1}>
			<Grid2 xs={12} md={8}>
				<Stack direction="row" alignItems="center" justifyContent="center">
					<IconButton color="primary" onClick={() => (month === 0 ? loadCalendar(11, year - 1) : loadCalendar(month - 1, year))}>
						<ArrowLeft />
					</IconButton>
					<Typography variant="h6">
						{monthNames[month]} <small>{year}</small>
					</Typography>
					<IconButton color="primary" onClick={() => (month === 11 ? loadCalendar(0, year + 1) : loadCalendar(month + 1, year))}>
						<ArrowRight />
					</IconButton>
				</Stack>
				<CalendarDisplay month={month} year={year} ></CalendarDisplay>
			</Grid2>
			<Grid2 xs={12} md={4} sx={{paddingTop: 4}}>
				{isManager && (
					<PendingList onClick={returnItemClickHandler}></PendingList>
				)}

				<AdditionalServicesList onClick={openStoreEventHandler}></AdditionalServicesList>
			</Grid2>
			<Grid2 xs={12} md={4}>
				<ExpiredEventList onClick={openStoreEventHandler}></ExpiredEventList>
			</Grid2>
			<Grid2 xs={12} md={4}>
				<PaymentPendingEventList onClick={openStoreEventHandler}></PaymentPendingEventList>
			</Grid2>
			<Grid2 xs={12} md={4}>
				<AdditionalServicePendingList onClick={openStoreEventHandler}></AdditionalServicePendingList>
			</Grid2>
			<Grid2 xs={12} md={4}>
				<PostponedEventList onClick={openStoreEventHandler}></PostponedEventList>
			</Grid2>
			

		</Grid2>
	)
}

export default DashboardPage
