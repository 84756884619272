import { Box, Button, Drawer, IconButton, Link, MenuItem, Paper, Select, Stack, TextField, Typography, styled } from "@mui/material";
import React, { useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import EmployeesAutoComplete from "components/Employee/EmployeesAutoComplete";
import { EventStatusText } from "models/EventStatus";
import { setEvent } from "store/slice/store-event.slice";
import storeEventService from "services/store-event.service";
import { useAppHooks } from "hooks/useAppHooks";
import { useDispatch } from "react-redux";
import useFormatter from "hooks/useFormatter";

const Container = styled(Stack)({
  background: '#e6e6e6',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: '1',
});

const Title = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.tertiary.main,
}));

const CustomBox = styled(Box)({
  wordWrap: 'break-word',
  padding: "5px 15px",
});

const SmallText = styled('span')({
  fontSize: '80%',
  fontWeight: 400,
});

const WhatsAppLink = styled(Link)({
  color: '#039903',
  textDecoration: 'none',
  fontSize: '80%',
});

const Tag = styled('small')(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '10px',
  color: theme.palette.primary.contrastText,
  padding: '0 5px',
}));

const SmallLink = styled(Link)({
  fontSize: '80%',
  fontWeight: 400,
  textDecoration: 'none',
  color: '#039903', // Se você tiver uma cor específica para os links
});

const TagSize = styled('span')({
  background: '#053766',
  color: 'white',
  borderRadius: '6px',
  padding: '3px 8px',
  fontSize: '12px',
  marginRight: '5px',
});


function StoreEventSummary({ storeEvent: s }) {
  const { withLoading, message } = useAppHooks();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const cleanPhone = (phone) => {
    return phone
      ?.replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/ /g, "")
      .replace(/-/g, "");
  };

  const [storeEvent, setStoreEvent] = useState(s);
  const eventDate = new Date(storeEvent.eventDate);
  const eventStartAt = new Date(storeEvent.eventStartAt);
  const eventEndAt = new Date(storeEvent?.eventEndAt);
  const weekDay= eventDate.toLocaleDateString("pt-BR", {
    weekday: "long",
  });

  console.log('storeEvent', storeEvent);

  const updateDataHandler = (field, value) => {
    
    withLoading(async () =>{
      const data = await storeEventService.updateData(storeEvent.id, field, value);     

      dispatch(setEvent(data));
      
    }).catch((error) => {
      message.error('Não foi possível realizar a atualização', error);
    });

  };

  return (
    <Container>
      <Title>
        <Typography variant="h3" gutterBottom>
          Chá da {storeEvent?.bridge?.name?.split(" ")[0]}
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          {`${eventDate.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}`}
          <br />
          {`${eventStartAt.toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
          })} às ${eventEndAt.toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
          })}`}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}          
        </Typography>
        <Tag className="mt-4">{EventStatusText[storeEvent.status]}</Tag>
      </Title>


      <CustomBox>
        <Typography>
          <strong>Loja:</strong>
          <br />
          <span className="store">{storeEvent.store.name}</span>
        </Typography>
      </CustomBox>

      <CustomBox>
        <Typography>
          <strong>Data limite troca:</strong>
          <br />
          <span className="store">{formatter(storeEvent.returnLimit, 'date')}</span>
        </Typography>
      </CustomBox>

      <CustomBox>
        {storeEvent?.assignee?.name && (
          <Typography>
            <strong>Quem fechou o contrato:</strong>
            <br />
            <span className="assignee">{storeEvent.assignee.name}</span>
          </Typography>
        )}
      </CustomBox>

      <CustomBox>
        <Typography>
          <strong>Contratante</strong>
          <br />
          <span className="contractor">{storeEvent.contractorName}</span>
          <br />
          <SmallText>{storeEvent.contractorMail}</SmallText>
          <br />
          <WhatsAppLink
            href={`https://api.whatsapp.com/send?phone=55${cleanPhone(
              storeEvent.contractorPhone
            )}&text=`}
            target="_blank"
          >
            {storeEvent.contractorPhone}
          </WhatsAppLink>
        </Typography>
      </CustomBox>
      {storeEvent?.bridge?.name && (
        <CustomBox>
          <Typography>
            <strong>Noiva</strong>
            <br />
            <span className="bridge">{storeEvent.bridge.name}</span>
            <br />
            <WhatsAppLink
              href={`https://api.whatsapp.com/send?phone=55${cleanPhone(
                storeEvent.bridge.phone
              )}&text=`}
              target="_blank"
            >
              {storeEvent.bridge.phone}
            </WhatsAppLink>
          </Typography>
        </CustomBox>
      )}
      <EditBox title="Tamanho da noiva" field="bridgeBraSize" value={storeEvent.bridgeBraSize} inputType='lingerie-sizes' onSave={updateDataHandler}>
        {storeEvent?.bridgeBraSize && (
          <>
          Sutiã <TagSize>{storeEvent.bridgeBraSize}</TagSize>
          </>          
        )}
        {!storeEvent?.bridgeBraSize && (
          <span className="bridgeBraSize">--</span>
        )}        
      </EditBox>
      
      <EditBox title="Tamanho da noiva" field="bridgePantySize" value={storeEvent.bridgePantySize} inputType='lingerie-sizes' onSave={updateDataHandler}>
        {storeEvent?.bridgePantySize && (
          <>
          Calcinha <TagSize>{storeEvent.bridgePantySize}</TagSize>
          </>
        )}
        {!storeEvent?.bridgePantySize && (
          <span className="bridgePantySize">--</span>
        )}
      </EditBox>

      <EditBox title="Número de convidadas" field="numberOfGuests" value={storeEvent.numberOfGuests} inputType='number' onSave={updateDataHandler}>
      </EditBox>


      <EditBox title="Link do chá" field="onlineStoreUrl" value={storeEvent.onlineStoreUrl} inputType='text' onSave={updateDataHandler}>        
          {storeEvent.onlineStoreUrl && (
            <SmallLink
                href={storeEvent.onlineStoreUrl}
                target="_blank"
              >
                {storeEvent.onlineStoreUrl}
            </SmallLink>
          )}
      </EditBox>

      <EditBox title="Grupo no WhatsApp" field="whatsAppGroup" value={storeEvent.whatsAppGroup} inputType='text' onSave={updateDataHandler}>        
          {storeEvent.whatsAppGroup && (
            <WhatsAppLink
              href={storeEvent.whatsAppGroup}
              target="_blank"
            >
              {storeEvent.whatsAppGroup}
            </WhatsAppLink>
          )}
      </EditBox>

      <EditBox title="Palestrante" field="speaker" value={storeEvent.speaker.name} inputType='employee' onSave={updateDataHandler}></EditBox>

      <EditBox title="Assistentes" field="assistants" value={storeEvent.assistants} inputType='employee-list' onSave={updateDataHandler}>
        {storeEvent.assistants && storeEvent.assistants.map((assistant) => (
          <Typography key={assistant.id}>
            {assistant.name}
          </Typography>
        ))}
        {!storeEvent.assistants || storeEvent.assistants.length === 0 && (
           <span className="assistants">--</span>
        )}
      </EditBox>

    </Container>
  );
}

export default StoreEventSummary;




const EditBox = ({title, field, value, inputType, onSave, children}) => {

  const valueRef = React.useRef(value);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  let displayValue = value || (Array.isArray(value) && value.length > 0) ? value : '--';
  
  const handleSave = () => {
    onSave(field, valueRef.current);
    handleDrawerClose();
  };

  const GetCustomizedInput = (inputType) => {
    switch (inputType) {
      case 'employee':
        return (
          <EmployeesAutoComplete
                        name={field}
                        onChange={(e) => valueRef.current = e.id}
                        selected={value} />
        );
      case 'employee-list':
        return (
          <EmployeesAutoComplete
              multiple
              name={field}
              onChange={(e) => valueRef.current = e.map((item) => item.id).join(',')}
              selected={value} />
        );
        
      case 'lingerie-sizes':
        return (
          <Select 
            fullWidth
            value={value} 
            onChange={(e) => { valueRef.current = e.target.value }}
          >
            {['P', 'M', 'G', 'GG', '48', '50', '52'].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        );
      default:
          return (  
            <TextField
              fullWidth
              type={inputType || 'text'}
              defaultValue={value}
              onChange={(e) => { valueRef.current = e.target.value }}
              variant="outlined"
              margin="normal"
            />
          );
    }
  }

  return (
    <>
      <CustomBox 
        sx={(theme) => ({
          cursor: 'pointer', 
          position: 'relative',
          ":hover": { 
            background: theme.palette.grey[100],
            "& button": {
              opacity: 1,
            },
          }
        })}
      >
        <Typography>
          <strong>{title}:</strong>
          <br />
          {children || <span className={field}>{displayValue}</span>}
        </Typography>
        
        <IconButton 
          variant="contained" 
          color="primary"
          size="small"
          onClick={handleDrawerOpen}
          sx={{
            position: 'absolute',
            top: 20,
            right: 10,
            transform: 'translateY(-50%)',
            opacity: 0,
            transition: 'opacity 0.3s',
            zIndex: 2,
          }}
        >
          <EditIcon fontSize="12" />
        </IconButton>
      </CustomBox>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        
        <Box style={{ width: 300, padding: 20 }}>
          <Typography variant="h6" sx={{marginBottom: 3}}>Atualizar informações adicionais:</Typography>
          <Typography variant="body1">{title}</Typography>

          {GetCustomizedInput(inputType)}
          <br />
          <br />
          <Stack direction="row" spacing={2} style={{ marginTop: 20 }} justifyContent="space-between">
            <Button variant="outlined" onClick={handleDrawerClose}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Confirmar
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
