import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./slice/auth-slice";
import globalSlice from "./slice/global-slice";
import storeEventSlice from "./slice/store-event.slice";
import storeSlice from "./slice/store.slice";
// import createSagaMiddleware from "@redux-saga/core";
// import { initializeAlert } from "../sagas/alert-saga";

// const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

const store = configureStore({
    reducer: { 
        global: globalSlice.reducer,
        auth: authSlice.reducer,
        storeEvent: storeEventSlice.reducer,
        stores: storeSlice.reducer,
    },
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(middleware),
});


//sagaMiddleware.run(initializeAlert);

export default store;
