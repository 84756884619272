import { toast } from 'react-toastify';

//create hook that will consume global state to show snackbar
export const useMessage = () => {
    
    return {
        success: (message) => {
            toast.success(message);
            //dispatch(setSnackbar({ message: message, severity: 'success' }));
        },
        error: (message, error) => {

            let m = message;
            if(error && error.response && error.response.data && typeof(error.response.data) === 'string')
            {
                m = error.response.data;
            }
            if(error && error.response && error.response.data && error.response.data.errors && typeof(error.response.data.errors[0]) === 'string')
            {
                m = error.response.data.errors[0];
            }
            
            if(typeof(error) === "string")
            {
                m = error;
            }
            
            toast.error(m);

            //dispatch(setSnackbar({ message: m, severity: 'error' }));
        },
        info: (message) => {
            toast.info(message);
            //dispatch(setSnackbar({ message: message, severity: 'info' }));
        },
        warning: (message) => {
            toast.warning(message);
            //dispatch(setSnackbar({ message: message, severity: 'warning' }));
        },
        close: () => {
            
            //dispatch(closeSnackbar());
        }
    };
}