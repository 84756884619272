import { useEffect, useMemo, useState } from 'react'

import moment from 'moment/moment'

const useDateFormatter = (date) => {
	const formatDateTime = useMemo(() => {
		const options = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}
		const options2 = {
			hour: '2-digit',
			minute: '2-digit',
		}

		return (date) => {
			var dateToFormat = date
			if (typeof date === 'string') {
				if (date.endsWith('Z')) {
					return moment.utc(date).format('DD/MM/yyyy HH:mm:ss')
				}

				dateToFormat = new Date(date)
			}

			const formattedDate = dateToFormat.toLocaleDateString('pt-BR', options)
			const formattedTime = dateToFormat.toLocaleTimeString('pt-BR', options2)
			return `${formattedDate} ${formattedTime}`
		}
	}, [])

	return formatDateTime
}

export default useDateFormatter
