import { Box, Button, Dialog, DialogActions, Drawer, Stack, Typography } from '@mui/material'
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import CouponDetails from 'components/Coupon/CouponDetails'
import DropdownButton from 'components/Layout/DropdownButton'
import GiftCardDetails from 'components/GiftCard/GiftCardDetails'
import { ReturnsTable } from 'components/StoreEvent/Returns/ReturnsTable'
import { SaleStatus } from 'models/SaleStatus'
import SalesDetails from 'components/Sales/SalesDetails'
import StoreEventSelection from 'components/StoreEvent/Search/StoreEventSelection'
import couponService from 'services/coupon.service'
import giftCardService from 'services/gift-card.service'
import printDocument from 'services/print'
import printFile from 'services/printFile'
import settings from 'settings.js'
import storeSalesReturnService from 'services/store-sales-return.service'
import storeSalesService from 'services/store-sales.service'
import { useAppHooks } from 'hooks/useAppHooks'
import { useHasRole } from 'hooks/useHasRole'
import { useSelector } from 'react-redux'

function SalesDetailsPage(props) {
	const location = useLocation()
	const [sale, setSale] = useState(location?.state?.sale)
	const { token } = useSelector((state) => state.auth)
	const [coupon, setCoupon] = useState(location?.state?.coupon)
	const [ giftCards, setGiftCards ] = useState([]);
	const [salesReturns, setSalesReturns] = useState([]);
	const [selectedCoupon, setSelectedCoupon] = useState(null);
	const [selectedGiftCards, setSelectedGiftCards] = useState(null);
	const [openReturnsPanel, setOpenReturnsPanel] = useState(false)
	const [openEditSaleStoreEvent, setOpenEditSaleStoreEvent] = useState(false)

	const { saleId, storeEventId } = useParams()
	const { withLoading, message } = useAppHooks()
	const isManager = useHasRole(['Administrator', 'Manager']) // Initialize your RoleService
	const navigate = useNavigate()

	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (sale) {
			return
		}

		withLoading(async () => {
			const data = await storeSalesService.get(saleId)
			setSale(data);
		}).catch((error) => {
			message.error('Não foi possível carregar os dados da venda.', error)
		});

		
		withLoading(async () => {
			if (isManager) {
				const coupon = await couponService.getBySale(saleId)
				setCoupon(coupon)
			}
		}).catch((error) => {
			if(error.response?.status === 404) return;
			message.error('Não foi possível carregar os dados do cupom.', error);
		});

		
		withLoading(async () => {
			const returns = await storeSalesReturnService.getBySale(saleId)
			setSalesReturns(returns)
		}).catch((error) => {
			if(error.response?.status === 404) return;
			message.error('Não foi possível carregar os dados das devoluções.', error)
		});
		
		
		withLoading(async () => {
			console.log(`searching gift cards for sale ${saleId}`);
				const data = await giftCardService.getBySale(saleId);

				 setGiftCards(data);
		}).catch((error) => {
			if(error.response?.status === 404) return;
			message.error('Não foi possível carregar os dados do vale presente.', error)
		});
	}, [saleId])

	useEffect(() => {
		if(coupon){
			printCoupon();
		}
	}, [coupon]);

	useEffect(() => {
		var es = new EventSourcePolyfill(`${settings.events.url}/connect/${saleId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		es.addEventListener('Sale.Updated', (e) => {
			const data = JSON.parse(e.data)

			if (data.status === SaleStatus.Approved && sale?.status !== SaleStatus.Approved) {
				withLoading(async () => {
					var blob = await storeSalesService.receipt(saleId)
					printFile(30, blob.data)
				}).catch()
			}

			setSale(data)
		})

		return () => {
			es?.close()
		}
	}, [saleId, token])

	const openReturnHandler = (saleReturn) => {
		if (storeEventId) {
			navigate(`/store-event/${storeEventId}/return/${saleReturn.id}`)
		} else {
			navigate(`/store/${sale.storeId}/return/${saleReturn.id}`)
		}
	}
	const createReturnHandler = () => {
		if (storeEventId) {
			navigate(`/store-event/${storeEventId}/return`)
		} else {
			navigate(`/store/${sale.storeId}/sales/${sale.id}/return`)
		}
	}

	const printPage = (e) => {
		if (e === 'document') {
			printSale();
		} else if (e === 'coupon') {
			printCoupon();
		} else if (e === 'gift-card') {
			printGiftCard();
		}
	}

	const printSale = () => {		
		if (sale.status === SaleStatus.Approved) {
			withLoading(async () => {
				var blob = await storeSalesService.receipt(saleId)

				printFile(30, blob.data)
			}).catch((error) => {
				printDocument(30)
			})
		} else {
			printDocument(30)
		}
	}
	
	const printGiftCard = () => {
		setSelectedGiftCards(giftCards);
		setOpen(true)
	}

	const printCoupon = () => {
		setSelectedCoupon(coupon)
		setOpen(true)
	}
	const handleClose = () => {
		setSelectedCoupon(null);
		setOpen(false)
	}

	const openCouponHandler = (c) => {
		withLoading(async () => {
			try {
				const data = await couponService.get(c)
				setSelectedCoupon(data)
				setOpen(true)
			} catch (error) {
				message.error('Não foi possível carregar os dados do cupom.', error)
			}
		})
	}

	const storeEventChangeHandler = (storeEvent) => {
		withLoading(async () => {
			try {
				await storeSalesService.associateStoreEvent(sale, storeEvent?.id)
				setSale({ ...sale, storeEventId: storeEvent?.id })
				setOpenEditSaleStoreEvent(false)

				message.success('Venda atualizada com sucesso.')
			} catch (error) {
				message.error('Não foi possível atualizar o evento da venda.', error)
			}
		})
	}

	const associateSaleHandler = (e) => {
		if (e === 'store-event') {
			setOpenEditSaleStoreEvent(true)
		} else {
			storeEventChangeHandler(null)
		}
	}

	const options = [{ value: 'store-event', label: 'Associar a outro evento' }]

	if (!!sale?.storeEventId) options.push({ value: 'store', label: 'Venda de loja' })

	const associateButtonText = sale?.storeEventId ? 'Venda de evento' : 'Venda de Loja'

	const printOptions = [		
		{value: 'document', label: 'Imprimir nota'}
	];

	if(coupon) printOptions.push({value: 'coupon', label: 'Imprimir cupom'});
	if(giftCards && giftCards.length > 0) printOptions.push({value: 'gift-card', label: 'Imprimir Vale presente'});

	return sale ? (
		<div className="print a4" id="component-to-print">
			<Stack direction="row" gap={2} position="absolute" right={30} zIndex={2} className="no-print">
				<DropdownButton buttonText={associateButtonText} options={options} onSelect={associateSaleHandler} />
				<Button variant="contained" color="primary" onClick={() => setOpenReturnsPanel(true)}>
					Devoluções
				</Button>
				<DropdownButton buttonText="Imprimir" options={printOptions} onSelect={printPage} />
			</Stack>

			<SalesDetails sale={sale} coupon={coupon} openCouponHandler={openCouponHandler}></SalesDetails>

			{/* Till Float/Drop Right Side Panel */}
			<Drawer anchor="right" open={openReturnsPanel} onClose={() => setOpenReturnsPanel(false)} sx={{ width: '80% !important' }}>
				<Box sx={{ p: 2 }}>
					<Typography variant="h6" sx={{ mb: 1 }}>
						Devoluções
					</Typography>
					<ReturnsTable salesReturns={salesReturns} onOpen={openReturnHandler} />

					<Button variant="outlined" sx={{ mt: 3 }} startIcon={<AddIcon />} onClick={createReturnHandler}>
						Registrar devolução
					</Button>
				</Box>
			</Drawer>
			<Drawer anchor="right" open={openEditSaleStoreEvent} onClose={() => setOpenEditSaleStoreEvent(false)} sx={{ width: '80% !important' }}>
				<Box sx={{ p: 2 }}>
					<StoreEventSelection storeId={sale.storeId} onSelect={storeEventChangeHandler}></StoreEventSelection>
				</Box>
			</Drawer>

			<Dialog fullWidth={true} maxWidth="md" onClose={handleClose} open={open}>
				{open && selectedCoupon && <CouponDetails coupon={selectedCoupon} storeId={sale.storeId} ></CouponDetails>}
				{open && selectedGiftCards && selectedGiftCards.length > 0 && 
					selectedGiftCards.map((giftCard, index) => (
						<GiftCardDetails key={giftCard.id} giftCard={giftCard} storeId={sale.storeId} ></GiftCardDetails>
					))
				}

				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	) : saleId ? (
		<p>Carregando venda...</p>
	) : (
		<p>Não foi possível localizar a venda.</p>
	)
}

export default SalesDetailsPage
