import { IconButton, TableFooter } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import axios from "axios";
import productService from "../../services/product.service";
import styled from "@emotion/styled";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";
import { useDebounce } from "../../hooks/useDebounce";

const ProductSelection = (props) => {
  const [products, setProducts] = useState([]);
  const [addRequested, setAddRequested] = useState(false);
  const [value, setValue] = React.useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const selectedProducts = props.products;
  const buttonRef = useRef(null);
  const addRequestedRef = useRef(false);
  const debounce = useDebounce();
  const { onProductsUpdated, storeId } = props;
  const currencyFormatter = useCurrencyFormatter();

  const searchProduct = (query) => {

    if(query.length < 2 || query === selectedProduct?.name)
    {
      return;
    }

    productService
      .search(query, storeId)
      .then((p) => {
        setProducts(p);

        if(addRequestedRef.current && p.length == 1){
          setSelectedProduct(p[0]);
          setTimeout(() => {
            buttonRef.current.focus();
            buttonRef.current.click();
          }, 50);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        addRequestedRef.current = false;
      });
  };

  const searchProductHandler = (query) => {

    if (!query || query.length < 2) {
      return;
    }

    
    setProducts([]);
    debounce(searchProduct.bind(null, query), 300);
  };

  const handleProductAdd = () => {
    if (selectedProduct) {
      onProductsUpdated([...selectedProducts, selectedProduct]);
      setSelectedProduct(null);
      setValue(null);

    }
  };

  const onKeyUpHandler = (event) => {
    if (event.key === "Enter") {
      if(selectedProduct){
        addRequestedRef.current = false;
        buttonRef.current.focus();
        buttonRef.current.click();
      }
      else{
        addRequestedRef.current = true;
      }
    }
  };

  const handleProductRemove = (productToRemove) => {
    onProductsUpdated(selectedProducts.filter((product) => product !== productToRemove));
  };

  const totalPrice = selectedProducts.reduce(
    (total, product) => total + product.price,
    0
  );

  const styledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <div>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid xs>
          <Autocomplete
            options={products}
            noOptionsText="Nenhum produto encontrado"
            value={value}
            autoHighlight={true}
            getOptionLabel={(option) => option.sku + " - " + option.name}
            filterOptions={(options, params) => options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(event, value) =>  searchProductHandler(value) }
            onChange={(event, value) => setSelectedProduct(value)}
            onKeyUp={onKeyUpHandler}
            // renderOption={(props, option) => (
            //     <li {...props}>
            //         <span>{option.sku}</span>
            //         <span>{option.name}</span>
            //         <span>{option.price}</span>
            //     </li>
            // )}
            renderInput={(params) => <TextField {...params} label="Produtos" />}
          />
        </Grid>
        <Grid xs="auto">
          <Button
            ref={buttonRef}
            variant="contained"
            color="primary"
            size="large"
            onClick={handleProductAdd}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Preço</TableCell>
              <TableCell width="10"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProducts.map((product, index) => (
              <TableRow key={`product_selection_${index}`}>
                <TableCell>{product.sku}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{currencyFormatter(product.price)}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleProductRemove(product)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {selectedProducts.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>Nenhum produto selecionado</TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>Total</TableCell>
              <TableCell>{currencyFormatter(totalPrice)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductSelection;
