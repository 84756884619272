import React, { useRef, useState } from 'react'

import { Button } from '@mui/material'
import storeEventService from 'services/store-event.service';
import { useAppHooks } from 'hooks/useAppHooks';

const AttachFile = ({storeEventId, onUploaded}) => {
    const fileInputRef = useRef(null);
    const { withLoading, message } = useAppHooks();

    const uploadFileHandler = (event) => {

        const file = event.target.files[0];
        console.log('file upload', file);
        withLoading(async () => {
            var uploaded = await storeEventService.attach(storeEventId, file);
            onUploaded(uploaded);
            fileInputRef.current.value = null;
        }).catch((e) => {
            message.error('Erro ao carregar os arquivos', e);
            fileInputRef.current.value = null;
        });
    }
    const openFileDialog = () => {
        fileInputRef.current.click();
    }

	return (
		<div>
			<input
                type="file"
                ref={fileInputRef}
                onChange={uploadFileHandler}
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                style={{ display: 'none' }} // Hide the default input
            />
            <Button variant="contained" color="primary" size='small' onClick={openFileDialog}>
                Anexar arquivo
            </Button>
            
		</div>
	)
}

export default AttachFile
