import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";

export const ButtonLink = styled(NavLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&.active': {
      color: theme.palette.secondary.main,
    },
    // Add more styles as needed
  }));
  